import React from "react";

import PageEdit from "../../partials/dashboard/PageEdit";
import PageImageEdit from "../../partials/dashboard/PageImageEdit";

const DashboardMain = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Cards */}
      <div className="grid grid-cols-12 gap-6">
        <PageEdit title="Sustainability En" edit="en" page="sustainability"/>
        <PageEdit title="Sustainability Tr" edit="tr" page="sustainability"/>
        <PageEdit title="Sustainability No" edit="no" page="sustainability"/>
        <PageImageEdit page="sustainability"/>

      </div>
    </div>
  );
};

export default DashboardMain;
