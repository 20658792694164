import React from "react";

import PageEdit from "../../partials/dashboard/PageEdit";
import PageImageEdit from "../../partials/dashboard/PageImageEdit";

const DashboardMain = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Cards */}
      <div className="grid grid-cols-12 gap-6">
        <PageEdit
          title="Energy Management Systems En"
          edit="en"
          page="energyman"
        />
        <PageEdit
          title="Energy Management Systems Tr"
          edit="tr"
          page="energyman"
        />
        <PageEdit
          title="Energy Management Systems No"
          edit="no"
          page="energyman"
        />
        <PageImageEdit page="energyman" />
      </div>
    </div>
  );
};

export default DashboardMain;
