import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import Toast from "react-hot-toast";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NewsModal from "./NewsModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAuth } from "../../../../context/AuthContext";

import axios from "../../../../api/axios";

const GET_URL = "/get/news/index.php";
const ADD_URL = "/auth/news/add/index.php";

const validationSchema = yup.object({
  trhead: yup.string("TR Head is required").required("TR Head is required"),
  enhead: yup
    .string("EN Head is required")
    .required("EN Head Type is required"),
  nohead: yup.string("NO Head is required").required("NO Head is required"),
  trshortdesc: yup
    .string("TR Short Description is required")
    .required("TR Short Description is required"),
  enshortdesc: yup
    .string("EN Short Description is required")
    .required("EN Short Description is required"),
  noshortdesc: yup
    .string("NO Short Description is required")
    .required("NO Short Description is required"),
  metatrdesc: yup
    .string("EN Description is required")
    .required("EN Description is required"),
  metaendesc: yup.string("TR Description is required").required("TR Description is required"),
  metanodesc: yup.string("NO Description is required").required("NO Description is required"),
  metaenkeys: yup.string("EN Keys is required").required("EN Keys is required"),
  metatrkeys: yup.string("TR Keys is required").required("TR Keys is required"),
  metanokeys: yup.string("NO Keys is required").required("NO Keys is required"),
});

function References(props) {
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [modalRef, setModalRef] = useState(null);
  const { user } = useAuth();
  const [trvalue, setTrvalue] = useState("");
  const [envalue, setEnvalue] = useState("");
  const [novalue, setNovalue] = useState("");

  const mainImageRef = useRef(null);
  const imageRef = useRef(null);
  const [value, setValue] = useState("");

  const formik = useFormik({
    initialValues: {
      trhead: "",
      enhead: "",
      nohead: "",
      trshortdesc: "",
      enshortdesc: "",
      noshortdesc: "",
      metatrdesc: "",
      metaendesc: "",
      metanodesc: "",
      metatrkeys: "",
      metaenkeys: "",
      metanokeys: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (
        imageRef.current.files[0] === undefined ||
        mainImageRef.current.files[0] === undefined
      ) {
        Toast.error("Lütfen dosyaları ekleyin");
        return;
      }
      if (
        values.trhead.length + values.trshortdesc.length > 125 ||
        values.enhead.length + values.enshortdesc.length > 125
      ) {
        Toast.error(
          "Lütfen başlık ve kısa açıklama alanlarını toplamını 125 karakterden fazla girmemeye dikkat edin"
        );
        return;
      }
      const formData = new FormData();
      formData.append("content", JSON.stringify(values));
      formData.append("trdesc", trvalue);
      formData.append("endesc", envalue);
      formData.append("nodesc", novalue);
      formData.append("date", new Date().toJSON().slice(0, 10));
      formData.append("mimage", imageRef.current.files[0]);
      formData.append("image", mainImageRef.current.files[0]);
      const loadid = Toast.loading("Ekleniyor...");
      
      axios.post(ADD_URL, formData, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      }).then((res) => {
        if (res.data.status === "success") {
          Toast.success(res.data.message);
        } else {
          Toast.error(res.data.message);
        }
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
        loadid.dismiss();
      }).catch((err) => {
        console.log(err);
      });
    },
  });

  useEffect(() => {
    axios
      .get(GET_URL)
      .then((res) => {
        setValue(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200 z-30">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800">{props.title}</h2>
      </header>
      <div className="p-3">
        {/* Card content */}
        {/* "Today" group */}
        <div>
          <header className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm font-semibold p-2">
            Create New One
          </header>
          <ul className="my-1">
            <li className="px-2 text-base">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Form</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-3"
                  >
                    <div className="flex gap-2 items-center">
                      <p>Head Image</p>
                      <input
                        ref={mainImageRef}
                        type="file"
                        name="image"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="flex gap-2 items-center">
                      <p>Description Image</p>
                      <input
                        ref={imageRef}
                        type="file"
                        name="image"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <hr />
                    <TextField
                      fullWidth
                      id="trhead"
                      name="trhead"
                      label="TR Head Text"
                      value={formik.values.trhead}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.trhead && Boolean(formik.errors.trhead)
                      }
                      helperText={formik.touched.trhead && formik.errors.trhead}
                    />
                    <TextField
                      fullWidth
                      id="trshortdesc"
                      name="trshortdesc"
                      label="TR Short Description"
                      value={formik.values.trshortdesc}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.trshortdesc &&
                        Boolean(formik.errors.trshortdesc)
                      }
                      helperText={
                        formik.touched.trshortdesc && formik.errors.trshortdesc
                      }
                    />
                    <p>TR Description</p>
                    <ReactQuill
                      theme="snow"
                      value={trvalue}
                      onChange={setTrvalue}
                    />
                    <hr />
                    <TextField
                      fullWidth
                      id="enhead"
                      name="enhead"
                      label="EN Head Text"
                      value={formik.values.enhead}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.enhead && Boolean(formik.errors.enhead)
                      }
                      helperText={formik.touched.enhead && formik.errors.enhead}
                    />
                    <TextField
                      fullWidth
                      id="enshortdesc"
                      name="enshortdesc"
                      label="EN Short Description"
                      value={formik.values.enshortdesc}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.enshortdesc &&
                        Boolean(formik.errors.enshortdesc)
                      }
                      helperText={
                        formik.touched.enshortdesc && formik.errors.enshortdesc
                      }
                    />
                    <p>EN Description</p>
                    <ReactQuill
                      theme="snow"
                      value={envalue}
                      onChange={setEnvalue}
                    />
                    <hr />
                    <TextField
                      fullWidth
                      id="nohead"
                      name="nohead"
                      label="NO Head Text"
                      value={formik.values.nohead}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.nohead && Boolean(formik.errors.nohead)
                      }
                      helperText={formik.touched.nohead && formik.errors.nohead}
                    />
                    <TextField
                      fullWidth
                      id="noshortdesc"
                      name="noshortdesc"
                      label="NO Short Description"
                      value={formik.values.noshortdesc}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.noshortdesc &&
                        Boolean(formik.errors.noshortdesc)
                      }
                      helperText={
                        formik.touched.noshortdesc && formik.errors.noshortdesc
                      }
                    />
                    <p>NO Description</p>
                    <ReactQuill
                      theme="snow"
                      value={novalue}
                      onChange={setNovalue}
                    />
                    <hr />
                    <h3 className="font-bold">Meta</h3>
                    <TextField
                      fullWidth
                      id="metatrdesc"
                      name="metatrdesc"
                      label="META TR Description"
                      value={formik.values.metatrdesc}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.metatrdesc &&
                        Boolean(formik.errors.metatrdesc)
                      }
                      helperText={
                        formik.touched.metatrdesc && formik.errors.metatrdesc
                      }
                    />
                    <TextField
                      fullWidth
                      id="metatrkeys"
                      name="metatrkeys"
                      label="META TR Keys"
                      value={formik.values.metatrkeys}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.metatrkeys &&
                        Boolean(formik.errors.metatrkeys)
                      }
                      helperText={
                        formik.touched.metatrkeys && formik.errors.metatrkeys
                      }
                    />
                    
                    <TextField
                      fullWidth
                      id="metaendesc"
                      name="metaendesc"
                      label="META EN Description"
                      value={formik.values.metaendesc}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.metaendesc &&
                        Boolean(formik.errors.metaendesc)
                      }
                      helperText={
                        formik.touched.metaendesc && formik.errors.metaendesc
                      }
                    />
                    <TextField
                      fullWidth
                      id="metaenkeys"
                      name="metaenkeys"
                      label="META EN Keys"
                      value={formik.values.metaenkeys}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.metaenkeys &&
                        Boolean(formik.errors.metaenkeys)
                      }
                      helperText={
                        formik.touched.metaenkeys && formik.errors.metaenkeys
                      }
                    />
                    <TextField
                      fullWidth
                      id="metanodesc"
                      name="metanodesc"
                      label="META NO Description"
                      value={formik.values.metanodesc}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.metanodesc &&
                        Boolean(formik.errors.metanodesc)
                      }
                      helperText={
                        formik.touched.metanodesc && formik.errors.metanodesc
                      }
                    />
                    <TextField
                      fullWidth
                      id="metanokeys"
                      name="metanokeys"
                      label="META NO Keys"
                      value={formik.values.metanokeys}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.metanokeys &&
                        Boolean(formik.errors.metanokeys)
                      }
                      helperText={
                        formik.touched.metanokeys && formik.errors.metanokeys
                      }
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </Button>
                  </form>
                </AccordionDetails>
              </Accordion>
            </li>
            <header className="mt-3 text-xs uppercase text-slate-400 bg-slate-50 rounded-sm font-semibold p-2">
              Edit Old Ones
            </header>
            {value.length > 0 &&
              value.map((item, index) => (
                <li
                  key={index}
                  className="p-2 mt-3 border-[1px] items-center rounded-md shadow text-base flex justify-between"
                >
                  <h3>{item.trhead}</h3>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalRef(item.enhead);
                      setSearchModalOpen(true);
                    }}
                    className="px-3 py-1 text-[#00833e]"
                  >
                    Edit
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <NewsModal
        id="edit-news"
        reference={modalRef}
        modalOpen={searchModalOpen}
        setModalOpen={setSearchModalOpen}
      />
    </div>
  );
}

export default References;
