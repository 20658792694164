import { useEffect } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Navigate } from 'react-router-dom'

export default function Logout() {
  const { logout } = useAuth()

  useEffect(() => {
    logout()
  }, [])

  return <Navigate to="/cyadmin/" />
}
