import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import Navbar from "../../../components/HomeComponents/Navbar";
import News from "../../../components/HomeComponents/News";
import References from "../../../components/HomeComponents/References";
import Ready from "../../../components/HomeComponents/Ready";
import Footer from "../../../components/HomeComponents/Footer";
import LogoSlider from "../../../components/HomeComponents/LogoSlider";
import Chatbot from "../../../components/HomeComponents/Chatbot/Chatbot";

import { AiOutlineMessage } from "react-icons/ai";

import { useLocation } from "react-router-dom";

import CookieConsent from "react-cookie-consent";

import { useTranslation } from "react-i18next";

const markets = [
  "/marketsmain",
  "/commercial",
  "/fishing",
  "/workboats",
  "/ferries",
  "/yachts",
  "/offshore",
  "/defense",
];

const Home = () => {
  const { i18n } = useTranslation();

  const location = useLocation();
  const { pathname } = location;

  const [track, setTrack] = useState(false);
  const [setd, setdd] = useState(false);

  const [chat, setChat] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTrack(true);
    }, 1000);
    setTimeout(() => {
      setdd(true);
    }, 2000);
  }, []);
  const pageChange = () => {
    setTrack(false);
    setdd(false);
    setTimeout(() => {
      setTrack(true);
    }, 1000);
    setTimeout(() => {
      setdd(true);
    }, 2000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    pageChange();
  }, [pathname]);
  return (
    <div className="overflow-x-hidden relative">
      <div
        className={`fixed top-0 left-0 ${
          setd ? "hidden" : "flex"
        } justify-center items-center h-screen w-full bg-[#158B01] z-[99999] transition duration-500 overflow-hidden ${
          track ? "-translate-y-[100vh]" : "-translate-y-0"
        }`}
      >
        <h1
          className={`tracking-[-.6rem] text-9xl transition duration-500 font-bold text-white ${
            track ? "translate-y-12" : "translate-y-0"
          }`}
        >
          BMA
        </h1>
      </div>
      <div
        onClick={() => {
          setChat((v) => !v);
        }}
        className="flex justify-center items-center cursor-pointer text-4xl text-white fixed bottom-4 right-4 md:bottom-8 md:right-8 h-16 w-16 bg-[#158A01] z-40 rounded-[100%] hover:-translate-y-1 transition-all"
        style={{ boxShadow: " 0 4px 25px 8px rgba(0,0,0,0.2)" }}
      >
        <AiOutlineMessage />
      </div>
      <div className="w-1/2 h-full">
        <CookieConsent
          location="bottom"
          buttonText={`${i18n.language === "en" ? "I accept" : ""} ${
            i18n.language === "tr" ? "Kabul ediyorum" : ""
          } ${i18n.language === "no" ? "Jeg aksepterer" : ""}`}
          cookieName="CookieConsent"
          style={{ background: "#158A01" }}
          buttonStyle={{
            backgroundColor: "#fff",
            color: "#158A01",
            fontSize: "13px",
          }}
          expires={20}
          enableDeclineButton
          declineButtonText={`${i18n.language === "en" ? "Decline" : ""} ${
            i18n.language === "tr" ? "Reddediyorum" : ""
          } ${i18n.language === "no" ? "Avslå" : ""}`}
        >
          {i18n.language === "en" && (
            <span>
              This website uses cookies to improve user experience.
              <a className="underline" href="https://bma.com.tr/uploads/downloads/66aa35254046-en.pdf">
                Cookie Policy
              </a>
            </span>
          )}
          {i18n.language === "no" && (
            <span>
              Denne nettsiden bruker informasjonskapsler for å forbedre
              brukeropplevelsen.{" "}
              <a className="underline" href="https://bma.com.tr/uploads/downloads/66aa35254046-no.pdf">
                Informasjonskapsler
              </a>
            </span>
          )}
          {i18n.language === "tr" && (
            <span>
              Bu web sitesi, kullanıcı deneyimini geliştirmek için çerezleri
              kullanır. Çerezler hakkında daha fazla bilgi almak için{" "}
              <a className="underline" href="https://bma.com.tr/uploads/downloads/66aa35254046b-tr.pdf">
                Çerez Politikamızı
              </a>{" "}
              ziyaret edebilirsiniz.
            </span>
          )}
        </CookieConsent>
      </div>
      <Chatbot setOpen={setChat} open={chat} />
      <Navbar />
      <Outlet />
      {markets.includes(pathname) ? <References /> : <News />}
      <Ready />
      <LogoSlider />
      <Footer />
    </div>
  );
};

export default Home;
