import React from "react";

import PageEdit from "../../partials/dashboard/PageEdit";
import PageImageEdit from "../../partials/dashboard/PageImageEdit";

const DashboardMain = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Cards */}
      <div className="grid grid-cols-12 gap-6">
        <PageEdit title="Power Management En" edit="en" page="powerman"/>
        <PageEdit title="Power Management Tr" edit="tr" page="powerman"/>
        <PageEdit title="Power Management No" edit="no" page="powerman"/>
        <PageImageEdit title="About En" page="powerman"/>

      </div>
    </div>
  );
};

export default DashboardMain;
