import React, { useRef, useEffect, useState } from "react";
import Transition from "../../utils/Transition";
import Toast from "react-hot-toast";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "../../../../api/axios";
import { useAuth } from "../../../../context/AuthContext";

const GET_URL = "/get/signed-reference/index.php";
const DELETE_URL = "/auth/signed-references/delete/index.php";
const UPDATE_URL = "/auth/signed-references/update/index.php";
const UPDATE_PDF_URL = "/auth/signed-references/update-pdf/index.php";

const validationSchema = yup.object({
  head: yup.string("Head is required").required("Head is required"),
  type: yup.string("Type is required").required("Type is required"),
  trtype: yup.string("TR Type is required").required("TR Type is required"),
  notype: yup.string("NO Type is required").required("NO Type is required"),
  yard: yup.string("Yard is required").required("Yard is required"),
  tryard: yup.string("TR Yard is required").required("TR Yard is required"),
  noyard: yup.string("NO Yard is required").required("NO Yard is required"),
  customername: yup
    .string("Customer Name is required")
    .required("Customer Name is required"),
  scope: yup.string("Scope is required").required("Scope is required"),
  trscope: yup.string("TR Scope is required").required("TR Scope is required"),
  noscope: yup.string("NO Scope is required").required("NO Scope is required"),
  trdesc: yup
    .string("TR Description is required")
    .required("TR Description is required"),
  endesc: yup
    .string("EN Description is required")
    .required("EN Description is required"),
  nodesc: yup
    .string("NO Description is required")
    .required("NO Description is required"),
  trkeys: yup.string("TR Keys is required").required("TR Keys is required"),
  enkeys: yup.string("EN Keys is required").required("EN Keys is required"),
  nokeys: yup.string("NO Keys is required").required("No Keys is required"),
});

function SearchModal({ id, reference, modalOpen, setModalOpen }) {
  const [value, setValue] = useState(null);
  const { user } = useAuth();
  const modalContent = useRef(null);

  const pdfRef = useRef(null);
  const setValueNull = () => {
    setTimeout(() => {
      setValue(null);
    }, 200);
  };
  useEffect(() => {
    if (reference !== null) {
      const formData = new FormData();
      formData.append("info", reference);
      try {
        axios.post(GET_URL, formData).then((res) => {
          setValue(res.data.data[0]);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [modalOpen, reference]);

  const handleDelete = (id) => {
    const formData = new FormData();
    formData.append("info", id);
    const loadid = Toast.loading("Siliniyor...");

    axios.post(DELETE_URL, formData, {
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
      },
    }).then((res) => {
      if (res.data.status === "success") {
        Toast.success(res.data.message);
      } else {
        Toast.error(res.data.message);
      }
      setTimeout(() => {
        setModalOpen(false);
        setValueNull();
        window.location.reload(false);
      }, 1000);
      Toast.dismiss(loadid);
    });
  };

  const handleChangePdf = (id) => {
    if (pdfRef.current.files[0] === undefined) {
      Toast.error("PDF Seçmediniz!");
      return;
    }
    const formData = new FormData();
    formData.append("info", id);
    formData.append("pdf", pdfRef.current.files[0]);
    const loadid = Toast.loading("Updating...");

    axios.post(UPDATE_PDF_URL, formData, {
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
      },
    }).then((res) => {
      if (res.data.status === "success") {
        Toast.success(res.data.message);
      } else {
        Toast.error(res.data.message);
      }
      setTimeout(() => {
        setModalOpen(false);
        setValueNull();
        window.location.reload(false);
      }, 1000);
      Toast.dismiss(loadid);
    });
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modalOpen || modalContent.current.contains(target)) return;
      setModalOpen(false);
      setValueNull();
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });
  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
      setValueNull();
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id={id}
        className="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center transform px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className="bg-white overflow-auto max-w-2xl w-full max-h-full rounded shadow-lg p-4"
        >
          {value === null && (
            <button
              type="button"
              class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-[#158A01] hover:bg-[#155A01] transition ease-in-out duration-150 cursor-not-allowed"
              disabled=""
            >
              <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Loading...
            </button>
          )}
          {value !== null && modalOpen && (
            <Formik
              initialValues={{
                head: value.head,
                type: value.type,
                trtype: value.trtype,
                notype: value.notype,
                yard: value.yard,
                tryard: value.tryard,
                noyard: value.noyard,
                customername: value.customername,
                scope: value.scope,
                trscope: value.trscope,
                noscope: value.noscope,
                trdesc: value.trdescription,
                endesc: value.endescription,
                nodesc: value.nodescription,
                trkeys: value.trkeys,
                enkeys: value.enkeys,
                nokeys: value.nokeys,
              }}
              enaleReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                const formData = new FormData();
                formData.append("content", JSON.stringify(values));
                formData.append("info", reference);

                const loadid = Toast.loading("Updating...");

                axios.post(UPDATE_URL, formData, {
                  headers: {
                    Authorization: `Bearer ${user?.accessToken}`,
                  },
                }).then((res) => {
                  if (res.data.status === "success") {
                    Toast.success(res.data.message);
                  } else {
                    Toast.error(res.data.message);
                  }
                  setTimeout(() => {
                    setModalOpen(false);
                    setValueNull();
                    window.location.reload(false);
                  }, 1000);
                  Toast.dismiss(loadid);
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                  <div className="flex justify-between">
                    <div className="flex gap-2">
                      <p>Change PDF</p>
                      <input ref={pdfRef} type="file" />
                    </div>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        handleChangePdf(value.id);
                      }}
                    >
                      Change
                    </Button>
                  </div>
                  <hr />
                  <TextField
                    fullWidth
                    id="head"
                    name="head"
                    label="Head"
                    value={values.head}
                    onChange={handleChange}
                    error={touched.head && Boolean(errors.head)}
                    helperText={touched.head && errors.head}
                  />
                  <TextField
                    fullWidth
                    id="type"
                    name="type"
                    label="Type"
                    value={values.type}
                    onChange={handleChange}
                    error={touched.type && Boolean(errors.type)}
                    helperText={touched.type && errors.type}
                  />
                  <TextField
                    fullWidth
                    id="trtype"
                    name="trtype"
                    label="TR Type"
                    value={values.trtype}
                    onChange={handleChange}
                    error={touched.trtype && Boolean(errors.trtype)}
                    helperText={touched.trtype && errors.trtype}
                  />
                  <TextField
                    fullWidth
                    id="notype"
                    name="notype"
                    label="NO Type"
                    value={values.notype}
                    onChange={handleChange}
                    error={touched.notype && Boolean(errors.notype)}
                    helperText={touched.notype && errors.notype}
                  />
                  <TextField
                    fullWidth
                    id="yard"
                    name="yard"
                    label="Yard"
                    value={values.yard}
                    onChange={handleChange}
                    error={touched.yard && Boolean(errors.yard)}
                    helperText={touched.yard && errors.yard}
                  />
                  <TextField
                    fullWidth
                    id="tryard"
                    name="tryard"
                    label="TR Yard"
                    value={values.tryard}
                    onChange={handleChange}
                    error={touched.tryard && Boolean(errors.tryard)}
                    helperText={touched.tryard && errors.tryard}
                  />
                  <TextField
                    fullWidth
                    id="noyard"
                    name="noyard"
                    label="NO Yard"
                    value={values.noyard}
                    onChange={handleChange}
                    error={touched.noyard && Boolean(errors.noyard)}
                    helperText={touched.noyard && errors.noyard}
                  />
                  <TextField
                    fullWidth
                    id="customername"
                    name="customername"
                    label="Customer Name"
                    value={values.customername}
                    onChange={handleChange}
                    error={touched.customername && Boolean(errors.customername)}
                    helperText={touched.customername && errors.customername}
                  />
                  <TextField
                    fullWidth
                    id="scope"
                    name="scope"
                    label="Scope"
                    value={values.scope}
                    onChange={handleChange}
                    error={touched.scope && Boolean(errors.scope)}
                    helperText={touched.scope && errors.scope}
                  />
                  <TextField
                    fullWidth
                    id="trscope"
                    name="trscope"
                    label="TR Scope"
                    value={values.trscope}
                    onChange={handleChange}
                    error={touched.trscope && Boolean(errors.trscope)}
                    helperText={touched.trscope && errors.trscope}
                  />
                  <TextField
                    fullWidth
                    id="noscope"
                    name="noscope"
                    label="NO Scope"
                    value={values.noscope}
                    onChange={handleChange}
                    error={touched.noscope && Boolean(errors.noscope)}
                    helperText={touched.noscope && errors.noscope}
                  />
                  <TextField
                    fullWidth
                    id="trdesc"
                    name="trdesc"
                    label="TR Description"
                    value={values.trdesc}
                    onChange={handleChange}
                    error={touched.trdesc && Boolean(errors.trdesc)}
                    helperText={touched.trdesc && errors.trdesc}
                  />
                  <TextField
                    fullWidth
                    id="endesc"
                    name="endesc"
                    label="EN Description"
                    value={values.endesc}
                    onChange={handleChange}
                    error={touched.endesc && Boolean(errors.endesc)}
                    helperText={touched.endesc && errors.endesc}
                  />
                  <TextField
                    fullWidth
                    id="nodesc"
                    name="nodesc"
                    label="NO Description"
                    value={values.nodesc}
                    onChange={handleChange}
                    error={touched.nodesc && Boolean(errors.nodesc)}
                    helperText={touched.nodesc && errors.nodesc}
                  />
                  <TextField
                    fullWidth
                    id="trkeys"
                    name="trkeys"
                    label="TR Keys"
                    value={values.trkeys}
                    onChange={handleChange}
                    error={touched.trkeys && Boolean(errors.trkeys)}
                    helperText={touched.trkeys && errors.trkeys}
                  />
                  <TextField
                    fullWidth
                    id="enkeys"
                    name="enkeys"
                    label="EN Keys"
                    value={values.enkeys}
                    onChange={handleChange}
                    error={touched.enkeys && Boolean(errors.enkeys)}
                    helperText={touched.enkeys && errors.enkeys}
                  />
                  <TextField
                    fullWidth
                    id="nokeys"
                    name="nokeys"
                    label="NO Keys"
                    value={values.nokeys}
                    onChange={handleChange}
                    error={touched.nokeys && Boolean(errors.nokeys)}
                    helperText={touched.nokeys && errors.nokeys}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      handleDelete(value.id);
                    }}
                  >
                    Delete
                  </Button>
                </form>
              )}
            </Formik>
          )}
        </div>
      </Transition>
    </>
  );
}

export default SearchModal;
