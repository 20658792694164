import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Logout from "./pages/Admin/Logout";
import AdminLogin from "./pages/Admin/AdminLogin";
import Page404 from "./pages/404";
import PrivateRouteAdmin from "./components/PrivateRouteAdmin";

// Policy
import Kvkk from "./pages/Guest/kvkk";
import Personaldata from "./pages/Guest/personaldata";

// Home
import HomeLayout from "./pages/Guest/Home/index";
import Home from "./pages/Guest/Home/Main";

// News
import NewsHome from "./pages/Guest/News/index";
import NewsPage from "./pages/Guest/News/refurl";

//References
import SignedReferencesHome from "./pages/Guest/SignedReferences/index";
import SignedReferencesUrl from "./pages/Guest/SignedReferences/refurl";

import FinishedReferencesHome from "./pages/Guest/FinishedReferences/index";
import FinishedReferencesUrl from "./pages/Guest/FinishedReferences/refurl";

// Contact
import Contact from "./pages/Guest/Contact/index";

// Media
import Gallery from "./pages/Guest/Gallery/gallery";
import Catalogue from "./pages/Guest/Media/Catalogue";
import Facility from "./pages/Guest/Media/Facility";
import PressBulletins from "./pages/Guest/PressBulletins/index";
import PressBulletinsRef from "./pages/Guest/PressBulletins/refurl";

// Pages
import AboutUs from "./pages/Guest/About/AboutUs";
import Career from "./pages/Guest/About/Career";
import Policy from "./pages/Guest/About/Policy";
import Downloads from "./pages/Guest/About/Downloads";
import RD from "./pages/Guest/About/RD";
import Sustainability from "./pages/Guest/About/Sustainability";

import Values from "./pages/Guest/About/Values";
import Commercial from "./pages/Guest/Markets/Commercial";
import Defense from "./pages/Guest/Markets/Defense";
import Ferries from "./pages/Guest/Markets/Ferries";
import Fishing from "./pages/Guest/Markets/Fishing";
import Offshore from "./pages/Guest/Markets/Offshore";
import Workboats from "./pages/Guest/Markets/Workboats";
import Yatch from "./pages/Guest/Markets/Yatch";

import Switchboards from "./pages/Guest/Systems/Switchboards";
import ElectricalDrives from "./pages/Guest/Systems/ElectricalDrives";
import Epro from "./pages/Guest/Systems/Epro";
import Energyman from "./pages/Guest/Systems/Energyman";
import Powerman from "./pages/Guest/Systems/Powerman";
import Integrated from "./pages/Guest/Systems/Integrated";
import Other from "./pages/Guest/Systems/Other";

//Admin
import Dashboard from "./pages/Admin/pages/Dashboard";
import DashboardMain from "./pages/Admin/pages/DashboardMain";

import AdminAboutUs from "./pages/Admin/pages/About/AboutUs";
import AdminRD from "./pages/Admin/pages/About/RD";
import AdminDownloads from "./pages/Admin/pages/About/Downloads";
import AdminCareer from "./pages/Admin/pages/About/Career";
import AdminValues from "./pages/Admin/pages/About/Values";
import AdminPolicy from "./pages/Admin/pages/About/Policy";
import AdminSustainability from "./pages/Admin/pages/About/Sustainability";

import AdminCommercial from "./pages/Admin/pages/Markets/Commercial";
import AdminFishing from "./pages/Admin/pages/Markets/Fishing";
import AdminWorkboats from "./pages/Admin/pages/Markets/Workboats";
import AdminFerries from "./pages/Admin/pages/Markets/Ferries";
import AdminYatch from "./pages/Admin/pages/Markets/Yatch";
import AdminOffshore from "./pages/Admin/pages/Markets/Offshore";
import AdminDefense from "./pages/Admin/pages/Markets/Defense";

import AdminSwitchboards from "./pages/Admin/pages/Systems/Switchboards";
import AdminElectricalDrives from "./pages/Admin/pages/Systems/ElectricalDrives";
import AdminEpro from "./pages/Admin/pages/Systems/epro";
import AdminEnergyman from "./pages/Admin/pages/Systems/energyman";
import AdminPowerMan from "./pages/Admin/pages/Systems/PowerMan";
import AdminIntegrated from "./pages/Admin/pages/Systems/Integrated";
import AdminOther from "./pages/Admin/pages/Systems/Other";

import AdminSignedReferences from "./pages/Admin/partials/dashboard/SignedReferences";
import AdminFinishedReferences from "./pages/Admin/partials/dashboard/FinishedReferences";
import AdminNews from "./pages/Admin/partials/dashboard/News";
import AdminContact from "./pages/Admin/partials/dashboard/Contact";
import AdminGallery from "./pages/Admin/partials/dashboard/Gallery";
import AdminCatalogue from "./pages/Admin/pages/Media/Catalogue";
import AdminFacility from "./pages/Admin/pages/Media/Facility";
import AdminPartners from "./pages/Admin/pages/Media/Partners";
import AdminPressBulletins from "./pages/Admin/partials/dashboard/Pressbulletins";
import ContactMessages from "./pages/Admin/partials/dashboard/ContactMessages";

import TagManager from "react-gtm-module";

function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: 12689834 });
  }, []);
  
  return (
    <>
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route index={true} element={<Home />} />

          <Route path="kvkk" element={<Kvkk />} />
          <Route path="personal-data" element={<Personaldata />} />

          <Route path="about-us" element={<AboutUs />} />
          <Route path="career" element={<Career />} />
          <Route path="policy" element={<Policy />} />
          <Route path="downloads" element={<Downloads />} />
          <Route path="r-d" element={<RD />} />
          <Route path="sustainability" element={<Sustainability />} />
          <Route path="values" element={<Values />} />

          <Route path="commercial" element={<Commercial />} />
          <Route path="defense" element={<Defense />} />
          <Route path="ferries" element={<Ferries />} />
          <Route path="fishing" element={<Fishing />} />
          <Route path="offshore" element={<Offshore />} />
          <Route path="workboats" element={<Workboats />} />
          <Route path="yachts" element={<Yatch />} />

          <Route path="switchboards" element={<Switchboards />} />
          <Route path="electricaldrives" element={<ElectricalDrives />} />
          <Route path="epro" element={<Epro />} />
          <Route path="EMS" element={<Energyman />} />
          <Route path="PMS" element={<Powerman />} />
          <Route path="IACS" element={<Integrated />} />
          <Route path="other" element={<Other />} />

          <Route path="gallery" element={<Gallery />} />
          <Route path="catalogue" element={<Catalogue />} />
          <Route path="facility" element={<Facility />} />

          <Route path="news" element={<NewsHome />} />
          <Route path="news/:url" element={<NewsPage />} />
          <Route path="press-bulletins" element={<PressBulletins />} />
          <Route path="press-bulletins/:url" element={<PressBulletinsRef />} />
          <Route path="signedreferences" element={<SignedReferencesHome />} />
          <Route
            path="signedreferences/:url"
            element={<SignedReferencesUrl />}
          />
          <Route
            path="finishedreferences/:url"
            element={<FinishedReferencesUrl />}
          />
          <Route
            path="finishedreferences"
            element={<FinishedReferencesHome />}
          />
          <Route path="contact" element={<Contact />} />
        </Route>

        {/* -------------------------------------- */}
        <Route path="/cyadmin" element={<AdminLogin />} />
        
        <Route
          path="/cyadmin/dashboard"
          element={
            <PrivateRouteAdmin>
              <Dashboard />
            </PrivateRouteAdmin>
          }
        >
          <Route index={true} element={<DashboardMain />} />

          <Route path="about-us" element={<AdminAboutUs />} />
          <Route path="about-r-d" element={<AdminRD />} />
          <Route path="about-downloads" element={<AdminDownloads />} />
          <Route path="about-career" element={<AdminCareer />} />
          <Route path="about-values" element={<AdminValues />} />
          <Route path="about-policy" element={<AdminPolicy />} />
          <Route
            path="about-sustainability"
            element={<AdminSustainability />}
          />

          <Route path="markets-commercial" element={<AdminCommercial />} />
          <Route path="markets-fishing" element={<AdminFishing />} />
          <Route path="markets-workboats" element={<AdminWorkboats />} />
          <Route path="markets-ferries" element={<AdminFerries />} />
          <Route path="markets-yachts" element={<AdminYatch />} />
          <Route path="markets-offshore" element={<AdminOffshore />} />
          <Route path="markets-defense" element={<AdminDefense />} />

          <Route path="systems-switchboards" element={<AdminSwitchboards />} />
          <Route
            path="systems-electricaldrives"
            element={<AdminElectricalDrives />}
          />
          <Route path="systems-epro" element={<AdminEpro />} />
          <Route path="systems-energyman" element={<AdminEnergyman />} />
          <Route path="systems-powermanagement" element={<AdminPowerMan />} />
          <Route path="systems-integrated" element={<AdminIntegrated />} />
          <Route path="systems-other" element={<AdminOther />} />

          <Route path="signedreferences" element={<AdminSignedReferences />} />
          <Route
            path="finishedreferences"
            element={<AdminFinishedReferences />}
          />
          <Route path="news" element={<AdminNews />} />
          <Route path="catalogue" element={<AdminCatalogue />} />
          <Route path="facility" element={<AdminFacility />} />
          <Route path="gallery" element={<AdminGallery />} />
          <Route path="partners" element={<AdminPartners />} />
          <Route path="press-bulletins" element={<AdminPressBulletins />} />
          <Route path="contact" element={<AdminContact />} />
          <Route path="messageform" element={<ContactMessages />} />

          <Route path="logout" element={<Logout />} />
        </Route>

        <Route path="*" element={<Page404 />} />
      </Routes>
    </>
  );
}
export default App;
