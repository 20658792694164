import React, { useRef, useCallback, useEffect, useState } from "react";

import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

import "./News.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import { useTranslation } from "react-i18next";

import Img from "../../assets/Guest/Home/SectionOne/Hardaous.jpg";
import { Link } from "react-router-dom";
import axios from "../../api/axios";
const GET_URL = "/get/finished-references/index.php";

const News = () => {
  const sliderRef = useRef(null);

  const [news, setNews] = useState(null);

  const { t } = useTranslation();

  const [foranim, setForanim] = useState(false);
  const [reverseanim, setReverseanim] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setForanim(false);
    }, 700);
    return () => {
      clearTimeout(timeout);
    };
  }, [foranim]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setReverseanim(false);
    }, 700);
    return () => {
      clearTimeout(timeout);
    };
  }, [reverseanim]);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  useEffect(() => {
    try {
      axios.get(GET_URL).then((response) => {
        let data = response.data.data;
        setNews(data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="md:p-16 p-3 min-h-screen ">
      <div className="md:h-[calc(100vh-8rem)] h-[calc(100vh-1.5rem)] flex flex-col justify-between gap-2">
        <div className="md:mt-12 md:ml-12 p-2 text-2xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-bold flex gap-12 flex-wrap ">
          <div className="flex gap-2 flex-wrap ">
            <span
              className={`text-black  opacity-60 ${
                foranim && "animation-opacity-60"
              } ${reverseanim && "animation-reverse-opacity-60"}`}
            >
              {t("bar-references-title").toLocaleUpperCase()}
            </span>
            <span
              className={`text-stroke-news text-transparent opacity-40 md:block hidden ${
                foranim && "animation-opacity-40"
              } ${reverseanim && "animation-reverse-opacity-40"}`}
            >
              {t("bar-references-title").toLocaleUpperCase()}
            </span>
            <span
              className={`text-stroke-news text-transparent opacity-30 md:block hidden ${
                foranim && "animation-opacity-30"
              } ${reverseanim && "animation-reverse-opacity-30"}`}
            >
              {t("bar-references-title").toLocaleUpperCase()}
            </span>
          </div>
          <div className="text-[#000] flex gap-2">
            <button className="border-[1px] border-black" onClick={handlePrev}>
              <IoIosArrowBack />
            </button>
            <button className="border-[1px] border-black" onClick={handleNext}>
              <IoIosArrowForward />
            </button>
          </div>
        </div>
        <div className="w-full h-full  flex md:flex-row flex-col-reverse justify-between items-center p-4 overflow-hidden">
          <div className="relative md:left-14 left-0 md:w-2/5 w-full text-black gap-4 md:gap-8 flex flex-col md:justify-center justify-end h-1/3 md:h-full">
            <p className="text-[#A3A3A3]">{t("references-description")}</p>
            <Link
              to="/finishedreferences"
              className="text-center md:px-12 md:py-4 px-4 py-2 border-[1px] border-black  text-sm hover:bg-black hover:text-white transition-all"
            >
              {t("references-all-text")}
            </Link>
          </div>
          <div className="md:w-4/5 news w-full relative md:left-28 left-0  h-full md:h-full flex justify-center items-center">
            <Swiper
              onSlideChange={(swiper) => {
                if (
                  swiper.activeIndex > swiper.previousIndex ||
                  swiper.activeIndex === 0
                ) {
                  setForanim(true);
                } else {
                  setReverseanim(true);
                }
              }}
              ref={sliderRef}
              loop={true}
              spaceBetween={0}
              breakpoints={{
                30: {
                  slidesPerView: 1,
                },
                480: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 1,
                },
                900: {
                  slidesPerView: 2,
                },
                1366: {
                  slidesPerView: 3,
                },
              }}
            >
              {news &&
                news.map((item, index) => (
                  <SwiperSlide key={index}>
                    <Link
                      to={"/finishedreferences/" + item.customername}
                      className="group flex flex-col w-full h-full  hover:shadow-lg hover:-translate-y-2 transition-all md:p-8 p-6"
                    >
                      <div className="w-full h-1/2 overflow-hidden">
                        <img
                          src={
                            process.env.REACT_APP_URL +
                            "uploads/finishedreferences/" +
                            item.unique +
                            "." +
                            item.last
                          }
                          alt="img"
                          className="w-full h-full object-cover group-hover:scale-110 transition-all duration-500"
                        />
                      </div>
                      <div className="w-full h-1/2 flex flex-col md:gap-4 gap-1  overflow-auto">
                        <p className="md:text-xl text-lg">
                          {item.customername}
                        </p>
                        <p className="md:text-lg text-base text-[#A3A3A3]">
                          {item.scope}
                        </p>
                        <div className="flex justify-end mt-auto">
                          <IoIosArrowForward />
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
