import React, { useEffect, useState } from "react";

import Main from "../../../assets/Guest/References/main.png";

import { IoIosArrowForward } from "react-icons/io";

import { FormControlLabel, Checkbox } from "@mui/material";

import { useTranslation } from "react-i18next";

import AOS from "aos";
import "aos/dist/aos.css";

import SEO from "../../../components/SEO";

import { useAutoAnimate } from "@formkit/auto-animate/react";

import Filter from "./filter";

import Toast from "react-hot-toast";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import axios from "../../../api/axios";

const validationSchema = yup.object({
  name: yup.string("Name is required").required("Name is required"),
  email: yup.string("Email is required").required("Email is required"),
  phone: yup.string("Phone is required").required("Phone is required"),
  message: yup.string("Message is required").required("Message is required"),
  checked: yup
    .boolean()
    .oneOf([true], "You must accept the terms and conditions."),
  checked2: yup
    .boolean()
    .oneOf([true], "You must accept the terms and conditions."),
});

const POST_URL = "/contact/index.php";
const GET_URL = "/get/contacts/index.php";

const Contact = () => {
  const { t, i18n } = useTranslation();

  const [animationParent] = useAutoAnimate();

  const [filter, setFilter] = useState("");
  const [references, setReferences] = useState(null);
  const [filtered, setFiltered] = useState(null);

  const [informations, setInformations] = useState({
    city: "Kocaeli",
    address: "contact-istanbul-address",
    phone: "contact-istanbul-phone",
    mail: "contact-istanbul-mail",
    map: (
      <iframe
        title="bma-technology-map"
        width="100%"
        height="100%"
        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Gebze%20Organize%20Sanayi%20B%C3%B6lgesi%201000.Cad.%20No:1035%20%C3%87ay%C4%B1rova-Kocaeli-T%C3%BCrkiye+(BMA%20Technology)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      >
        <a href="https://www.maps.ie/distance-area-calculator.html">
          measure acres/hectares on map
        </a>
      </iframe>
    ),
  });

  useEffect(() => {
    AOS.init();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      checked: false,
      checked2: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("content", JSON.stringify(values));
      formData.append(
        "reply",
        `${i18n.language === "en" ? "Thank you for reaching us." : ""} ${
          i18n.language === "tr"
            ? "Bizimle iletişime geçtiğiniz için teşekkür ederiz."
            : ""
        } `
      );
      const loadid = Toast.loading("Gönderiliyor...");
      try {
        axios.post(POST_URL, formData).then((res) => {
          console.log(res.data);
          if (res.data.status === "success") {
            Toast.success(res.data.message);
          } else {
            Toast.error(res.data.message);
          }
          Toast.dismiss(loadid);
          setTimeout(() => {}, 1000);
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    try {
      axios.get(GET_URL).then((res) => {
        if (res.data.status) {
          let data = res.data.data;
          if (i18n.language === "en") {
            setReferences(data);
            setFiltered(data);
            filterMethod(data);
          } else if (i18n.language === "tr") {
            let newData = [];
            data.map((item) => {
              newData.push({
                id: item.id,
                name: item.name,
                unique: item.unique,
                title: item.titletr,
                number: item.number,
                mail: item.mail,
                last: item.last,
              });
            });
            setReferences(newData);
            setFiltered(newData);
            filterMethod(newData);
          } else {
            let newData = [];
            data.map((item) => {
              newData.push({
                id: item.id,
                name: item.name,
                unique: item.unique,
                title: item.titleno,
                number: item.number,
                mail: item.mail,
                last: item.last,
              });
            });
            setReferences(newData);
            setFiltered(newData);
            filterMethod(newData);
          }
        } else {
          Toast.error(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const filterFunc = (e) => {
    const text = e;
    if (text === "all" || text === "tümü" || text === "alle") {
      setFiltered(references);
    } else {
      const arr = [];
      references.map((item) => {
        const scopes = item.title.split(",");
        scopes.map((scope) => {
          if (scope.trim() === text) {
            arr.push(item);
          }
        });
      });
      setFiltered(arr);
    }
  };

  const filterMethod = (data) => {
    const arr = [];
    data.map((item) => {
      const scopes = item.title.split(",");
      scopes.map((scope) => {
        let text = scope.trim();
        if (!arr.includes(text)) {
          arr.push(text);
        }
      });
    });
    setFilter(arr);
  };

  return (
    <div className="md:px-24 p-3 flex flex-col gap-16">
      <SEO
        title={t("contact-title")}
        description={t("contact-description")}
        keys={t("contact-meta-keys")}
        conancial={"/contact"}
      />
      <div className="w-full border-[1px] flex md:text-base text-xs">
        <button
          onClick={() => {
            // istanbul
            setInformations({
              city: "Kocaeli",
              address: "contact-istanbul-address",
              phone: "contact-istanbul-phone",
              mail: "contact-istanbul-mail",
              map: (
                <iframe
                  title="bma-technology-map"
                  width="100%"
                  height="100%"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Gebze%20Organize%20Sanayi%20B%C3%B6lgesi%201000.Cad.%20No:1035%20%C3%87ay%C4%B1rova-Kocaeli-T%C3%BCrkiye+(BMA%20Technology)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="https://www.maps.ie/distance-area-calculator.html">
                    measure acres/hectares on map
                  </a>
                </iframe>
              ),
            });
          }}
          className={`md:px-12 px-3 md:py-4 py-2 flex justify-center items-center ${
            informations.city === "Kocaeli"
              ? "bg-[#158B01] text-white"
              : "text-[#158B01] bg-white"
          }`}
        >
          Kocaeli
        </button>
        <button
          onClick={() => {
            // antalya
            setInformations({
              city: "Antalya",
              address: "contact-antalya-adress",
              phone: "contact-antalya-phone",
              mail: "contact-antalya-mail",
              map: (
                <iframe
                  title="bma-technology-map"
                  width="100%"
                  height="100%"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=23.%20Sk.%20No:5%20Konyaalt%C4%B1%2FAntalya%2FT%C3%BCrkiye+(BMA%20Technology)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="https://www.maps.ie/distance-area-calculator.html">
                    measure acres/hectares on map
                  </a>
                </iframe>
              ),
            });
          }}
          className={`md:px-12 px-3 md:py-4 py-2 flex justify-center items-center ${
            informations.city === "Antalya"
              ? "bg-[#158B01] text-white"
              : "text-[#158B01] bg-white"
          }`}
        >
          Antalya
        </button>
        <button
          onClick={() => {
            setInformations({
              city: "Ossendrecht",
              address: "contact-netherland-adress",
              phone: "contact-netherland-phone",
              mail: "contact-netherland-mail",
              map: (
                <iframe
                  width="100%"
                  height="600"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=YBQ-Engineering%20B.V.%20Calfven%20148,%204641RG%20Ossendrecht%20Netherlands+(BMA%20Technology)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="https://www.maps.ie/distance-area-calculator.html">
                    measure acres/hectares on map
                  </a>
                </iframe>
              ),
            });
          }}
          className={`md:px-12 px-3 md:py-4 py-2 flex justify-center items-center ${
            informations.city === "Ossendrecht"
              ? "bg-[#158B01] text-white"
              : "text-[#158B01] bg-white"
          }`}
        >
          {t("contact-netherland")}
        </button>
        <button className="cursor-default md:px-12 px-3 md:py-4 py-2 flex justify-center items-center  text-[#A3A3A3] border-x-[1px]">
          {t("contact-coming-soon")}
        </button>
      </div>
      <div className="w-full  flex md:flex-row flex-col-reverse">
        <div className="w-full md:w-2/5 h-1/2 md:h-full flex flex-col  gap-8 justify-center pr-8">
          <div className="flex flex-col gap-6 mt-6">
            <p className="text-[#00833e] md:text-4xl text-2xl font-bold">
              {t("contact-title")}
            </p>
            <div className="text-[#A3A3A3] md:text-lg text-base flex flex-col gap-3">
              <p>
                <span className="font-bold">{t("contact-form-adress")}: </span>
                {t(informations.address)}
              </p>
              <p>
                <span className="font-bold">{t("contact-form-phone")}: </span>
                {t(informations.phone)}
              </p>
              <p>
                <span className="font-bold">{t("contact-form-mail")}: </span>
                {t(informations.mail)}
              </p>
            </div>
          </div>

          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 ">
            <h3 className="text-[#00833e] text-xl tracking-wide underline ">
              {t("contact-us-head")}
            </h3>
            <p className="text-[#A3A3A3] md:text-lg text-base">
              {t("contact-form-description")}
            </p>
            <TextField
              fullWidth
              id="name"
              name="name"
              label={t("contact-form-name")}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              fullWidth
              id="email"
              name="email"
              label={t("contact-form-mail")}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              fullWidth
              id="phone"
              name="phone"
              label={t("contact-form-phone")}
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
            <TextField
              fullWidth
              id="message"
              name="message"
              label={t("contact-form-message")}
              multiline
              maxRows={10}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
            <div>
              <div className="flex items-center">
                <Checkbox
                  checked={formik.values.checked}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.checked && Boolean(formik.errors.checked)
                  }
                  helperText={formik.touched.checked && formik.errors.checked}
                  name="checked"
                  color="primary"
                />
                <p className="flex gap-1">
                  {i18n.language === "en" && (
                    <>
                      <span>I have read and accept the </span>
                      <a href="/kvkk" target="_blank" className="underline">
                        Terms of Service
                      </a>
                    </>
                  )}
                  {i18n.language === "tr" && (
                    <>
                      <a href="/kvkk" target="_blank" className="underline">
                        Aydınlatma Metni
                      </a>
                      <span> koşullarını kabul ediyorum.</span>
                    </>
                  )}
                  {i18n.language === "no" && (
                    <>
                      <span>Jeg bekrefter å ha lest og forstått vilkår i </span>
                      <a href="/kvkk" target="_blank" className="underline">
                        samsvarserklæringen
                      </a>
                    </>
                  )}
                </p>
              </div>
              <div className="flex">
                <span>
                  <Checkbox
                    checked={formik.values.checked2}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.checked2 && Boolean(formik.errors.checked2)
                    }
                    helperText={
                      formik.touched.checked2 && formik.errors.checked2
                    }
                    name="checked2"
                    color="primary"
                  />
                </span>
                <div>
                  {i18n.language === "en" && (
                    <>
                      <span>
                        In accordance with the clarification text on the
                        processing of{" "}
                      </span>
                      <a
                        href="/personal-data"
                        target="_blank"
                        className="underline"
                      >
                        personal data, {" "}
                      </a>
                      <span>
                        I hereby give my consent/permission for my contact
                        information, which I have provided through the website
                        contact form and shared with BMA Teknoloji Anonim
                        Şirketi (the Company), to be used for sending commercial
                        communications to me via the method(s) I have selected
                        below. This includes the delivery of general or
                        personalized campaigns, celebrations, offers,
                        promotions, advertisements, announcements, newsletters,
                        informational updates, customer satisfaction surveys
                        related to our products and services, and invitations to
                        competitions, raffles, events, and other activities via
                        phone calls, SMS, or email. I also consent to the
                        recording and storage of this information to be
                        submitted to the Ministry of Customs and Trade.
                      </span>
                    </>
                  )}
                  {i18n.language === "tr" && (
                    <>
                      <a
                        href="/personal-data"
                        target="_blank"
                        className="underline"
                      >
                        Kişisel verilerin işlenmesi hakkında aydınlatma metni
                        kapsamında,{" "}
                      </a>
                      <span>
                        internet sitesi iletişim formu üzerinden beyan ettiğim
                        ve BMA Teknoloji Anonim Şirketi (Şirket) ile
                        paylaşacağım iletişim bilgilerime, genel veya bana özel
                        kişiselleştirilmiş kampanyalar, kutlama, avantajlar,
                        promosyonlar, reklamlar, duyuru, e-bülten,
                        bilgilendirmeler, pazarlama faaliyetleri kapsamında ürün
                        ve hizmetlerimizle ilgili müşteri memnuniyetine yönelik
                        anketlerin, yarışma, çekiliş, davet, açılış ve diğer
                        etkinliklere ilişkin davetlerin iletilmesi amaçlarıyla
                        aşağıdaki kutularda işaretlediğim yöntemle, tarafıma
                        arama, sms ya da e-posta vasıtasıyla ticari ileti
                        gönderilmesini ve Gümrük ve Ticaret Bakanlığı’na
                        sunulmak üzere kayıt altına alınarak saklanmasına
                        onay/izin veriyorum.
                      </span>
                    </>
                  )}
                  {i18n.language === "no" && (
                    <>
                      <span>Jeg samtykker, i samsvar </span>
                      <a
                        href="/personal-data"
                        target="_blank"
                        className="underline"
                      >
                        med samsvrserklringen
                      </a>
                      <span>
                        {" "}
                        , Jeg gir mitt samtykke/tillatelse til at kontaktinformasjonen min, som jeg har gitt gjennom nettsidens kontaktskjema og delt med BMA Teknoloji Anonim Şirketi (Selskapet), kan brukes til å sende kommersiell kommunikasjon til meg via metoden(e) jeg har valgt nedenfor. Dette inkluderer levering av generelle eller personlige kampanjer, feiringer, tilbud, kampanjer, annonser, kunngjøringer, nyhetsbrev, informasjonsoppdateringer, kundeundersøkelser knyttet til våre produkter og tjenester, og invitasjoner til konkurranser, trekninger, arrangementer og andre aktiviteter via telefonsamtaler, SMS eller e-post. Jeg samtykker også til at denne informasjonen blir registrert og lagret for å bli sendt til Handels- og Tollsdepartementet.
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <Button color="success" variant="contained" fullWidth type="submit">
              {t("contact-form-submit")}
            </Button>
          </form>
        </div>
        <div className="w-full xl:h-[80vh] md:w-3/5 h-1/2 md:h-full">
          <div className="w-full md:h-[80vh] xl:h-full h-[50vh]">
            {informations.map}
          </div>
          {/* <img src={Main} alt="main" className="w-full h-full object-cover" /> */}
        </div>
      </div>
      <div className="w-full border-[1px] relative md:mt-20 mt-14">
        <div className="absolute -top-16 left-24">
          <div className=" h-16 w-[1px] bg-[#D8D8D8]"></div>
          <div className="absolute animate-ping -top-5 -left-5 w-10 h-10 rounded-[100%] border-[1px] border-[#D8D8D8]"></div>
          <div className="absolute -top-5 -left-5 w-10 h-10 rounded-[100%] border-[1px] border-[#D8D8D8]">
            <div className="absolute top-[52%] left-[51%] -translate-x-1/2 -translate-y-1/2 w-1 h-1 bg-[#D8D8D8] rounded-[100%]"></div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex gap-2 text-[#A3A3A3] md:p-6 p-2 md:text-base text-xs">
            <Link to="/">{t("home-page")}</Link>
            <p>{`>`}</p>
            <p className="text-black">{t("contact-page")}</p>
          </div>
          <div className="flex justify-center items-center md:p-6 p-2 md:text-lg text-sm">
            {filter.length > 0 && (
              <Filter filter={filterFunc} filterText={filter} />
            )}
            <p></p>
          </div>
        </div>
        <div
          ref={animationParent}
          className="md:p-24 p-6 flex flex-wrap border-t-[1px] text-lg gap-12 justify-center"
        >
          {references !== null &&
            references.length > 0 &&
            filtered.map((item, index) => (
              <div
                data-aos="flip-left"
                key={index}
                className="group lg:w-[28%] md:w-[40%] w-[85%] aspect-[3/4] flex flex-col   transition-all  hover:shadow-lg"
              >
                <div className="h-[66%] w-full overflow-hidden">
                  <img
                    src={
                      process.env.REACT_APP_URL +
                      "/uploads/contact/" +
                      item.unique +
                      "." +
                      item.last
                    }
                    alt="ref"
                    className="w-full h-full object-cover group-hover:scale-110 scale-100 transition-all"
                  />
                </div>
                <div className="flex gap-2 justify-center  flex-col p-2">
                  <div className="flex flex-col">
                    <p className="text-black lg:text-3xl md:text-xl">
                      {item.name}
                    </p>
                    <p className="text-[#00833e] lg:text-lg text-base text-ellipsis line-clamp-3">
                      {item.title}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[#A3A3A3] lg:text-lg text-base text-ellipsis line-clamp-3">
                      {item.number}
                    </p>
                    <p className="text-[#A3A3A3] lg:text-lg text-base text-ellipsis line-clamp-3">
                      {item.mail}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Contact;
