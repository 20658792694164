import React, { useRef, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Transition from "../utils/Transition";

import { useTranslation } from "react-i18next";
import axios from "../../api/axios";

let GET_FINISHED_REFERENCES = "/get/finished-references/index.php"
let GET_SIGNED_REFERENCES = "/get/signed-references/index.php"
let GET_ALL_SYSTEMS = "/get/systems/index.php"
let GET_NEWS = "/get/news/index.php"
let GET_PRESS_BULLETINS = "/get/press-bulletins/index.php"

function SearchModal({ id, searchId, modalOpen, setModalOpen }) {
  const modalContent = useRef(null);
  const searchInput = useRef();

  const { t, i18n } = useTranslation();

  const [value, setValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [signedReferences, setSignedReferences] = useState(null);
  const [finishedReferences, setFinishedReferences] = useState(null);
  const [news, setNews] = useState(null);
  const [bulletins, setBulletins] = useState(null);
  const [pages, setPages] = useState(null)

  const combination = useCallback(() => {
    if (finishedReferences !== null && news !== null && bulletins !== null && signedReferences !== null && pages !== null) {
      let all = [];
      if (i18n.language === "en") {
        signedReferences.forEach((element) => {
          all.push({
            head: element.head,
            desc: element.scope + " " + element.customername,
            link: "/references/" + element.customername,
            last: t("bar-references-title"),
          });
        });
        finishedReferences.forEach((element) => {
          all.push({
            head: element.head,
            desc: element.scope + " " + element.customername,
            link: "/references/" + element.customername,
            last: t("bar-references-title"),
          });
        });
        news.forEach((element) => {
          all.push({
            head: element.enhead,
            desc: element.endesc,
            link: "/news/" + element.enhead,
            last: t("news-page"),
          });
        });
        bulletins.forEach((element) => {
          all.push({
            head: element.enhead,
            desc: element.endesc,
            link: "/press-bulletins/" + element.enheadd,
            last: t("press-bulletins-title"),
          });
        });
        pages.forEach((element) => {
          all.push({
            head: element.entitle,
            desc: element.encontent,
            link: "/"+element.link,
            last: element.entitle,
          });
        })
      } else {
        finishedReferences.forEach((element) => {
          all.push({
            head: element.head,
            desc: element.scope + " " + element.customername,
            link: "/references/" + element.customername,
            last: t("bar-references-title"),

          });
        });
        signedReferences.forEach((element) => {
          all.push({
            head: element.head,
            desc: element.scope + " " + element.customername,
            link: "/references/" + element.customername,
            last: t("bar-references-title"),

          });
        });
        news.forEach((element) => {
          all.push({
            head: element.trhead,
            desc: element.trdesc,
            link: "/news/" + element.unique,
            last: t("news-page"),

          });
        });
        bulletins.forEach((element) => {
          all.push({
            head: element.trhead,
            desc: element.trdesc,
            link: "/press-bulletins/" + element.unique,
            last: t("press-bulletins-title"),

          });
        });
        pages.forEach((element) => {
          all.push({
            head: element.trtitle,
            desc: element.trcontent,
            link: "/"+element.link,
            last: element.trtitle,
          });
        })
      }
      let filtered = [];
      all.forEach((item) => {
        if (
          item.head.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
          item.desc.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        ) {
          filtered.push(item);
        }
      });
      if (value.trim() === "") {
        setSearchResults([]);
      }else{
        setSearchResults(filtered);
      }
    }
  }, [signedReferences,finishedReferences, news, bulletins, i18n.language, value, t]);

  useEffect(() => {
    const timer = setTimeout(() => {
      combination();
    }, 750);
    return () => clearTimeout(timer);
  }, [combination, value]);

  useEffect(() => {
    try {
      axios.get(GET_FINISHED_REFERENCES).then((response) => {
        setFinishedReferences(response.data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  useEffect(() => {
    try {
      axios.get(GET_SIGNED_REFERENCES).then((response) => {
        setSignedReferences(response.data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  useEffect(() => {
    try {
      axios.get(GET_ALL_SYSTEMS).then((response) => {
        console.log(response)
        setPages(response.data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  useEffect(() => {
    try {
      axios.get(GET_NEWS).then((response) => {
        setNews(response.data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  useEffect(() => {
    try {
      axios.get(GET_PRESS_BULLETINS).then((response) => {
        setBulletins(response.data.data);
      });
    } catch (error) {
      console.log(error);
    }
    combination();
  }, []);
  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modalOpen || modalContent.current.contains(target)) return;
      setModalOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    modalOpen && searchInput.current.focus();
  }, [modalOpen]);

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id={id}
        className="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center transform px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className="bg-white overflow-auto max-w-2xl w-full max-h-full rounded shadow-lg"
        >
          {/* Search form */}
          <form className="border-b border-slate-200">
            <div className="relative">
              <label htmlFor={searchId} className="sr-only">
                Search
              </label>
              <input
                value={value}
                onChange={(e) => setValue(e.target.value)}
                id={searchId}
                className="w-full border-0 focus:ring-transparent placeholder-slate-400 appearance-none py-3 pl-10 pr-4"
                type="search"
                placeholder="Search Anything…"
                ref={searchInput}
              />
              <button
                className="absolute inset-0 right-auto group"
                type="submit"
                aria-label="Search"
              >
                <svg
                  className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-4 mr-2"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                  <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                </svg>
              </button>
            </div>
          </form>
          <div className="py-4 px-2">
            {/* Recent searches */}
            <div className="mb-3 last:mb-0">
              <div className="text-xs font-semibold text-slate-400 uppercase px-2 mb-2">
                Searches
              </div>
              <ul className="text-sm">
                <li>
                  {searchResults.map((result, index) => (
                    <Link
                      key={index}
                      className="flex items-center p-2 text-slate-800 hover:text-white hover:bg-indigo-500 rounded group"
                      to={result.link}
                      onClick={() => setModalOpen(!modalOpen)}
                    >
                      <div className="flex justify-between w-full">
                        <p>{result.head}</p>
                        <p className="text-[#A3A3A3] text-xs">{result.last}</p>
                      </div>
                    </Link>
                  ))}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
}

export default SearchModal;
