import React, { useEffect, useState, useRef } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Toast from "react-hot-toast";
import { useAuth } from "../../../../context/AuthContext";

import axios from "../../../../api/axios";

const GET_URL = "/get/images/index.php";
const ADD_URL = "/auth/images/add/index.php";
const DELETE_URL = "/auth/images/delete/index.php";
const DashboardMain = () => {
  const imageRef = useRef(null);
  const lowImageRef = useRef(null);
  const textRef = useRef(null);
  const textRef2 = useRef(null);
  const textRef3 = useRef(null);
  const { user } = useAuth();
  const [value, setValue] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      imageRef.current.files[0] === undefined ||
      lowImageRef.current.files[0] === undefined || textRef.current.value === "" ||
      textRef2.current.value === "" || textRef3.current.value === ""
    ) {
      Toast.error("Lütfen dosyaları ekleyin ve açıklama girin.");
      return;
    }
    const formData = new FormData();
    formData.append("image", imageRef.current.files[0]);
    formData.append("low_image", lowImageRef.current.files[0]);
    formData.append("head", textRef.current.value);
    formData.append("trhead", textRef2.current.value);
    formData.append("nohead", textRef3.current.value);
    formData.append("action", "add_image");
    const loadid = Toast.loading("Ekleniyor...");
    try {
      axios
        .post(ADD_URL, formData, {
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            Toast.success(res.data.message);
          } else {
            Toast.error(res.data.message);
          }
          Toast.dismiss(loadid);
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        });
    }
    catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (id) => {
    const formData = new FormData();
    formData.append("action", "delete_image");
    formData.append("id", id);
    const loadid = Toast.loading("Siliniyor...");

    try {
      axios.post(DELETE_URL, formData, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      }).then((res) => {
        if (res.data.status === "success") {
          Toast.success(res.data.message);
        } else {
          Toast.error(res.data.message);
        }
        Toast.dismiss(loadid);
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      });
    }
    catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      axios.get(GET_URL).then((res) => {
        if (res.data.status) {
          setValue(res.data.data);
        } else {
          Toast.error(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Cards */}
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-full bg-white shadow-lg rounded-sm border border-slate-200">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Create New One</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                <div className="flex gap-2 items-center">
                  <p>EN Head</p>
                  <input className="p-2 outline-none border-[1px]" ref={textRef} type="text" placeholder="Head" name="image" />
                </div>
                <div className="flex gap-2 items-center">
                  <p>TR Head</p>
                  <input className="p-2 outline-none border-[1px]" ref={textRef2} type="text" placeholder="Head" name="image" />
                </div>
                <div className="flex gap-2 items-center">
                  <p>NO Head</p>
                  <input className="p-2 outline-none border-[1px]" ref={textRef3} type="text" placeholder="Head" name="image" />
                </div>
                <div className="flex gap-2 items-center">
                  <p>Image</p>
                  <input ref={imageRef} type="file" name="image" />
                </div>
                <div className="flex gap-2 items-center">
                  <p>Low Image</p>
                  <input ref={lowImageRef} type="file" name="lowImage" />
                </div>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Submit
                </Button>
              </form>
            </AccordionDetails>
          </Accordion>
        </div>
        {value !== null &&
          value.map((item) => (
            <div
              key={item.unique}
              className="col-span-full md:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200"
            >
              <div className="p-4 flex flex-col gap-2">
                <img
                  src={
                    process.env.REACT_APP_URL +
                    "/uploads/gallery/" +
                    item.unique +
                    "-low." +
                    item.last2
                  }
                  alt="admin"
                />
                <Button
                  color="error"
                  variant="contained"
                  fullWidth
                  type="button"
                  onClick={() => handleDelete(item.id)}
                >
                  Delete
                </Button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DashboardMain;
