import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Toast from "react-hot-toast";
import TextField from "@mui/material/TextField";

import axios from "../../../../api/axios";
import { useAuth } from "../../../../context/AuthContext";

const GET_URL = "/get/page_content/index.php";
const POST_URL = "/auth/page_content/content/index.php";

function DashboardCard13(props) {
  const [value, setValue] = useState("");
  const { user } = useAuth();
  const [desc, setDesc] = useState("");
  const handleChangeDesc = (event) => {
    setDesc(event.target.value);
  };

  const [title, setTitle] = useState("");
  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };
  const [keys, setKeys] = useState("");
  const handleChangeKeys = (event) => {
    setKeys(event.target.value);
  };

  useEffect(() => {
    try {
      axios.post(GET_URL, JSON.stringify({ name: props.page }), {}).then((res) => {

        if (props.edit === "en") {
          setTitle(res.data.data[0].entitle);
          setValue(res.data.data[0].encontent);
          setDesc(res.data.data[0].endescription);
          setKeys(res.data.data[0].enkeys);
        } else if (props.edit === "tr") {
          setTitle(res.data.data[0].trtitle);
          setValue(res.data.data[0].trcontent);
          setDesc(res.data.data[0].trdescription);
          setKeys(res.data.data[0].trkeys);
        } else {
          setTitle(res.data.data[0].notitle);
          setValue(res.data.data[0].nocontent);
          setDesc(res.data.data[0].nodescription);
          setKeys(res.data.data[0].nokeys);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const submitFunc = async () => {
    try {
      const response = await axios.put(POST_URL, JSON.stringify({
        name: props.page,
        info: props.edit,
        content: value,
        desc: desc,
        title: title,
        keys: keys,
        action: "change_page_content",
      }), {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`
        }
      });
      if (response.data.status === "success") {
        Toast.success(response.data.message);
      } else {
        Toast.error(response.data.message);
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800">{props.title}</h2>
      </header>
      <div className="p-3">
        {/* Card content */}
        {/* "Today" group */}
        <div>
          <header className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm font-semibold p-2">
            Edit page
          </header>
          <ul className="my-1 flex flex-col gap-2">
            <TextField
              value={title}
              onChange={handleChangeTitle}
              size="small"
              id="standard-basic"
              label="Title"
              variant="standard"
            />
            <ReactQuill theme="snow" value={value} onChange={setValue} />
            <TextField
              value={desc}
              onChange={handleChangeDesc}
              size="small"
              id="standard-basic"
              label="Meta Description"
              variant="standard"
            />
            <TextField
              value={keys}
              onChange={handleChangeKeys}
              size="small"
              id="standard-basic"
              label="Meta Keys"
              variant="standard"
            />
            <button
              onClick={submitFunc}
              className="p-2 text-sm bg-[#00833e] text-white mt-1"
            >
              Submit
            </button>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DashboardCard13;
