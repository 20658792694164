import React from "react";

const Kvkk = () => {
  return (
    <div className="md:p-24 p-1">
      <div className="flex flex-col gap-2 border-[1px] md:p-16 p-2">
        <h3 className="font-bold text-lg">İletişim Formu Aydınlatma Metni</h3>
        <p>
          <br />
        </p>
        <p>Veri Sorumlusunun Kimliği</p>
        <p>
          <br />
        </p>
        <p>
          BMA Teknoloji Anonim Şirketi (VKN: 1780741614) (&ldquo;BMA&rdquo; veya
          &ldquo;Şirket&rdquo;) olarak, veri sorumlusu sıfatıyla iletişim
          formunu dolduran kişilerden elde ettiğimiz kişisel verilere ilişkin
          olarak 6698 sayılı Kişisel Verilerin Korunması Kanunu
          (&ldquo;KVKK&rdquo;)&rsquo;ye Avrupa Birliği&rsquo;ndeki ger&ccedil;ek
          kişilerin kişisel verilerine ilişkin olarak Genel Veri Koruma
          T&uuml;z&uuml;ğ&uuml; (GVKT)&rsquo;ye ve ilgili d&uuml;zenlemelere
          uygun hareket edebilmemizi teminen şeffaflık ve aydınlatma
          y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml; kapsamında aşağıdaki hususları
          bilgilerinize sunuyoruz.
        </p>
        <p>
          <br />
        </p>
        <p>Kişisel Verilerin İşlenmesi ve İşleme Ama&ccedil;ları&nbsp;</p>
        <p>
          <br />
        </p>
        <p>
          İnternet sitemizde bulunan iletişim formunu doldurmanız halinde, isim,
          e-posta adresiniz, telefon, mesajınızda kişisel veri paylaşmanız
          halinde, paylaştığınız kişisel veriniz işlenecektir.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          İletişim sayfamızdaki iletişim formumuz; &ouml;neri, istek, talep,
          teklif, şikayet, sipariş ve belirteceğiniz diğer konularda
          mesajlarınızı iletmek i&ccedil;in bulunmakta olup mesajınızda ve
          iletişim formundaki belirttiğiniz isim, e-posta, telefon numarası,
          mesajlarınız doğrultusunda size tekrar ulaşılabilme ve talebinize
          uygun işlem yapılması, iş faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi,
          iletişim faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi, talep ve
          şikayetlerin takibi ama&ccedil;larıyla işlenmektedir. Kişisel
          verilerinizi, yukarıda a&ccedil;ıklananlar dışında profilinizi
          etkileyen veya profilinizi oluşturan herhangi bir otomatik karar alma
          i&ccedil;in kullanılmamaktadır.
        </p>
        <p>
          <br />
        </p>
        <p>
          İşlenen Kişisel Verilerin Kimlere ve Hangi Ama&ccedil;lar ile
          Aktarılabileceği
        </p>
        <p>
          <br />
        </p>
        <p>
          İşbu Aydınlatma Metni&rsquo;nin 2. maddesinde belirtilen kişisel
          verileriniz aşağıda belirtilen ama&ccedil;larla ilgili alıcılara
          aktarılacaktır:
        </p>
        <p>
          <br />
        </p>
        <p>
          Yasal zorunluluk olması durumunda resmi kurum ve kuruluşlara,
          avukatlara
        </p>
        <p>
          <br />
        </p>
        <p>
          İletişim formlarının e-posta ile g&ouml;nderildiği ortamı hosting
          hizmeti aldığımız bilişim firması sağlamaktadır. &nbsp;Bu nedenle
          a&ccedil;ık rızanın olması halinde ilgili kişisel veriler, e-posta,
          bulut bilişim hizmetlerinin kullanılması amacıyla sınırlı olmak
          &uuml;zere, bilişim teknolojileri hizmeti alınan firmaların yurt
          i&ccedil;i ve yurt dışındaki sunucuları ile veri merkezlerine
          aktarılmaktadır.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>Kişisel Veri Toplamanın Y&ouml;ntemi ve Hukuki Sebebi</p>
        <p>
          <br />
        </p>
        <p>
          Kişisel verileriniz, işbu Aydınlatma Metni&rsquo;nin 2. maddesinde
          a&ccedil;ıklanan ama&ccedil;lar ile Kanun&rsquo;da
          &ouml;ng&ouml;r&uuml;len temel ilkelere uygun olarak Kanun&rsquo;un 5.
          maddesinde belirtilen; veri sorumlusunun hukuki
          y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;, ilgili kişi tarafından kişisel
          verinin alenileştirilmiş olması, veri sorumlusunun meşru menfaati
          hukuki sebeplerine dayalı ve bunlarla sınırlı olarak; internet
          sitemizde bulunan iletişim formunun doldurulmasıyla kısmen otomatik
          yollarla toplanabilmektedir.
        </p>
        <p>
          <br />
        </p>
        <p>5. Kişisel Verilerin Korunmasına Y&ouml;nelik Haklarınız</p>
        <p>
          <br />
        </p>
        <p>
          Kişisel verilerin korunması mevzuatı &ccedil;er&ccedil;evesinde
          kişisel verilerinizin işlenip işlenmediğini &ouml;ğrenme, işlenmişse
          buna ilişkin bilgi talep etme, işlenme amacını ve tarafımızca bu
          ama&ccedil;lara uygun olarak kullanıp kullanılmadığını &ouml;ğrenme,
          yurt i&ccedil;inde veya yurt dışında aktarıldığı
          &uuml;&ccedil;&uuml;nc&uuml; kişileri &ouml;ğrenme, eksik veya yanlış
          işlenmiş olması halinde d&uuml;zeltilmesini ve aktarılmışsa aktarılan
          &uuml;&ccedil;&uuml;nc&uuml; kişilere de bildirilmesini talep etme,
          işlenme şartlarının ortadan kalkması halinde silinmesini veya yok
          edilmesini ve aktarılmışsa aktarılan &uuml;&ccedil;&uuml;nc&uuml;
          kişilere de bildirilmesini talep etme, işlenen verilerin
          m&uuml;nhasıran otomatik sistemler vasıtasıyla analiz edilmesi
          suretiyle aleyhinize bir sonucun ortaya &ccedil;ıktığını
          d&uuml;ş&uuml;n&uuml;yorsanız bu duruma itiraz etme, KVKK&rsquo;ye
          aykırı olarak işlenmesi sebebiyle bir zarara uğramanız halinde bu
          zararın giderilmesini talep etme haklarınız bulunmaktadır.
        </p>
        <p>
          <br />
        </p>
        <p>
          KVKK&rsquo;nin &ldquo;ilgili kişinin haklarını d&uuml;zenleyen&rdquo;
          11. maddesi kapsamındaki taleplerinizi, &ldquo;Veri Sorumlusuna
          Başvuru Usul ve Esasları Hakkında Tebliğe&rdquo; g&ouml;re,
        </p>
        <p>
          <br />
        </p>
        <p>
          &ldquo;Gebze Organize Sanayi B&ouml;lgesi 1000. Cadde No:1035
          &Ccedil;ayırova / Kocaeli&rdquo; adresine bizzat yazılı başvuru ile ya
          da noter kanalıyla,&nbsp;
        </p>
        <p>
          G&uuml;venli elektronik imza veya mobil imza ile imzalanarak kayıtlı
          elektronik posta &ldquo;
          <a data-fr-linked="true" href="mailto:bmateknoloji@hs03.kep.tr">
            bmateknoloji@hs03.kep.tr
          </a>{" "}
          &rdquo; adresine,
        </p>
        <p>
          &nbsp;G&uuml;venli elektronik veya mobil imzanız ile
          &ldquo;&ldquo;[&bull;]&rdquo;&rdquo; adresine başvurunuzu
          iletebilirsiniz.
        </p>
        <p>
          <br />
        </p>
        <p>AVRUPA BİRLİĞİNE İLİŞKİN EK GİZLİLİK H&Uuml;K&Uuml;MLERİ&nbsp;</p>
        <p>
          <br />
        </p>
        <p>
          Avrupa Birliği&rsquo;ndeki ger&ccedil;ek kişilerin kişisel verilerine
          ilişkin olarak Genel Veri Koruma T&uuml;z&uuml;ğ&uuml; (GVKT)
          kapsamında, yukarıda belirtilen hususlara ek olarak bazı haklarınız
          bulunmakta olup bu konularda da sizleri bilgilendirmek isteriz.&nbsp;
        </p>
        <p>
          İletişim formunda isim, e-posta adresi, telefon bilginizi bizler ile
          paylaşmazsanız sizlerle ile iletişime ge&ccedil;emeyebilir,
          mesajınızda belirttiğiniz talebinize y&ouml;nelik işlem
          yapamayabiliriz.
        </p>
        <p>
          <br />
        </p>
        <p>Kişisel Verilerinizin Aktarılması&nbsp;</p>
        <p>
          <br />
        </p>
        <p>
          Kişisel bilgilerinizi yalnızca talebinizi işleme koymak amacıyla
          paylaşıyoruz. Bilgilerinizin paylaşıldığı &uuml;&ccedil;&uuml;nc&uuml;
          tarafların, politikalarımız doğrultusunda kişisel bilgilerinizi
          korumak i&ccedil;in uygun g&uuml;venlik &ouml;nlemlerini almasını
          zorunlu tutuyoruz. Kişisel verilerinizi paylaştığımız
          &uuml;&ccedil;&uuml;nc&uuml; tarafların kişisel bilgilerinizi kendi
          ama&ccedil;ları doğrultusunda kullanmalarına izin vermiyoruz. Kişisel
          bilgilerinizi yalnızca işbu aydınlatma metninde belirtilen
          ama&ccedil;lar doğrultusunda ve talimatlarımıza uygun olarak
          işlemelerine izin veriyoruz. Bu paylaşımın Avrupa Ekonomik Alanı
          dışına bir aktarım i&ccedil;ermesi halinde, yeterli korumanın
          bulunmamasından kaynaklanan olası riskleri dikkate alacağız. Bu
          nedenle standart s&ouml;zleşme maddelerini i&ccedil;eren veri aktarım
          anlaşmaları imzalayarak kişisel bilgilerinizin yeterince korunmasını
          sağlayacağız. Belirttiğiniz kişisel verileriniz aşağıdaki kişi
          gruplarına aktarılacaktır:&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          Yasal zorunluluk olması durumunda resmi kurum ve kuruluşlara,
          avukatlara,
        </p>
        <p>
          <br />
        </p>
        <p>
          İletişim formlarının e-posta ile g&ouml;nderildiği ortamı hosting
          hizmeti aldığımız bilişim firması sağlamaktadır. &nbsp;Bu nedenle
          rızanın olması halinde ilgili kişisel veriler, e-posta, bulut bilişim
          hizmetlerinin kullanılması amacıyla sınırlı olmak &uuml;zere, bilişim
          teknolojileri hizmeti alınan firmaların yurt i&ccedil;i ve yurt
          dışındaki sunucuları ile veri merkezlerine, aktarılacaktır.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>Kişisel verilerinizin g&uuml;venliği&nbsp;</p>
        <p>
          <br />
        </p>
        <p>
          Kişisel verilerinizin g&uuml;venliği i&ccedil;in g&uuml;venlik
          &ouml;nlemleri alınmaktadır. Kişisel verilerinizin kaybolması,
          yetkisiz kişisel tarafından erişilmesi veya ifa edilmesi gibi
          durumların &ouml;n&uuml;ne ge&ccedil;mek adıyla yayınladığımız
          politikalar kapsamında gerekli g&uuml;venlik tedbirleri şirket
          tarafından uygulanmaktadır. &nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>Haklarınız</p>
        <p>
          <br />
        </p>
        <p>
          Belirli koşullar altında ve yasaların &ouml;ng&ouml;rd&uuml;ğ&uuml;
          &ouml;l&ccedil;&uuml;de aşağıda belirtilen haklarınız bulunmaktadır.
        </p>
        <p>
          <br />
        </p>
        <p>
          Erişim hakkı kapsamında, hakkınızda tutulan kişisel verilerinizin ne
          olduğunu bilme ve kişisel verilerinizin bir kopyasını talep etme
          hakkınız bulunmaktadır. Bazı durumlarda bu hakkın istisnaları
          bulunmaktadır. &Ouml;rneğin kişisel verilerinizi sizinle paylaşmamız,
          başka bir kişinin kişisel verilerinin a&ccedil;ığa &ccedil;ıkmasına
          neden olacaksa veya bu t&uuml;r bilgileri a&ccedil;ıklamamız yasal
          olarak engelleniyorsa talebiniz reddedilebilir.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          Kişisel verilerinizin doğru, g&uuml;ncel ve eksiksiz kalmasını
          hedefliyoruz. Kişisel verilerinizi g&uuml;ncel tutabilmemiz
          i&ccedil;in, kişisel verilerinizden herhangi birinin doğru olmadığını
          veya değiştiğini bize bildirmek &uuml;zere bizimle iletişime
          ge&ccedil;ebilirsiniz.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          Belirli durumlarda kişisel verilerinizin işlenmesine itiraz etme ve
          kişisel verilerinizin engellemesini, silinmesini ve kısıtlanmasını
          talep etme hakkınız bulunmaktadır. Kişisel verilerinizi kullanmamızı
          durdurmamızı istiyorsanız bizimle iletişime
          ge&ccedil;ebilirsiniz.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          Kişisel verilerinizin yaygın olarak kullanılan, makine- okunabilir bir
          formatta size veya başka bir veri sorumlusuna verilmesini talep etme
          hakkınız bulunmaktadır.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          Kişisel verileriniz, toplandığı ama&ccedil;lar i&ccedil;in artık
          gerekli olmadığında veya başka nedenlerle kişisel verileriniz hukuka
          aykırı bir şekilde işlendiğinde, kişisel verilerinizin silinmesini
          talep etme hakkınız bulunmaktadır.
        </p>
        <p>
          <br />
        </p>
        <p>
          Kişisel verilerinizin ihlal edildiğini d&uuml;ş&uuml;n&uuml;yorsanız
          ilgili denetleyici makama şikayette bulunma hakkınız
          bulunmaktadır.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          Kimlik belgenizin ya da pasaportunuzun kopyası ile birlikte (Kimlik
          teyidinizin yapılması amacıyla), aşağıda belirtilen iletişim
          kanallarıyla iletişime ge&ccedil;erek belirtilen haklarınızı
          kullanabilirsiniz.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>Rızayı Geri &Ccedil;ekme Hakkı&nbsp;</p>
        <p>
          <br />
        </p>
        <p>
          Kişisel verilerinizin işlenmesi ve aktarılmasına rıza verdiyseniz
          onayınızı tamamen veya kısmen geri &ccedil;ekme hakkınız
          bulunmaktadır. &nbsp;Rızanızı geri &ccedil;ektiğimize ilişkin bildirim
          aldığımızda, işleme i&ccedil;in başka bir yasal dayanak olmadığı
          s&uuml;rece bilgilerinizi artık rızanızın olduğu ama&ccedil;lar
          i&ccedil;in işlenmeyecektir.
        </p>
        <p>
          <br />
        </p>
        <p>Kişisel Verilerinizin Saklanma S&uuml;resi&nbsp;</p>
        <p>
          <br />
        </p>
        <p>
          Kişisel verilerinizi yalnızca iletişim formunda yer alan mesaj
          b&ouml;l&uuml;m&uuml;ndeki talebiniz doğrultusunda işlediğimizden
          s&ouml;z konusu talepleriniz &ccedil;&ouml;z&uuml;mleninceye kadar
          topladığımız ama&ccedil;ları yerine getirmek ve yasal zorunluluk
          kapsamında gerekli olduğu s&uuml;rece saklanacaktır.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          Saklama politikamızda belirtilen kişisel verileriniz i&ccedil;in
          &ouml;zel saklama s&uuml;relerimiz hakkında daha fazla bilgi edinmek
          isterseniz bizimle iletişime ge&ccedil;ebilirsiniz. Ge&ccedil;erli
          saklama s&uuml;resinin sona ermesi &uuml;zerine kişisel verilerinizi
          ge&ccedil;erli yasa ve d&uuml;zenlemelere uygun olarak g&uuml;venli
          bir şekilde silinecek veya yok edilecektir.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>Hak ve Talepleriniz İletişim Bilgilerimiz&nbsp;</p>
        <p>
          <br />
        </p>
        <p>
          Yukarıda belirtilen haklar kapsamında taleplerinizi aşağıda belirtilen
          iletişim adreslerimize aşağıdaki iletişim kanallarıyla
          iletebilirsiniz.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          &ldquo;Gebze Organize Sanayi B&ouml;lgesi 1000. Cadde No:1035
          &Ccedil;ayırova / Kocaeli&rdquo; adresine bizzat yazılı başvuru ile ya
          da noter kanalıyla,&nbsp;
        </p>
        <p>
          G&uuml;venli elektronik imza veya mobil imza ile imzalanarak kayıtlı
          elektronik posta &ldquo;
          <a data-fr-linked="true" href="mailto:bmateknoloji@hs03.kep.tr">
            bmateknoloji@hs03.kep.tr
          </a>{" "}
          &rdquo; adresine,
        </p>
        <p>
          &nbsp;G&uuml;venli elektronik veya mobil imzanız ile
          &ldquo;&ldquo;[&bull;]&rdquo;&rdquo; adresine başvurunuzu
          iletebilirsiniz.
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>Aydınlatma Bildirimindeki Değişiklikler</p>
        <p>
          <br />
        </p>
        <p>
          Kişisel verilerinizi işleme şeklimizdeki değişiklikleri veya değişen
          yasal gereklilikleri yansıtmak amacıyla bu Aydınlatma Metnini zaman
          zaman g&uuml;ncelleme ve değiştirme hakkımızı saklı tutuyoruz.
          Aydınlatma Metninde yapabileceğimiz herhangi bir değişiklik bu sayfada
          yayınlanacaktır.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>Son G&uuml;ncelleme:&nbsp;</p>
        <p>
          <br />
        </p>
      </div>
    </div>
  );
};

export default Kvkk;
