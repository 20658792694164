import React, { useRef, useCallback, useEffect, useState } from "react";

import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

import "./SectionFive.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SectionFive = () => {
  const { t } = useTranslation();
  const [foranim, setForanim] = useState(false);
  const [reverseanim, setReverseanim] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setForanim(false);
    }, 700);
    return () => {
      clearTimeout(timeout);
    };
  }, [foranim]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setReverseanim(false);
    }, 700);
    return () => {
      clearTimeout(timeout);
    };
  }, [reverseanim]);
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className="md:py-16 md:px-32 p-3 min-h-screen bg-blue-900">
      <div className="md:h-[calc(100vh-8rem)] h-[calc(100vh-1.5rem)] flex flex-col justify-between gap-2">
        <div className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-bold flex gap-12 flex-wrap ">
          <div className="flex gap-2 flex-wrap ">
            <span
              className={`text-white opacity-60 ${
                foranim && "animation-opacity-60"
              } ${reverseanim && "animation-reverse-opacity-60"}`}
            >
              {t("bar-references-title").toLocaleUpperCase()}
            </span>
            {/* opacity-60 */}
            <span
              className={`text-stroke text-transparent opacity-40 md:block hidden ${
                foranim && "animation-opacity-40"
              } ${reverseanim && "animation-reverse-opacity-40"}`}
            >
              {t("bar-references-title").toLocaleUpperCase()}
            </span>
            {/* opacity-40 */}
            <span
              className={`text-stroke text-transparent opacity-20 md:block hidden ${
                foranim && "animation-opacity-20"
              } ${reverseanim && "animation-reverse-opacity-20"}`}
            >
              {t("bar-references-title").toLocaleUpperCase()}
            </span>
          </div>
          <div className="text-[#fff] flex gap-2">
            <button className="border-[1px] border-white" onClick={handlePrev}>
              <IoIosArrowBack />
            </button>
            <button className="border-[1px] border-white" onClick={handleNext}>
              <IoIosArrowForward />
            </button>
          </div>
        </div>
        <div className="w-full h-full">
          <div className="sectionfive w-full  h-full flex justify-center items-center">
            <Swiper
              onSlideChange={(swiper) => {
                if (
                  swiper.activeIndex > swiper.previousIndex ||
                  swiper.activeIndex === 0
                ) {
                  setForanim(true);
                } else {
                  setReverseanim(true);
                }
              }}
              ref={sliderRef}
              loop={true}
              spaceBetween={10}
            >
              <SwiperSlide>
                <div className="flex w-full h-full font-regular md:flex-row flex-col">
                  <div className="md:w-2/5 w-full md:h-full h-1/2 flex flex-col md:gap-9 gap-2 justify-center items-start overflow-auto  text-white">
                    <p className="md:text-5xl text-xl font-thin">
                      ARGOS GEORGIA
                    </p>
                    <p className="w-3/5 md:text-lg text-sm">
                      {t("homepage-references-description")}
                    </p>
                    <div>
                      <Link
                        to="/finishedreferences"
                        className="md:px-16 md:py-4 px-4 py-1 border-[1px] tracking-wide hover:bg-white hover:text-black transition-all"
                      >
                        {t("homepage-references-seeall")}
                      </Link>
                    </div>
                  </div>
                  <div className="md:w-3/5 w-full md:h-full h-1/2 md:p-12 relative">
                    <div
                      id="argos-georgia"
                      className="w-full h-full absolute top-0 left-0"
                    >
                      <iframe
                        title="ARGOS GEORGIA"
                        name="marinetraffic"
                        id="marinetraffic"
                        width="100%"
                        height="100%"
                        src="https://www.marinetraffic.com/en/ais/embed/zoom:9/centery:37.4460/centerx:24.9467/maptype:1/shownames:false/mmsi:665111129/shipid:0/fleet:seyda.ipek@bma.com.tr/fleet_id:/vtypes:/showmenu:/remember:false"
                      >
                        Browser does not support embedded objects.
                        <br />
                        <a href="http://www.marinetraffic.com/">
                          www.marinetraffic.com
                        </a>
                      </iframe>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex w-full h-full font-regular md:flex-row flex-col">
                  <div className="md:w-2/5 w-full md:h-full h-1/2 flex flex-col md:gap-9 gap-2 justify-center items-start overflow-auto  text-white">
                    <p className="md:text-5xl text-xl font-thin">
                      BJORG PAULINE
                    </p>
                    <p className="w-3/5 md:text-lg text-sm">
                      {t("homepage-references-description")}
                    </p>
                    <div>
                      <Link
                        to="/finishedreferences"
                        className="md:px-16 md:py-4 px-4 py-1 border-[1px] tracking-wide hover:bg-white hover:text-black transition-all"
                      >
                        {t("homepage-references-seeall")}
                      </Link>
                    </div>
                  </div>
                  <div className="md:w-3/5 w-full md:h-full h-1/2 md:p-12 relative">
                    <div className="w-full h-full absolute top-0 left-0">
                      <iframe
                        title="ARGOS GEORGIA"
                        name="marinetraffic"
                        id="marinetraffic"
                        width="100%"
                        height="100%"
                        frameBorder="1"
                        src="https://www.marinetraffic.com/en/ais/embed/zoom:9/centery:37.4460/centerx:24.9467/maptype:1/shownames:false/mmsi:257652000/shipid:0/fleet:seyda.ipek@bma.com.tr/fleet_id:/vtypes:/showmenu:/remember:false"
                      >
                        Browser does not support embedded objects.
                        <br />
                        <a href="https://www.marinetraffic.com/">
                          www.marinetraffic.com
                        </a>
                      </iframe>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex w-full h-full font-regular md:flex-row flex-col">
                  <div className="md:w-2/5 w-full md:h-full h-1/2 flex flex-col md:gap-9 gap-2 justify-center items-start overflow-auto  text-white">
                    <p className="md:text-5xl text-xl font-thin">
                      HARALD MARTIN
                    </p>
                    <p className="w-3/5 md:text-lg text-sm">
                      {t("homepage-references-description")}
                    </p>
                    <div>
                      <Link
                        to="/finishedreferences"
                        className="md:px-16 md:py-4 px-4 py-1 border-[1px] tracking-wide hover:bg-white hover:text-black transition-all"
                      >
                        {t("homepage-references-seeall")}
                      </Link>
                    </div>
                  </div>
                  <div className="md:w-3/5 w-full md:h-full h-1/2 md:p-12 relative">
                    <div className="w-full h-full absolute top-0 left-0">
                      <iframe
                        title="ARGOS GEORGIA"
                        name="marinetraffic"
                        id="marinetraffic"
                        width="100%"
                        height="100%"
                        frameBorder="1"
                        src="https://www.marinetraffic.com/en/ais/embed/zoom:9/centery:37.4460/centerx:24.9467/maptype:1/shownames:false/mmsi:259000840/shipid:0/fleet:seyda.ipek@bma.com.tr/fleet_id:/vtypes:/showmenu:/remember:false"
                      >
                        Browser does not support embedded objects.
                        <br />
                        <a href="https://www.marinetraffic.com/">
                          www.marinetraffic.com
                        </a>
                      </iframe>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex w-full h-full font-regular md:flex-row flex-col">
                  <div className="md:w-2/5 w-full md:h-full h-1/2 flex flex-col md:gap-9 gap-2 justify-center items-start overflow-auto  text-white">
                    <p className="md:text-5xl text-xl font-thin">
                      NORTHERN OSPREY III
                    </p>
                    <p className="w-3/5 md:text-lg text-sm">
                      {t("homepage-references-description")}
                    </p>
                    <div>
                      <Link
                        to="/finishedreferences"
                        className="md:px-16 md:py-4 px-4 py-1 border-[1px] tracking-wide hover:bg-white hover:text-black transition-all"
                      >
                        {t("homepage-references-seeall")}
                      </Link>
                    </div>
                  </div>
                  <div className="md:w-3/5 w-full md:h-full h-1/2 md:p-12 relative">
                    <div className="w-full h-full absolute top-0 left-0">
                      <iframe
                        title="ARGOS GEORGIA"
                        name="marinetraffic"
                        id="marinetraffic"
                        width="100%"
                        height="100%"
                        frameBorder="1"
                        src="https://www.marinetraffic.com/en/ais/embed/zoom:9/centery:37.4460/centerx:24.9467/maptype:1/shownames:false/mmsi:316014290/shipid:0/fleet:seyda.ipek@bma.com.tr/fleet_id:/vtypes:/showmenu:/remember:false"
                      >
                        Browser does not support embedded objects.
                        <br />
                        <a href="https://www.marinetraffic.com/">
                          www.marinetraffic.com
                        </a>
                      </iframe>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex w-full h-full font-regular md:flex-row flex-col">
                  <div className="md:w-2/5 w-full md:h-full h-1/2 flex flex-col md:gap-9 gap-2 justify-center items-start overflow-auto  text-white">
                    <p className="md:text-5xl text-xl font-thin">VERONICA</p>
                    <p className="w-3/5 md:text-lg text-sm">
                      {t("homepage-references-description")}
                    </p>
                    <div>
                      <Link
                        to="/finishedreferences"
                        className="md:px-16 md:py-4 px-4 py-1 border-[1px] tracking-wide hover:bg-white hover:text-black transition-all"
                      >
                        {t("homepage-references-seeall")}
                      </Link>
                    </div>
                  </div>
                  <div className="md:w-3/5 w-full md:h-full h-1/2 md:p-12 relative">
                    <div className="w-full h-full absolute top-0 left-0">
                      <iframe
                        title="ARGOS GEORGIA"
                        name="marinetraffic"
                        id="marinetraffic"
                        width="100%"
                        height="100%"
                        frameBorder="1"
                        src="https://www.marinetraffic.com/en/ais/embed/zoom:9/centery:37.4460/centerx:24.9467/maptype:1/shownames:false/mmsi:250006235/shipid:0/fleet:seyda.ipek@bma.com.tr/fleet_id:/vtypes:/showmenu:/remember:false"
                      >
                        Browser does not support embedded objects.
                        <br />
                        <a href="https://www.marinetraffic.com/">
                          www.marinetraffic.com
                        </a>
                      </iframe>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFive;
