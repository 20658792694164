import React, { useState } from "react";

import Hamburger from "hamburger-react";
import Default from "../../assets/bmadefault.png";
import White from "../../assets/bmawhite.png";

import {Link} from 'react-router-dom'

import { useLocation } from "react-router-dom";

import Icon1 from "../../assets/Guest/Home/SectionTwo/groupone.png";
import Icon2 from "../../assets/Guest/Home/SectionTwo/grouptwo.png";
import Icon3 from "../../assets/Guest/Home/SectionTwo/groupthree.png";
import Icon4 from "../../assets/Guest/Home/Bar/BMA-icons-15.png";
import Icon5 from "../../assets/Guest/Home/Bar/BMA-icons-19.png";
import Icon6 from "../../assets/Guest/Home/Bar/BMA-icons-23.png";
import Icon7 from "../../assets/Guest/Home/Bar/BMA-icons-32.png";

import Bg from "../../assets/map.png";

import { IoIosArrowForward } from "react-icons/io";

import "./Navbar.css";
import { useEffect } from "react";

import FilterLang from "./filter";
import SearchModal from "./SearchModal";

import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";

// for(i=0; i<sayac;i++){
//   if(dizi[i][1] === 'sabah'){

//   }
// }

const Navbar = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const { pathname } = location;

  const [isOpen, setOpen] = useState(false);
  const [searchModalOpen, setSearchModalOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <nav
      className={`${
        isOpen
          ? "bg-[#158B01] h-screen fixed top-0 left-0 w-full z-[99999]"
          : "bg-white"
      } border-gray-200 py-1  transition-all duration-500`}
    >
      <div
        className={`absolute top-0 left-0 w-full h-full ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <img src={Bg} alt="main" className="w-full h-full object-cover" />
      </div>
      <div className="w-full h-full flex flex-wrap items-center justify-between md:px-24 px-1">
        <Link to="/">
          <img
            src={isOpen ? White : Default}
            className="md:h-6 h-4 ml-2"
            alt="bma"
          />
        </Link>
        <div>
          <ul className="flex md:text-sm md:font-medium items-center">
            <li className={`${isOpen ? "flex" : "hidden"} items-center`}>
              <FilterLang />
            </li>
            <li className={`${isOpen ? "flex" : "hidden"} z-50  items-center`}>
              <button
                className="z-50"
                onClick={(e) => {
                  e.stopPropagation();
                  setSearchModalOpen(true);
                }}
              >
                <AiOutlineSearch size={30} color="#fff" />
              </button>
              <SearchModal
                id="search-modal"
                searchId="search"
                modalOpen={searchModalOpen}
                setModalOpen={setSearchModalOpen}
              />
            </li>
            <li className="relative ">
              <Hamburger
                size={30}
                direction="left"
                color={isOpen ? `#fff` : "#00833e"}
                toggled={isOpen}
                toggle={setOpen}
              />
            </li>
          </ul>
        </div>
        {isOpen && (
          <div className="w-full h-full flex flex-col justify-between py-6 lg:py-12">
            <div className="flex flex-col select-none ">
              <div className="flex items-center gap-2 text-white group relative cursor-pointer">
                <img
                  src={Icon4}
                  alt="img"
                  className="md:h-14 md:w-14 lg:h-20 lg:w-20 h-10 w-10 object-contain brightness-0 invert-[1] opacity-0 group-hover:opacity-100 transition-all"
                />
                <nav className="flex justify-between items-center lg:w-[26rem] md:w-[18rem] w-[9rem]">
                  <Link
                    to="/"
                    className="lg:text-7xl md:text-5xl text-2xl font-bold title-nav text-transparent group-hover:text-white group-hover:scale-105 scale-100"
                  >
                    {t("home-page")}
                  </Link>
                </nav>
              </div>
              <div className="flex items-center gap-2 text-white group relative cursor-pointer">
                <img
                  src={Icon5}
                  alt="img"
                  className="md:h-14 md:w-14 lg:h-20 lg:w-20 h-10 w-10 object-contain brightness-0 invert-[1] opacity-0 group-hover:opacity-100 transition-all"
                />
                <div className="flex justify-between items-center lg:w-[26rem] md:w-[18rem] w-[9rem]">
                  <p
                    className="lg:text-7xl md:text-5xl text-2xl font-bold title-nav text-transparent group-hover:text-white group-hover:scale-105 scale-100"
                  >
                    {t("bar-about-title")}
                  </p>
                  <div className="font-bold lg:text-4xl md:text-3xl hidden group-hover:flex justify-center items-center">
                    <IoIosArrowForward />
                  </div>
                </div>
                <nav className="z-[9999999] flex-col hidden group-hover:flex group-hover:translate-y-0 translate-y-12 absolute top-3 lg:left-[36rem] md:left-[28rem] left-[14rem]">
                  <Link
                    to="/about-us"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-about-aboutus")}
                  </Link>
                  <Link
                    to="/r-d"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-about-rd")}
                  </Link>
                  <Link
                    to="/downloads"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-about-downloads")}
                  </Link>
                  <Link
                    to="/career"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-about-career")}
                  </Link>
                  <Link
                    to="/values"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-about-values")}
                  </Link>
                  <Link
                    to="/policy"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-about-policy")}
                  </Link>
                  <Link
                    to="/sustainability"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-about-sustainability")}
                  </Link>
                </nav>
              </div>
              <div className="flex items-center gap-2 text-white group relative cursor-pointer">
                <img
                  src={Icon1}
                  alt="img"
                  className="md:h-14 md:w-14 lg:h-20 lg:w-20 h-10 w-10 object-contain brightness-0 invert-[1] opacity-0 group-hover:opacity-100 transition-all"
                />
                <div className="flex justify-between items-center lg:w-[26rem] md:w-[18rem] w-[9rem]">
                  <p
                    className="lg:text-7xl md:text-5xl text-2xl font-bold title-nav text-transparent group-hover:text-white group-hover:scale-105"
                  >
                    {t("bar-markets-title")}
                  </p>
                  <div className="font-bold lg:text-4xl md:text-3xl hidden group-hover:flex justify-center items-center">
                    <IoIosArrowForward />
                  </div>
                </div>
                <nav className="z-[9999999] flex-col hidden group-hover:flex group-hover:translate-y-0 translate-y-12 absolute top-3 lg:left-[36rem] md:left-[28rem] left-[14rem]">
                  <Link
                    to="/commercial"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-markets-commercial")}
                  </Link>
                  <Link
                    to="/fishing"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-markets-fishing")}
                  </Link>
                  <Link
                    to="/workboats"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-markets-workboats")}
                  </Link>
                  <Link
                    to="/ferries"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-markets-ferries")}
                  </Link>
                  <Link
                    to="/yachts"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-markets-yatch")}
                  </Link>
                  <Link
                    to="/offshore"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-markets-offshore")}
                  </Link>
                  <Link
                    to="/defense"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-markets-defense")}
                  </Link>
                </nav>
              </div>
              <div className="flex items-center gap-2 text-white group relative cursor-pointer">
                <img
                  src={Icon3}
                  alt="img"
                  className="md:h-14 md:w-14 lg:h-20 lg:w-20 h-10 w-10 object-contain brightness-0 invert-[1] opacity-0 group-hover:opacity-100 transition-all"
                />
                <div className="flex justify-between items-center lg:w-[26rem] md:w-[18rem] w-[9rem]">
                  <p
                    className="lg:text-7xl md:text-5xl text-2xl font-bold title-nav text-transparent group-hover:text-white group-hover:scale-105"
                  >
                    {t("bar-systems-title")}
                  </p>
                  <div className="font-bold lg:text-4xl md:text-3xl hidden group-hover:flex justify-center items-center">
                    <IoIosArrowForward />
                  </div>
                </div>
                <nav className="z-[9999999] flex-col hidden group-hover:flex group-hover:translate-y-0 translate-y-12 absolute top-3 lg:left-[36rem] md:left-[28rem] left-[14rem]">
                  <Link
                    to="/switchboards"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-systems-switchboards")}
                  </Link>
                  <Link
                    to="/electricaldrives"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-systems-electricaldrives")}
                  </Link>
                  <Link
                    to="/epro"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-systems-epro")}
                  </Link>
                  <Link
                    to="/EMS"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-systems-energyman")}
                  </Link>
                  <Link
                    to="/PMS"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-systems-powerman")}
                  </Link>
                  <Link
                    to="/IACS"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-systems-integrated")}
                  </Link>
                  <Link
                    to="/other"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-systems-other")}
                  </Link>
                </nav>
              </div>
              <div className="flex items-center gap-2 text-white group relative cursor-pointer">
                <img
                  src={Icon6}
                  alt="img"
                  className="md:h-14 md:w-14 lg:h-20 lg:w-20 h-10 w-10 object-contain brightness-0 invert-[1] opacity-0 group-hover:opacity-100 transition-all"
                />
                <div className="flex justify-between items-center lg:w-[26rem] md:w-[18rem] w-[9rem]">
                  <p className="lg:text-7xl md:text-5xl text-2xl font-bold title-nav text-transparent group-hover:text-white group-hover:scale-105">
                    {t("bar-media-title")}
                  </p>
                  <div className="font-bold lg:text-4xl md:text-3xl hidden group-hover:flex justify-center items-center">
                    <IoIosArrowForward />
                  </div>
                </div>
                <nav className="z-[9999999] flex-col hidden group-hover:flex group-hover:translate-y-0 translate-y-12 absolute top-3 lg:left-[36rem] md:left-[28rem] left-[14rem]">
                  <Link
                    to="/news"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-media-news")}
                  </Link>
                  <Link
                    to="/gallery"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-media-gallery")}
                  </Link>
                  <Link
                    to="/press-bulletins"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-media-press")}
                  </Link>
                  <Link
                    to="/catalogue"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-media-catalogue")}
                  </Link>
                  <Link
                    to="/facility"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("bar-media-facility")}
                  </Link>
                </nav>
              </div>
              <div className="flex items-center gap-2 text-white group relative cursor-pointer">
                <img
                  src={Icon2}
                  alt="img"
                  className="md:h-14 md:w-14 lg:h-20 lg:w-20 h-10 w-10 object-contain brightness-0 invert-[1] opacity-0 group-hover:opacity-100 transition-all"
                />
                <div className="flex justify-between items-center lg:w-[26rem] md:w-[18rem] w-[9rem]">
                  <p className="lg:text-7xl md:text-5xl text-2xl font-bold title-nav text-transparent group-hover:text-white group-hover:scale-105">
                    {t("bar-references-title")}
                  </p>
                </div>
                <nav className="z-[9999999] flex-col hidden group-hover:flex group-hover:translate-y-0 translate-y-12 absolute top-3 lg:left-[36rem] md:left-[28rem] left-[14rem]">
                  <Link
                    to="/finishedreferences"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("finishedreferences-page")}
                  </Link>
                  <Link
                    to="/signedreferences"
                    className="lg:text-3xl md:text-xl text-lg hover:font-bold transition-all"
                  >
                    {t("signedreferences-page")}
                  </Link>
                </nav>
              </div>
              <div className="flex items-center gap-2 text-white group relative cursor-pointer">
                <img
                  src={Icon7}
                  alt="img"
                  className="md:h-14 md:w-14 lg:h-20 lg:w-20 h-10 w-10 object-contain brightness-0 invert-[1] opacity-0 group-hover:opacity-100 transition-all"
                />
                <nav className="flex justify-between items-center lg:w-[26rem] md:w-[18rem] w-[9rem]">
                  <Link
                    to="/contact"
                    className="lg:text-7xl md:text-5xl text-2xl font-bold title-nav text-transparent group-hover:text-white group-hover:scale-105"
                  >
                    {t("bar-contact-title")}
                  </Link>
                </nav>
              </div>
            </div>
            <div className="flex gap-8 text-white text-3xl md:mb-0 mb-16">
              <div className="text-2xl font-bold">{t("contact-follow-us")}</div>
              <div className="flex gap-3 mb-12 z-50">
                <Link
                  target="_blank"
                  rel="noreferrer"
                  className="cursor-pointer"
                  to="https://www.facebook.com/bmatechnologygosb/"
                >
                  <FaFacebookF />
                </Link>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  className="cursor-pointer"
                  to="https://tr.linkedin.com/company/bma-technology"
                >
                  <FaLinkedinIn />
                </Link>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  className="cursor-pointer"
                  to="https://www.instagram.com/bma__technology/"
                >
                  <FaInstagram />
                </Link>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  className="cursor-pointer"
                  to="https://www.youtube.com/@bma_technology"
                >
                  <FaYoutube />
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
