import React, { useRef, useEffect, useState } from "react";
import Transition from "../../utils/Transition";
import Toast from "react-hot-toast";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "../../../../api/axios";
import { useAuth } from "../../../../context/AuthContext";

const GET_URL = "/get/contact/index.php";
const DELETE_URL = "/auth/contacts/delete/index.php";
const UPDATE_URL = "/auth/contacts/update/index.php";
const validationSchema = yup.object({
  name: yup.string("Name is required").required("Name is required"),
  title: yup.string("Title is required").required("Title is required"),
  titletr: yup.string("Title TR is required").required("Title TR is required"),
  titleno: yup.string("Title NO is required").required("Title NO is required"),
  number: yup.string("Number is required").required("Number is required"),
  mail: yup.string("Mail is required").required("Mail is required"),
});

function SearchModal({ id, reference, modalOpen, setModalOpen }) {
  const [value, setValue] = useState(null);
  const { user } = useAuth();
  const modalContent = useRef(null);

  const setValueNull = () => {
    setTimeout(() => {
      setValue(null);
    }, 200);
  };

  useEffect(() => {
    if (reference !== null) {
      const formData = new FormData();
      formData.append("info", reference);
      try {
        axios.post(GET_URL, formData).then((res) => {
          setValue(res.data.data[0]);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [modalOpen, reference]);

  const handleDelete = (id) => {
    const formData = new FormData();
    formData.append("info", id);
    const loadid = Toast.loading("Siliniyor...");
    try {
      axios.post(DELETE_URL, formData, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      }).then((res) => {
        console.log(res.data)
        if (res.data.status === "success") {
          Toast.success(res.data.message);
        } else {
          Toast.error(res.data.message);
        }
        setTimeout(() => {
          setModalOpen(false);
          setValueNull();
          window.location.reload(false);
        }, 1000);
        Toast.dismiss(loadid);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modalOpen || modalContent.current.contains(target)) return;
      setModalOpen(false);
      setValueNull();
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });
  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
      setValueNull();
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id={id}
        className="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center transform px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className="bg-white overflow-auto max-w-2xl w-full max-h-full rounded shadow-lg p-4"
        >
          {value === null && (
            <button
              type="button"
              class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-[#158A01] hover:bg-[#155A01] transition ease-in-out duration-150 cursor-not-allowed"
              disabled=""
            >
              <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Loading...
            </button>
          )}
          {value !== null && modalOpen && (
            <Formik
              initialValues={{
                name: value.name,
                title: value.title,
                titletr: value.titletr,
                titleno: value.titleno,
                number: value.number,
                mail: value.mail,
              }}
              enaleReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                const formData = new FormData();
                formData.append("content", JSON.stringify(values));
                formData.append("info", reference);
                const loadid = Toast.loading("Updating...");
                try {
                  axios.post(UPDATE_URL, formData, {
                    headers: {
                      Authorization: `Bearer ${user?.accessToken}`,
                    },
                  }).then((res) => {
                    if (res.data.status === "success") {
                      Toast.success(res.data.message);
                    } else {
                      Toast.error(res.data.message);
                    }
                    setTimeout(() => {
                      setModalOpen(false);
                      setValueNull();
                      window.location.reload(false);
                    }, 1000);
                    Toast.dismiss(loadid);
                  });
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    id="title"
                    name="title"
                    label="Title"
                    value={values.title}
                    onChange={handleChange}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                  />
                  <TextField
                    fullWidth
                    id="titletr"
                    name="titletr"
                    label="Title TR"
                    value={values.titletr}
                    onChange={handleChange}
                    error={touched.titletr && Boolean(errors.titletr)}
                    helperText={touched.titletr && errors.titletr}
                  />
                  <TextField
                    fullWidth
                    id="titleno"
                    name="titleno"
                    label="Title NO"
                    value={values.titleno}
                    onChange={handleChange}
                    error={touched.titleno && Boolean(errors.titleno)}
                    helperText={touched.titleno && errors.titleno}
                  />
                  <TextField
                    fullWidth
                    id="number"
                    name="number"
                    label="Number"
                    value={values.number}
                    onChange={handleChange}
                    error={touched.number && Boolean(errors.number)}
                    helperText={touched.number && errors.number}
                  />
                  <TextField
                    fullWidth
                    id="mail"
                    name="mail"
                    label="Mail"
                    value={values.mail}
                    onChange={handleChange}
                    error={touched.mail && Boolean(errors.mail)}
                    helperText={touched.mail && errors.mail}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      handleDelete(value.id);
                    }}
                  >
                    Delete
                  </Button>
                </form>
              )}
            </Formik>
          )}
        </div>
      </Transition>
    </>
  );
}

export default SearchModal;
