import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import Toast from "react-hot-toast";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContactModal from "./ContactModal";
import { useAuth } from "../../../../context/AuthContext";

import axios from "../../../../api/axios";

const GET_URL = "/get/contacts/index.php";
const ADD_URL = "/auth/contacts/add/index.php";

const validationSchema = yup.object({
  name: yup.string("Name is required").required("Name is required"),
  title: yup.string("Title is required").required("Title is required"),
  titletr: yup.string("Title TR is required").required("Title TR is required"),
  titleno: yup.string("Title NO is required").required("Title NO is required"),
  number: yup.string("Number is required").required("Number is required"),
  mail: yup.string("Mail is required").required("Mail is required"),
});

function Contact(props) {
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [modalRef, setModalRef] = useState(null);
  const { user } = useAuth();
  const imageRef = useRef(null);
  const [value, setValue] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      title: "",
      titletr: "",
      titleno: "",
      number: "",
      mail: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (imageRef.current.files[0] === undefined) {
        Toast.error("Lütfen dosyaları ekleyin");
        return;
      }
      const formData = new FormData();
      formData.append("content", JSON.stringify(values));
      formData.append("image", imageRef.current.files[0]);
      formData.append("action", "add_contact");
      Toast.loading("Ekleniyor...");

      try {
        axios.post(ADD_URL, formData, {
          headers: {
            Authorization: `Bearer ${user?.accessToken}`
          }
        }).then((res) => {
          if (res.data.status === "success") {
            Toast.success(res.data.message);
          } else {
            Toast.error(res.data.message);
          }
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        }
        ).catch((err) => {
          console.log(err);
        })
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    try {
      axios.get(GET_URL).then((res) => {
        if (res.data.status) {
          setValue(res.data.data);
        } else {
          Toast.error(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200 z-30">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800">{props.title}</h2>
      </header>
      <div className="p-3">
        {/* Card content */}
        {/* "Today" group */}
        <div>
          <header className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm font-semibold p-2">
            Create New One
          </header>
          <ul className="my-1">
            <li className="px-2 text-base">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Form</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-3"
                  >
                    <div className="flex gap-2 items-center">
                      <p>Image</p>
                      <input
                        ref={imageRef}
                        type="file"
                        name="image"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <TextField
                      fullWidth
                      id="name"
                      name="name"
                      label="Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField
                      fullWidth
                      id="title"
                      name="title"
                      label="Title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.title &&
                        Boolean(formik.errors.title)
                      }
                      helperText={
                        formik.touched.title && formik.errors.title
                      }
                    />
                    <TextField
                      fullWidth
                      id="titletr"
                      name="titletr"
                      label="Title TR"
                      value={formik.values.titletr}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.titletr &&
                        Boolean(formik.errors.titletr)
                      }
                      helperText={
                        formik.touched.titletr && formik.errors.titletr
                      }
                    />
                    <TextField
                      fullWidth
                      id="titleno"
                      name="titleno"
                      label="Title NO"
                      value={formik.values.titleno}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.titleno &&
                        Boolean(formik.errors.titleno)
                      }
                      helperText={
                        formik.touched.titleno && formik.errors.titleno
                      }
                    />
                    <TextField
                      fullWidth
                      id="number"
                      name="number"
                      label="Number"
                      value={formik.values.number}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.number && Boolean(formik.errors.number)
                      }
                      helperText={formik.touched.number && formik.errors.number}
                    />
                    <TextField
                      fullWidth
                      id="mail"
                      name="mail"
                      label="Mail"
                      value={formik.values.mail}
                      onChange={formik.handleChange}
                      error={formik.touched.mail && Boolean(formik.errors.mail)}
                      helperText={formik.touched.mail && formik.errors.mail}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </Button>
                  </form>
                </AccordionDetails>
              </Accordion>
            </li>
            <header className="mt-3 text-xs uppercase text-slate-400 bg-slate-50 rounded-sm font-semibold p-2">
              Edit Old Ones
            </header>
            {value.length > 0 &&
              value.map((item, index) => (
                <li
                  key={index}
                  className="p-2 mt-3 border-[1px] items-center rounded-md shadow text-base flex justify-between"
                >
                  <h3>{item.name}</h3>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalRef(item.unique);
                      setSearchModalOpen(true);
                    }}
                    className="px-3 py-1 text-[#00833e]"
                  >
                    Edit
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <ContactModal
        id="edit-contact"
        reference={modalRef}
        modalOpen={searchModalOpen}
        setModalOpen={setSearchModalOpen}
      />
    </div>
  );
}

export default Contact;
