import React, { useEffect, useState, useRef } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

// import required modules
import { Autoplay } from "swiper";
import axios from "../../api/axios";

const GET_URL = "/get/partners/index.php";

export default function App() {
  const [value, setValue] = useState(null);

  useEffect(() => {
    try {
      axios
        .get(GET_URL)
        .then((res) => {
          setValue(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <Swiper
        className=""
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        loop={true}
        navigation={true}
        modules={[Autoplay]}
        breakpoints={{
          30: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          900: {
            slidesPerView: 4,
          },
          1366: {
            slidesPerView: 5,
          },
        }}
      >
        {value !== null &&
          value.length > 0 &&
          value.map((item) => (
            <SwiperSlide key={item.unique} className="flex justify-center items-center py-12">
              <img
                className="h-24 object-contain"
                alt="logo"
                src={
                  process.env.REACT_APP_URL +
                  "uploads/partners/" +
                  item.unique +
                  "." +
                  item.last
                }
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
}
