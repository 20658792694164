import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";

import SidebarLinkGroup from "./SidebarLinkGroup";

import logo from "../../../assets/bmawhite.png";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40  lg:z-auto transition-opacity duration-200 ${sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar  lg:sidebar-expanded:!w-64 lg:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${sidebarOpen ? "translate-x-0" : "-translate-x-[24rem]"
          }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className=" text-slate-500 hover:text-slate-400 lg:hidden"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <a href="https://bma.com.tr" rel="noreferrer" target="_blank" className="block">
            <img src={logo} alt="logo" className="w-32" />
          </a>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
              <span
                className="hidden lg:block lg:sidebar-expanded:hidden  text-center w-6"
                aria-hidden="true"
              >
                •••
              </span>
              <span className=" lg:sidebar-expanded:block lg:block">Pages</span>
            </h3>
            <ul className="mt-3">
              {/* About */}
              <SidebarLinkGroup activecondition={pathname.includes("about")}>
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <button
                        className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes("about") && "hover:text-slate-200"
                          }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <svg
                              className="shrink-0 h-6 w-6"
                              viewBox="0 0 24 24"
                            >
                              <path
                                className={`fill-current text-slate-400 ${pathname.includes("about-us") &&
                                  "text-indigo-300"
                                  }`}
                                d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"
                              />
                              <path
                                className={`fill-current text-slate-700 ${pathname.includes("about-us") &&
                                  "!text-indigo-600"
                                  }`}
                                d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"
                              />
                              <path
                                className={`fill-current text-slate-600 ${pathname.includes("about-us") &&
                                  "text-indigo-500"
                                  }`}
                                d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"
                              />
                            </svg>
                            <span className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                              About
                            </span>
                          </div>
                          {/* Icon */}
                          <div className="flex shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"
                                }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </button>
                      <div className=" lg:sidebar-expanded:block lg:block">
                        <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="about-us"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                About BMA Technology
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="about-r-d"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                R&D
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="about-downloads"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Downloads
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="about-career"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Career
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="about-values"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Values
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="about-policy"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Policy
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="about-sustainability"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Sustainability
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
              {/* Markets */}
              <SidebarLinkGroup activecondition={pathname.includes("markets")}>
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <button
                        className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes("markets") && "hover:text-slate-200"
                          }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <svg
                              className="shrink-0 h-6 w-6"
                              viewBox="0 0 24 24"
                            >
                              <path
                                className={`fill-current text-slate-400 ${pathname.includes("markets") &&
                                  "text-indigo-300"
                                  }`}
                                d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"
                              />
                              <path
                                className={`fill-current text-slate-700 ${pathname.includes("markets") &&
                                  "!text-indigo-600"
                                  }`}
                                d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"
                              />
                              <path
                                className={`fill-current text-slate-600 ${pathname.includes("markets") &&
                                  "text-indigo-500"
                                  }`}
                                d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"
                              />
                            </svg>
                            <span className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                              Markets
                            </span>
                          </div>
                          {/* Icon */}
                          <div className="flex shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"
                                }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </button>
                      <div className=" lg:sidebar-expanded:block lg:block">
                        <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="markets-commercial"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Comercial Vessels
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="markets-fishing"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Fishing and Aquaculture Vessels
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="markets-workboats"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Workboats
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="markets-ferries"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Ferries
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="markets-yachts"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Yachts
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="markets-offshore"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Offshore Supply Vessels
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="markets-defense"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Defense
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
              {/* Systems */}
              <SidebarLinkGroup activecondition={pathname.includes("systems")}>
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <button
                        className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes("systems") && "hover:text-slate-200"
                          }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <svg
                              className="shrink-0 h-6 w-6"
                              viewBox="0 0 24 24"
                            >
                              <path
                                className={`fill-current text-slate-400 ${pathname.includes("systems") &&
                                  "text-indigo-300"
                                  }`}
                                d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"
                              />
                              <path
                                className={`fill-current text-slate-700 ${pathname.includes("systems") &&
                                  "!text-indigo-600"
                                  }`}
                                d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"
                              />
                              <path
                                className={`fill-current text-slate-600 ${pathname.includes("systems") &&
                                  "text-indigo-500"
                                  }`}
                                d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"
                              />
                            </svg>
                            <span className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                              Systems
                            </span>
                          </div>
                          {/* Icon */}
                          <div className="flex shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"
                                }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </button>
                      <div className=" lg:sidebar-expanded:block lg:block">
                        <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="systems-switchboards"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Switchboards
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="systems-electricaldrives"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Electrical Drives
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="systems-epro"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                E-Propulsion Control Systems
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="systems-energyman"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Energy Management Systems
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="systems-powermanagement"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="truncate w-64 text-xs font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Power Management Systems
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="systems-integrated"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Integrated Control and Automation Systems
                              </span>
                            </NavLink>
                            <NavLink
                              end
                              to="systems-other"
                              className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
                            >
                              <span className="text-sm font-medium  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                                Other Products
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>

              {/* Delivered References */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("finished") && "bg-slate-900"
                  }`}
              >
                <NavLink
                  end
                  to="finishedreferences"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes("finished") && "hover:text-slate-200"
                    }`}
                >
                  <div className="flex items-center">
                    <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current text-slate-600 ${pathname.includes("finished") && "text-indigo-500"
                          }`}
                        d="M16 13v4H8v-4H0l3-9h18l3 9h-8Z"
                      />
                      <path
                        className={`fill-current text-slate-400 ${pathname.includes("finished") && "text-indigo-300"
                          }`}
                        d="m23.72 12 .229.686A.984.984 0 0 1 24 13v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8c0-.107.017-.213.051-.314L.28 12H8v4h8v-4H23.72ZM13 0v7h3l-4 5-4-5h3V0h2Z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                      Delivered Projects
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* Signed References */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("signed") && "bg-slate-900"
                  }`}
              >
                <NavLink
                  end
                  to="signedreferences"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes("signed") && "hover:text-slate-200"
                    }`}
                >
                  <div className="flex items-center">
                    <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current text-slate-600 ${pathname.includes("signed") && "text-indigo-500"
                          }`}
                        d="M16 13v4H8v-4H0l3-9h18l3 9h-8Z"
                      />
                      <path
                        className={`fill-current text-slate-400 ${pathname.includes("signed") && "text-indigo-300"
                          }`}
                        d="m23.72 12 .229.686A.984.984 0 0 1 24 13v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8c0-.107.017-.213.051-.314L.28 12H8v4h8v-4H23.72ZM13 0v7h3l-4 5-4-5h3V0h2Z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                      Signed Projects
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* News */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("news") && "bg-slate-900"
                  }`}
              >
                <NavLink
                  end
                  to="news"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes("news") && "hover:text-slate-200"
                    }`}
                >
                  <div className="flex items-center">
                    <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current text-slate-600 ${pathname.includes("news") && "text-indigo-500"
                          }`}
                        d="M16 13v4H8v-4H0l3-9h18l3 9h-8Z"
                      />
                      <path
                        className={`fill-current text-slate-400 ${pathname.includes("news") && "text-indigo-300"
                          }`}
                        d="m23.72 12 .229.686A.984.984 0 0 1 24 13v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8c0-.107.017-.213.051-.314L.28 12H8v4h8v-4H23.72ZM13 0v7h3l-4 5-4-5h3V0h2Z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                      News
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* Contact */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("contact") && "bg-slate-900"
                  }`}
              >
                <NavLink
                  end
                  to="contact"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes("contact") && "hover:text-slate-200"
                    }`}
                >
                  <div className="flex items-center">
                    <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current text-slate-600 ${pathname.includes("contact") && "text-indigo-500"
                          }`}
                        d="M16 13v4H8v-4H0l3-9h18l3 9h-8Z"
                      />
                      <path
                        className={`fill-current text-slate-400 ${pathname.includes("contact") && "text-indigo-300"
                          }`}
                        d="m23.72 12 .229.686A.984.984 0 0 1 24 13v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8c0-.107.017-.213.051-.314L.28 12H8v4h8v-4H23.72ZM13 0v7h3l-4 5-4-5h3V0h2Z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                      Contact
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* Catalogue */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("catalogue") && "bg-slate-900"
                  }`}
              >
                <NavLink
                  end
                  to="catalogue"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes("catalogue") && "hover:text-slate-200"
                    }`}
                >
                  <div className="flex items-center">
                    <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current text-slate-600 ${pathname.includes("catalogue") && "text-indigo-500"
                          }`}
                        d="M16 13v4H8v-4H0l3-9h18l3 9h-8Z"
                      />
                      <path
                        className={`fill-current text-slate-400 ${pathname.includes("catalogue") && "text-indigo-300"
                          }`}
                        d="m23.72 12 .229.686A.984.984 0 0 1 24 13v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8c0-.107.017-.213.051-.314L.28 12H8v4h8v-4H23.72ZM13 0v7h3l-4 5-4-5h3V0h2Z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                      Catalogue
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* FACILITY */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("facility") && "bg-slate-900"
                  }`}
              >
                <NavLink
                  end
                  to="facility"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes("facility") && "hover:text-slate-200"
                    }`}
                >
                  <div className="flex items-center">
                    <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current text-slate-600 ${pathname.includes("facility") && "text-indigo-500"
                          }`}
                        d="M16 13v4H8v-4H0l3-9h18l3 9h-8Z"
                      />
                      <path
                        className={`fill-current text-slate-400 ${pathname.includes("facility") && "text-indigo-300"
                          }`}
                        d="m23.72 12 .229.686A.984.984 0 0 1 24 13v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8c0-.107.017-.213.051-.314L.28 12H8v4h8v-4H23.72ZM13 0v7h3l-4 5-4-5h3V0h2Z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                      Facility
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* Partners */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("partners") && "bg-slate-900"
                  }`}
              >
                <NavLink
                  end
                  to="partners"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes("partners") && "hover:text-slate-200"
                    }`}
                >
                  <div className="flex items-center">
                    <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current text-slate-600 ${pathname.includes("partners") && "text-indigo-500"
                          }`}
                        d="M16 13v4H8v-4H0l3-9h18l3 9h-8Z"
                      />
                      <path
                        className={`fill-current text-slate-400 ${pathname.includes("partners") && "text-indigo-300"
                          }`}
                        d="m23.72 12 .229.686A.984.984 0 0 1 24 13v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8c0-.107.017-.213.051-.314L.28 12H8v4h8v-4H23.72ZM13 0v7h3l-4 5-4-5h3V0h2Z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                      Partners
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* Press bulletins */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("press-bulletins") && "bg-slate-900"
                  }`}
              >
                <NavLink
                  end
                  to="press-bulletins"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes("press-bulletins") &&
                    "hover:text-slate-200"
                    }`}
                >
                  <div className="flex items-center">
                    <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current text-slate-600 ${pathname.includes("press-bulletins") &&
                          "text-indigo-500"
                          }`}
                        d="M16 13v4H8v-4H0l3-9h18l3 9h-8Z"
                      />
                      <path
                        className={`fill-current text-slate-400 ${pathname.includes("press-bulletins") &&
                          "text-indigo-300"
                          }`}
                        d="m23.72 12 .229.686A.984.984 0 0 1 24 13v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8c0-.107.017-.213.051-.314L.28 12H8v4h8v-4H23.72ZM13 0v7h3l-4 5-4-5h3V0h2Z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                      Press Bulletins
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* Gallery */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("gallery") && "bg-slate-900"
                  }`}
              >
                <NavLink
                  end
                  to="gallery"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes("gallery") && "hover:text-slate-200"
                    }`}
                >
                  <div className="flex items-center">
                    <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current text-slate-600 ${pathname.includes("gallery") && "text-indigo-500"
                          }`}
                        d="M16 13v4H8v-4H0l3-9h18l3 9h-8Z"
                      />
                      <path
                        className={`fill-current text-slate-400 ${pathname.includes("gallery") && "text-indigo-300"
                          }`}
                        d="m23.72 12 .229.686A.984.984 0 0 1 24 13v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8c0-.107.017-.213.051-.314L.28 12H8v4h8v-4H23.72ZM13 0v7h3l-4 5-4-5h3V0h2Z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                      Product Gallery
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* messageform */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("messageform") && "bg-slate-900"
                  }`}
              >
                <NavLink
                  end
                  to="messageform"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes("messageform") && "hover:text-slate-200"
                    }`}
                >
                  <div className="flex items-center">
                    <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current text-slate-600 ${pathname.includes("messageform") && "text-indigo-500"
                          }`}
                        d="M16 13v4H8v-4H0l3-9h18l3 9h-8Z"
                      />
                      <path
                        className={`fill-current text-slate-400 ${pathname.includes("messageform") && "text-indigo-300"
                          }`}
                        d="m23.72 12 .229.686A.984.984 0 0 1 24 13v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8c0-.107.017-.213.051-.314L.28 12H8v4h8v-4H23.72ZM13 0v7h3l-4 5-4-5h3V0h2Z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3  lg:sidebar-expanded:opacity-100 lg:opacity-100 duration-200">
                      Form Messages
                    </span>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
      </div>
    </div>
  );
}

export default Sidebar;
