import React from "react";

import Img from "../../assets/Guest/Home/Outlet/ready.png";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Ready = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="md:p-24 p-3">
      <div className="flex md:flex-row flex-col justify-between border-[1px] border-[#DDDDDD]">
        <img
          alt="bma"
          src={Img}
          className="h-96 object-cover md:w-1/3 w-full"
        />
        <div className="flex flex-col gap-6 items-stat justify-center p-4 md:w-2/5 w-full">
          <p className="2xl:text-5xl xl:text-3xl text-[#778798]">
            <span className="text-[#00833e] font-bold">
              {i18n.language === "en" && "READY"}
              {i18n.language === "tr" && "BAŞLAMAYA"}
              {i18n.language === "no" && "Klar"}
            </span>{" "}
            {i18n.language === "en" && "TO GET STARTED?"}
            {i18n.language === "tr" && "HAZIR MISIN?"}
            {i18n.language === "no" && "for å starte?"}
          </p>
          <p className="text-[#505050] xl:text-xl md:text-base">
            {t("contact-description")}
          </p>
        </div>
        <div className="flex justify-center items-center p-12">
          <Link
            to="/contact"
            className="xl:px-16 xl:py-6 md:px-8 md:py-3 px-12 py-3 text-white bg-[#158B01] hover:bg-[#fff] hover:text-[#158B01] hover:border-[#158B01] border-[1px] transition-all"
          >
            {t("contact-us")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Ready;
