import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";

import { TypeAnimation } from "react-type-animation";

import { AiOutlineArrowRight } from "react-icons/ai";

import Logo from '../../../assets/logo512.png'

import "./Chatbot.css";
import { Link } from "react-router-dom";

const Chatbot = (props) => {
  const { t } = useTranslation();

  const [type2, setType2] = useState(false);
  const [type3, setType3] = useState(false);

  return (
    <div
      className={`flex flex-col fixed bottom-4 right-4 md:bottom-8 md:right-8 ${
        props.open ? "h-[400px] w-[300px] opacity-100" : "h-0 w-0 opacity-0"
      } transition-all bg-[#ededed] rounded-3xl z-50 overflow-hidden shadow-xl duration-500`}
    >
      <div className="text-white h-[60px] bg-[#158A01] w-full flex justify-between items-center px-4">
        <div>{t("bma-title")}</div>
        <button
          onClick={() => {
            setType2(false);
            setType3(false);
            props.setOpen(false);
          }}
          className="text-xl hover:bg-[#404040] p-2 rounded-2xl transition-all"
        >
          <IoClose />
        </button>
      </div>
      <div className="w-full h-full flex gap-3 p-3">
        <div className="h-12 w-12">
            <img src={Logo} alt="logo" className="w-full h-full object-contain"/>
        </div>
        <div className="flex flex-col gap-1 w-[80%]">
          {props.open && (
            <>
              <div className="bg-white p-2 chatbot-left-triangle relative w-full">
                <TypeAnimation
                  sequence={[
                    t("chatbot-1"),
                    1000,
                    () => {
                      setType2(true); // Place optional callbacks anywhere in the array
                    },
                  ]}
                  cursor={false}
                  wrapper="div"
                />
              </div>
              {type2 && (
                <div className="bg-white p-2 chatbot-mess-2 relative w-full">
                  <TypeAnimation
                    sequence={[
                      t("chatbot-2"),
                      1000,
                      () => {
                        setType3(true); // Place optional callbacks anywhere in the array
                      },
                    ]}
                    wrapper="div"
                    cursor={false}
                  />
                </div>
              )}
              {type3 && (
                <div className="bg-[#158A01] text-white p-2 chatbot-mess-3 relative w-full">
                  <Link to="/contact" className="flex items-center gap-2 hover:translate-x-3 transition-all">
                    <AiOutlineArrowRight />
                    <TypeAnimation
                      sequence={[`${t("chatbot-3")}`, 1000]}
                      wrapper="div"
                      cursor={false}
                    />
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
