/* eslint-disable react/prop-types */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const userFromLocal = localStorage.getItem("user");
    if (userFromLocal) {
      const expires = JSON.parse(userFromLocal)?.expiresAt;
      if (expires && expires < Date.now()) {
        navigate("/cyadmin");
      }
    } else {
      navigate("/cyadmin");
    }
  }, [navigate]);

  return <>{children}</>;
};

export default PrivateRoute;
