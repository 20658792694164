import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import Toast from "react-hot-toast";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReferenceModal from "./FinishedReferenceModal";
import { useAuth } from "../../../../context/AuthContext";

import axios from "../../../../api/axios";

const GET_URL = "/get/finished-references/index.php";
const ADD_URL = "/auth/finished-references/add/index.php";

const validationSchema = yup.object({
  head: yup.string("Head is required").required("Head is required"),
  type: yup.string("Type is required").required("Type is required"),
  trtype: yup.string("TR Type is required").required("TR Type is required"),
  notype: yup.string("NO Type is required").required("NO Type is required"),
  yard: yup.string("Yard is required").required("Yard is required"),
  tryard: yup.string("TR Yard is required").required("TR Yard is required"),
  noyard: yup.string("NO Yard is required").required("NO Yard is required"),
  customername: yup
    .string("Customer Name is required")
    .required("Customer Name is required"),
  scope: yup.string("Scope is required").required("Scope is required"),
  trscope: yup.string("TR Scope is required").required("TR Scope is required"),
  noscope: yup.string("NO Scope is required").required("NO Scope is required"),
  trdesc: yup
    .string("TR Description is required")
    .required("TR Description is required"),
  endesc: yup
    .string("EN Description is required")
    .required("EN Description is required"),
  nodesc: yup
    .string("NO Description is required")
    .required("NO Description is required"),
  trkeys: yup.string("TR Keys is required").required("TR Keys is required"),
  enkeys: yup.string("EN Keys is required").required("EN Keys is required"),
  nokeys: yup.string("NO Keys is required").required("No Keys is required"),
});

function References(props) {
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [modalRef, setModalRef] = useState(null);
  const { user } = useAuth();
  const imageRef = useRef(null);
  const pdfRef = useRef(null);
  const [value, setValue] = useState("");

  const formik = useFormik({
    initialValues: {
      head: "",
      type: "",
      trtype: "",
      notype: "",
      yard: "",
      tryard: "",
      noyard: "",
      customername: "",
      scope: "",
      trscope: "",
      noscope: "",
      trdesc: "",
      endesc: "",
      nodesc: "",
      trkeys: "",
      enkeys: "",
      nokeys: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (
        imageRef.current.files[0] === undefined ||
        pdfRef.current.files[0] === undefined
      ) {
        Toast.error("Lütfen dosyaları ekleyin");
        return;
      }
      const formData = new FormData();
      formData.append("content", JSON.stringify(values));
      formData.append("image", imageRef.current.files[0]);
      formData.append("pdf", pdfRef.current.files[0]);
      formData.append("action", "add_finished_reference");
      const loadid = Toast.loading("Ekleniyor...");

      try {
        axios.post(ADD_URL, formData, {
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }).then((res) => {
          console.log(res)
          if (res.data.status === "success") {
            Toast.success(res.data.message);
          } else {
            Toast.error(res.data.message);
          }
          setTimeout(() => {
            window.location.reload(false);
          }, 700);
          Toast.dismiss(loadid);
        });
      }
      catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    try {
      axios.get(GET_URL).then((response) => {
        setValue(response.data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200 z-30">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800">{props.title}</h2>
      </header>
      <div className="p-3">
        {/* Card content */}
        {/* "Today" group */}
        <div>
          <header className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm font-semibold p-2">
            Create New One
          </header>
          <ul className="my-1">
            <li className="px-2 text-base">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Form</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-3"
                  >
                    <div className="flex gap-2 items-center">
                      <p>Image</p>
                      <input
                        ref={imageRef}
                        type="file"
                        name="image"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="flex gap-2 items-center">
                      <p>PDF</p>
                      <input
                        ref={pdfRef}
                        type="file"
                        name="pdf"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <TextField
                      fullWidth
                      id="head"
                      name="head"
                      label="Head"
                      value={formik.values.head}
                      onChange={formik.handleChange}
                      error={formik.touched.head && Boolean(formik.errors.head)}
                      helperText={formik.touched.head && formik.errors.head}
                    />
                    <TextField
                      fullWidth
                      id="type"
                      name="type"
                      label="Type"
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      error={formik.touched.type && Boolean(formik.errors.type)}
                      helperText={formik.touched.type && formik.errors.type}
                    />
                    <TextField
                      fullWidth
                      id="trtype"
                      name="trtype"
                      label="TR Type"
                      value={formik.values.trtype}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.trtype && Boolean(formik.errors.trtype)
                      }
                      helperText={formik.touched.trtype && formik.errors.trtype}
                    />
                    <TextField
                      fullWidth
                      id="notype"
                      name="notype"
                      label="NO Type"
                      value={formik.values.notype}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.notype && Boolean(formik.errors.notype)
                      }
                      helperText={formik.touched.notype && formik.errors.notype}
                    />
                    <TextField
                      fullWidth
                      id="yard"
                      name="yard"
                      label="Yard"
                      value={formik.values.yard}
                      onChange={formik.handleChange}
                      error={formik.touched.yard && Boolean(formik.errors.yard)}
                      helperText={formik.touched.yard && formik.errors.yard}
                    />
                    <TextField
                      fullWidth
                      id="tryard"
                      name="tryard"
                      label="TR Yard"
                      value={formik.values.tryard}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.tryard && Boolean(formik.errors.tryard)
                      }
                      helperText={formik.touched.tryard && formik.errors.tryard}
                    />
                    <TextField
                      fullWidth
                      id="noyard"
                      name="noyard"
                      label="NO Yard"
                      value={formik.values.noyard}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.noyard && Boolean(formik.errors.noyard)
                      }
                      helperText={formik.touched.noyard && formik.errors.noyard}
                    />
                    <TextField
                      fullWidth
                      id="customername"
                      name="customername"
                      label="Customer Name"
                      value={formik.values.customername}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.customername &&
                        Boolean(formik.errors.customername)
                      }
                      helperText={
                        formik.touched.customername &&
                        formik.errors.customername
                      }
                    />
                    <TextField
                      fullWidth
                      id="scope"
                      name="scope"
                      label="Scope"
                      value={formik.values.scope}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.scope && Boolean(formik.errors.scope)
                      }
                      helperText={formik.touched.scope && formik.errors.scope}
                    />
                    <TextField
                      fullWidth
                      id="trscope"
                      name="trscope"
                      label="TR Scope"
                      value={formik.values.trscope}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.trscope && Boolean(formik.errors.trscope)
                      }
                      helperText={
                        formik.touched.trscope && formik.errors.trscope
                      }
                    />
                    <TextField
                      fullWidth
                      id="noscope"
                      name="noscope"
                      label="NO Scope"
                      value={formik.values.noscope}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.noscope && Boolean(formik.errors.noscope)
                      }
                      helperText={
                        formik.touched.noscope && formik.errors.noscope
                      }
                    />
                    <TextField
                      fullWidth
                      id="trdesc"
                      name="trdesc"
                      label="TR Description"
                      value={formik.values.trdesc}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.trdesc && Boolean(formik.errors.trdesc)
                      }
                      helperText={formik.touched.trdesc && formik.errors.trdesc}
                    />
                    <TextField
                      fullWidth
                      id="endesc"
                      name="endesc"
                      label="EN Description"
                      value={formik.values.endesc}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.endesc && Boolean(formik.errors.endesc)
                      }
                      helperText={formik.touched.endesc && formik.errors.endesc}
                    />
                    <TextField
                      fullWidth
                      id="nodesc"
                      name="nodesc"
                      label="NO Description"
                      value={formik.values.nodesc}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.nodesc && Boolean(formik.errors.nodesc)
                      }
                      helperText={formik.touched.nodesc && formik.errors.nodesc}
                    />
                    <TextField
                      fullWidth
                      id="trkeys"
                      name="trkeys"
                      label="TR Keys"
                      value={formik.values.trkeys}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.trkeys && Boolean(formik.errors.trkeys)
                      }
                      helperText={formik.touched.trkeys && formik.errors.trkeys}
                    />
                    <TextField
                      fullWidth
                      id="enkeys"
                      name="enkeys"
                      label="EN Keys"
                      value={formik.values.enkeys}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.enkeys && Boolean(formik.errors.enkeys)
                      }
                      helperText={formik.touched.enkeys && formik.errors.enkeys}
                    />
                    <TextField
                      fullWidth
                      id="nokeys"
                      name="nokeys"
                      label="NO Keys"
                      value={formik.values.nokeys}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.nokeys && Boolean(formik.errors.nokeys)
                      }
                      helperText={formik.touched.nokeys && formik.errors.nokeys}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </Button>
                  </form>
                </AccordionDetails>
              </Accordion>
            </li>
            <header className="mt-3 text-xs uppercase text-slate-400 bg-slate-50 rounded-sm font-semibold p-2">
              Edit Old Ones
            </header>
            {value.length > 0 &&
              value.map((item, index) => (
                <li
                  key={index}
                  className="p-2 mt-3 border-[1px] items-center rounded-md shadow text-base flex justify-between"
                >
                  <h3>{item.head}</h3>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalRef(item.customername);
                      setSearchModalOpen(true);
                    }}
                    className="px-3 py-1 text-[#00833e]"
                  >
                    Edit
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <ReferenceModal
        id="edit-reference"
        reference={modalRef}
        modalOpen={searchModalOpen}
        setModalOpen={setSearchModalOpen}
      />
    </div>
  );
}

export default References;
