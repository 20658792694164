import React from 'react'



const DashboardMain = () => {
    return (
        
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

        {/* Cards */}
        <div className="grid grid-cols-12 gap-6">

          
          
        </div>

      </div>
    )
}

export default DashboardMain