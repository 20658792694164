import { Helmet } from "react-helmet-async";

const seo = (props) => {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta charSet="utf-8" />
      <meta name="keywords" content={props.keys} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      ></meta>
      <meta name="rating" content="General" />
      <meta name="target" content="All" />
      <meta name="robots" content="index,follow" />
      <meta name="revisit-after" content="1 days" />
      <meta name="og:description" content={props.description} />
      <meta name="og:title" content={props.title} />
      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={props.description} />
      <link rel="canonical" href={props?.canonical} />
    </Helmet>
  );
};

export default seo;
