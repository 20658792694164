import React from "react";
import { Link } from "react-router-dom";

import One from "../../../assets/Guest/Home/SectionTwo/groupone.png";
import Two from "../../../assets/Guest/Home/SectionTwo/grouptwo.png";
import Three from "../../../assets/Guest/Home/SectionTwo/groupthree.png";
import Main from "../../../assets/Guest/Home/SectionTwo/sectiontwomain.png";

import { useTranslation } from "react-i18next";

const SectionTwo = () => {
  const { t } = useTranslation();

  return (
    <div className="md:p-16 p-3  relative flex justify-center items-center mt-20">
      <div className="absolute top-0 left-0 w-full h-full lg:p-24 p-3 z-10">
        <img
          src={Main}
          alt="bmatech"
          className="z-10 object-cover w-full h-full"
        />
      </div>
      <div className="z-20 flex justify-center items-center  w-full h-full lg:p-24">
        <div className="w-full h-full flex flex-wrap justify-center lg:justify-between md:items-stretch items-center gap-8 lg:px-16 lg:py-20 p-3">
          <div className="group cursor-pointer lg:w-[29%] md:w-[45%] md:top-[1rem] flex flex-col items-center md:items-start justify-between md:gap-8 gap-3 relative p-6 ">
            <div className="absolute top-0 left-0 w-full h-[calc(100%-2rem)] group-hover:h-[calc(100%)] md:h-[calc(100%-5rem)] md:group-hover:h-[calc(100%)] bg-[#fff] group-hover:bg-[#158B01] transition-all"></div>
            <div className="relative z-20 flex flex-col md:gap-9 gap-3">
              <div className="relative z-20">
                <img
                  alt="bma"
                  src={One}
                  className="md:w-24 w-16 aspect-square object-contain  group-hover:brightness-0 group-hover:invert-[1]"
                />
                <div className="md:w-24 w-16 aspect-square bg-[#158B01] opacity-10 absolute top-0 left-0 rounded-[100%] translate-x-2 translate-y-2 group-hover:w-28 group-hover:h-28 group-hover:translate-x-0 group-hover:translate-y-0 transition-all"></div>
              </div>
              <div className="z-20">
                <p className="font-bold text-[#00833e] group-hover:text-white text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">
                  {t("bar-markets-title")}
                </p>
              </div>
              <div className="z-20">
                <p className="text-[#A3A3A3]  group-hover:text-white md:text-xl text-sm  md:max-w-[100%] max-w-[13rem] md:w-full lg:text-xl xl:text-2xl">
                  {t("home-section-two-markets-description")}
                </p>
              </div>
            </div>
            <div className="z-20 opacity-0 group-hover:opacity-100">
              <Link
                to="/commercial"
                className="inline-block text-white text-center hover:bg-white hover:text-[#158B01] px-2 py-1 md:px-12 md:py-4 md:text-base text-xs  border-[1px] border-white bg-[#158B01] transition-all "
              >
                {t("home-section-two-more-detail")}
              </Link>
            </div>
          </div>
          <div className="group cursor-pointer lg:w-[29%] md:w-[45%] md:top-[1rem] flex flex-col items-center md:items-start justify-between  md:gap-8 gap-3 relative p-6">
            <div className="absolute top-0 left-0 w-full h-[calc(100%-2rem)] group-hover:h-[calc(100%)] md:h-[calc(100%-5rem)] md:group-hover:h-[calc(100%)] bg-[#fff] group-hover:bg-[#158B01] transition-all"></div>
            <div className="relative z-20 flex flex-col md:gap-9 gap-3">
              <img
                alt="bma"
                src={Two}
                className="md:w-24 w-16 object-contain aspect-square  group-hover:brightness-0 group-hover:invert-[1]"
              />
              <div className="md:w-24 w-16 aspect-square bg-[#158B01] opacity-10 absolute top-0 left-0 rounded-[100%] translate-x-2 translate-y-2 group-hover:w-28 group-hover:h-28 group-hover:translate-x-0 group-hover:translate-y-0 transition-all"></div>
              <div className="z-20">
                <p className=" font-bold text-[#00833e] group-hover:text-white text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">
                  {t("bar-references-title")}
                </p>
              </div>
              <div className="z-20">
                <p className="text-[#A3A3A3] group-hover:text-white md:text-xl text-sm  md:max-w-[100%] max-w-[13rem] md:w-full lg:text-xl xl:text-2xl">
                  {t("home-section-two-references-description")}
                </p>
              </div>
            </div>
            <div className="z-20 opacity-0 group-hover:opacity-100">
              <Link
                to="/finishedreferences"
                className="inline-block text-white text-center hover:bg-white hover:text-[#158B01] px-2 py-1 md:px-12 md:py-4 md:text-base text-xs  border-[1px] border-white bg-[#158B01] transition-all "
              >
                {t("home-section-two-more-detail")}
              </Link>
            </div>
          </div>
          <div className="group cursor-pointer lg:w-[29%] md:w-[45%] md:top-[1rem] flex flex-col items-center md:items-start justify-between md:gap-8 gap-3 relative p-6">
            <div className="absolute top-0 left-0 w-full h-[calc(100%-2rem)] group-hover:h-[calc(100%)] md:h-[calc(100%-5rem)] md:group-hover:h-[calc(100%)] bg-[#fff] group-hover:bg-[#158B01] transition-all"></div>
            <div className="relative z-20 flex flex-col md:gap-9 gap-3">
              <div className="relative z-20">
                <img
                  alt="bma"
                  src={Three}
                  className="md:w-24 w-16 object-contain aspect-square  group-hover:brightness-0 group-hover:invert-[1]"
                />
                <div className="md:w-24 w-16 aspect-square bg-[#158B01] opacity-10 absolute top-0 left-0 rounded-[100%] translate-x-2 translate-y-2 group-hover:w-28 group-hover:h-28 group-hover:translate-x-0 group-hover:translate-y-0 transition-all z-20"></div>
              </div>
              <div className="z-20">
                <p className=" font-bold text-[#00833e] group-hover:text-white text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">
                  {t("bar-systems-title")}
                </p>
              </div>
              <div className="z-20">
                <p className="text-[#A3A3A3] group-hover:text-white md:text-xl text-sm md:max-w-[100%] max-w-[13rem] md:w-full lg:text-xl xl:text-2xl   ">
                  {t("home-section-two-systems-description")}
                </p>
              </div>
            </div>
            <div className="z-20 opacity-0 group-hover:opacity-100">
              <Link
                to="/switchboards"
                className="inline-block text-white text-center hover:bg-white hover:text-[#158B01] px-2 py-1 md:px-12 md:py-4 md:text-base text-xs  border-[1px] border-white bg-[#158B01] transition-all "
              >
                {t("home-section-two-more-detail")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
