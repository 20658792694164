import React, { useRef, useEffect, useState } from "react";
import Transition from "../../utils/Transition";
import Toast from "react-hot-toast";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "../../../../api/axios";
import { useAuth } from "../../../../context/AuthContext";

const GET_URL = "/get/press-bulletin/index.php";
const DELETE_URL = "/auth/press-bulletins/delete/index.php";
const UPDATE_URL = "/auth/press-bulletins/update/index.php";
const validationSchema = yup.object({
  trhead: yup.string("TR Head is required").required("TR Head is required"),
  enhead: yup
    .string("EN Head is required")
    .required("EN Head Type is required"),
  nohead: yup.string("NO Head is required").required("NO Head is required"),
  trshortdesc: yup
    .string("TR Short Description is required")
    .required("TR Short Description is required"),
  enshortdesc: yup
    .string("EN Short Description is required")
    .required("EN Short Description is required"),
  noshortdesc: yup
    .string("NO Short Description is required")
    .required("NO Short Description is required"),
  metatrdesc: yup
    .string("EN Description is required")
    .required("EN Description is required"),
  metaendesc: yup
    .string("TR Description is required")
    .required("TR Description is required"),
  metanodesc: yup
    .string("NO Description is required")
    .required("NO Description is required"),
  metaenkeys: yup.string("EN Keys is required").required("EN Keys is required"),
  metatrkeys: yup.string("TR Keys is required").required("TR Keys is required"),
  metanokeys: yup.string("NO Keys is required").required("NO Keys is required"),
});

function SearchModal({ id, reference, modalOpen, setModalOpen }) {
  const [value, setValue] = useState(null);
  const [trvalue, setTrvalue] = useState("");
  const [envalue, setEnvalue] = useState("");
  const [novalue, setNovalue] = useState("");
  const { user } = useAuth();
  const modalContent = useRef(null);

  const setValueNull = () => {
    setTimeout(() => {
      setValue(null);
    }, 200);
  };

  useEffect(() => {
    if (reference !== null) {
      const formData = new FormData();
      formData.append("info", reference);

      axios
        .post(GET_URL, formData, {
        })
        .then((res) => {
          if (res.data.status) {
            setValue(res.data.data[0]);
            setTrvalue(res.data.data[0].trdesc);
            setEnvalue(res.data.data[0].endesc);
            setNovalue(res.data.data[0].nodesc);
          } else {
            Toast.error(res.data.message);
          }
        })
    }
  }, [modalOpen, reference]);

  const handleDelete = (id) => {
    const formData = new FormData();
    formData.append("action", "delete_press_bulletin");
    formData.append("info", id);
    const loadid = Toast.loading("Siliniyor...");

    axios.post(DELETE_URL, formData, {
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
      },
    }).then((res) => {
      if (res.data.status === "success") {
        Toast.success(res.data.message);
      } else {
        Toast.error(res.data.message);
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 700);
      Toast.dismiss(loadid);
    }
    );
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modalOpen || modalContent.current.contains(target)) return;
      setModalOpen(false);
      setValueNull();
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });
  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
      setValueNull();
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id={id}
        className="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center transform px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className="bg-white overflow-auto max-w-2xl w-full max-h-full rounded shadow-lg p-4"
        >
          {value === null && (
            <button
              type="button"
              class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-[#158A01] hover:bg-[#155A01] transition ease-in-out duration-150 cursor-not-allowed"
              disabled=""
            >
              <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Loading...
            </button>
          )}
          {value !== null && modalOpen && (
            <Formik
              initialValues={{
                trhead: value.trhead,
                enhead: value.enhead,
                nohead: value.nohead,
                trshortdesc: value.trshortdesc,
                enshortdesc: value.enshortdesc,
                noshortdesc: value.noshortdesc,
                metatrdesc: value.metatrdesc,
                metaendesc: value.metaendesc,
                metanodesc: value.metanodesc,
                metatrkeys: value.metatrkeys,
                metaenkeys: value.metaenkeys,
                metanokeys: value.metanokeys,
              }}
              enaleReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                const formData = new FormData();
                formData.append("content", JSON.stringify(values));
                formData.append("info", reference);
                formData.append("trdesc", trvalue);
                formData.append("endesc", envalue);
                formData.append("nodesc", novalue);
                formData.append("action", "update_press_bulletin");
                const loadid = Toast.loading("Updating...");

                axios.post(UPDATE_URL, formData, {
                  headers: {
                    Authorization: `Bearer ${user?.accessToken}`,
                  },
                }).then((res) => {
                  if (res.data.status === "success") {
                    Toast.success(res.data.message);
                  } else {
                    Toast.error(res.data.message);
                  }
                  setTimeout(() => {
                    window.location.reload(false);
                  }, 700);
                  Toast.dismiss(loadid);
                }
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                  <TextField
                    fullWidth
                    id="trhead"
                    name="trhead"
                    label="TR Head Text"
                    value={values.trhead}
                    onChange={handleChange}
                    error={touched.trhead && Boolean(errors.trhead)}
                    helperText={touched.trhead && errors.trhead}
                  />
                  <TextField
                    fullWidth
                    id="trshortdesc"
                    name="trshortdesc"
                    label="TR Short Description"
                    value={values.trshortdesc}
                    onChange={handleChange}
                    error={touched.trshortdesc && Boolean(errors.trshortdesc)}
                    helperText={touched.trshortdesc && errors.trshortdesc}
                  />
                  <p>TR Description</p>
                  <ReactQuill
                    theme="snow"
                    value={trvalue}
                    onChange={setTrvalue}
                  />
                  <hr />
                  <TextField
                    fullWidth
                    id="enhead"
                    name="enhead"
                    label="EN Head Text"
                    value={values.enhead}
                    onChange={handleChange}
                    error={touched.enhead && Boolean(errors.enhead)}
                    helperText={touched.enhead && errors.enhead}
                  />
                  <TextField
                    fullWidth
                    id="enshortdesc"
                    name="enshortdesc"
                    label="EN Short Description"
                    value={values.enshortdesc}
                    onChange={handleChange}
                    error={touched.enshortdesc && Boolean(errors.enshortdesc)}
                    helperText={touched.enshortdesc && errors.enshortdesc}
                  />
                  <p>EN Description</p>
                  <ReactQuill
                    theme="snow"
                    value={envalue}
                    onChange={setEnvalue}
                  />
                  <hr />
                  <TextField
                    fullWidth
                    id="nohead"
                    name="nohead"
                    label="NO Head Text"
                    value={values.nohead}
                    onChange={handleChange}
                    error={touched.nohead && Boolean(errors.nohead)}
                    helperText={touched.nohead && errors.nohead}
                  />
                  <TextField
                    fullWidth
                    id="noshortdesc"
                    name="noshortdesc"
                    label="NO Short Description"
                    value={values.noshortdesc}
                    onChange={handleChange}
                    error={touched.noshortdesc && Boolean(errors.noshortdesc)}
                    helperText={touched.noshortdesc && errors.noshortdesc}
                  />
                  <p>NO Description</p>
                  <ReactQuill
                    theme="snow"
                    value={novalue}
                    onChange={setNovalue}
                  />
                  <hr />
                  <h3 className="font-bold">Meta</h3>
                  <TextField
                    fullWidth
                    id="metatrdesc"
                    name="metatrdesc"
                    label="META TR Desc"
                    value={values.metatrdesc}
                    onChange={handleChange}
                    error={touched.metatrdesc && Boolean(errors.metatrdesc)}
                    helperText={touched.metatrdesc && errors.metatrdesc}
                  />
                  <TextField
                    fullWidth
                    id="metatrkeys"
                    name="metatrkeys"
                    label="META TR Keys"
                    value={values.metatrkeys}
                    onChange={handleChange}
                    error={touched.metatrkeys && Boolean(errors.metatrkeys)}
                    helperText={touched.metatrkeys && errors.metatrkeys}
                  />
                  <TextField
                    fullWidth
                    id="metaendesc"
                    name="metaendesc"
                    label="META EN Description"
                    value={values.metaendesc}
                    onChange={handleChange}
                    error={touched.metaendesc && Boolean(errors.metaendesc)}
                    helperText={touched.metaendesc && errors.metaendesc}
                  />
                  <TextField
                    fullWidth
                    id="metaenkeys"
                    name="metaenkeys"
                    label="META EN Keys"
                    value={values.metaenkeys}
                    onChange={handleChange}
                    error={touched.metaenkeys && Boolean(errors.metaenkeys)}
                    helperText={touched.metaenkeys && errors.metaenkeys}
                  />
                  <TextField
                    fullWidth
                    id="metanodesc"
                    name="metanodesc"
                    label="META NO Description"
                    value={values.metanodesc}
                    onChange={handleChange}
                    error={touched.metanodesc && Boolean(errors.metanodesc)}
                    helperText={touched.metanodesc && errors.metanodesc}
                  />
                  <TextField
                    fullWidth
                    id="metanokeys"
                    name="metanokeys"
                    label="META NO Keys"
                    value={values.metanokeys}
                    onChange={handleChange}
                    error={touched.metanokeys && Boolean(errors.metanokeys)}
                    helperText={touched.metanokeys && errors.metanokeys}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      handleDelete(value.id);
                    }}
                  >
                    Delete
                  </Button>
                </form>
              )}
            </Formik>
          )}
        </div>
      </Transition>
    </>
  );
}

export default SearchModal;
