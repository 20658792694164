import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "bma-title": "BMA Technology",

      "slider-1-description": "Hybrid Purse Seiner / Trawler",
      "slider-2-description":
        "World’s First LNG Driven Battery Powered Electric Propulsion Live Fish Carrier",
      "slider-3-description": "World’s First Electric Tugboat",
      "slider-4-description": "Switchboards, Electrical Drives and More",

      "home-page": "Home",
      "finishedreferences-page": "Delivered Projects", // Anasayfa > Referanslar
      "news-page": "News", // Anasayfa > Haberler
      "finishedreferences-title": "Delivered Projects",
      "finishedreferences-description":
        "Discover our projects, learn the scope for our highlighted projects.",
      "finishedreferences-meta-keys":
        "References, Reference, Reference List, Reference List Page, Reference List Page Description",
      "signedreferences-page": "Signed Projects", // Anasayfa > Referanslar
      "signedreferences-title": "Signed Projects",
      "signedreferences-description":
        "Discover our projects, learn the scope for our highlighted projects.",
      "signedreferences-meta-keys":
        "References, Reference, Reference List, Reference List Page, Reference List Page Description",
      "news-title": "News",
      "news-all-text": "All News",
      "news-description": "Latest news from us",
      "news-meta-keys":
        "News, News List, News List Page, News List Page Description",
      "press-bulletins-title": "Press Bulletins",
      "press-bulletins-description": "Last news from us",
      "press-bulletins-meta-keys":
        "Press Bulletins, Press Bulletins List, Press Bulletins List Page, Press Bulletins List Page Description",
      "press-bulletins-page": "Press Bulletins",
      "aboutus-title": "About Us",
      "contact-netherland": "Netherlands",
      "contact-page": "Contact",
      "contact-title": "BMA Teknoloji A.Ş.",
      "contact-description": "For further information, please reach us.",
      "contact-us": "Contact Us",
      "contact-meta-keys": "Contact, Contact Page, Contact Page Description",
      "contact-istanbul-address":
        "Gebze Organize Sanayi Bölgesi 1000.Cad. No:1035 Çayırova - Kocaeli - Türkiye",
      "contact-antalya-adress":
        "Liman Mahallesi, 23. Sokak Ahmet Bileydi Apartmanı No:11 D:9 Konyalatı/Antalya",
      "contact-netherland-adress":
        "YBQ-Engineering B.V. Calfven 148, 4641RG Ossendrecht Netherlands",
      "contact-istanbul-phone": "+90 (262) 677 06 50",
      "contact-antalya-phone": "+90 (242) 259 52 32",
      "contact-netherland-phone": "+31 6 13 99 86 98",
      "contact-istanbul-mail": "info@bma.com.tr",
      "contact-antalya-mail": "info@bma.com.tr",
      "contact-netherland-mail": "b.blom@ybq-engineering.com",
      "contact-email": "info@bma.com.tr",
      "contact-us-head": "Contact Us",
      "contact-follow-us": "Follow Us",
      "gallery-title": "Product Gallery",
      "gallery-description": "Gallery description",
      "gallery-meta-keys":
        "Gallery, Gallery List, Gallery List Page, Gallery List Page Description",
      "gallery-page": "Product Gallery",

      "catalogue-title": "Catalogue",
      "catalogue-description": "Catalogue description",
      "catalogue-meta-keys":
        "Catalogue, Catalogue List, Catalogue List Page, Catalogue List Page Description",
      "catalogue-page": "Catalogue",
      "catalogue-redirect-text": "Go To Catalogue",

      "facility-page": "Facility",

      "bar-about-title": "About",
      "bar-about-aboutus": "About BMA Technology",
      "bar-about-rd": "R&D",
      "bar-about-downloads": "Downloads",
      "bar-about-career": "Career",
      "bar-about-values": "Values",
      "bar-about-policy": "Policy",
      "bar-about-sustainability": "Sustainability",
      "bar-markets-title": "Markets",
      "bar-markets-commercial": "Commercial Vessels",
      "bar-markets-fishing": "Fishing and Aquaculture Vessels",
      "bar-markets-workboats": "Workboats",
      "bar-markets-ferries": "Ferries",
      "bar-markets-yatch": "Yachts",
      "bar-markets-offshore": "Offshore Supply Vessels",
      "bar-markets-defense": "Defense",
      "bar-systems-title": "Systems",
      "bar-systems-switchboards": "Switchboards",
      "bar-systems-electricaldrives": "Electrical Drives",
      "bar-systems-energyman": "Energy Management Systems",
      "bar-systems-epro": "E-Propulsion Control Systems",
      "bar-systems-powerman": "Power Management Systems",
      "bar-systems-integrated": "Integrated Alarm and Control Systems",
      "bar-systems-other": "Other Products",
      "bar-media-title": "Media",
      "bar-media-gallery": "Product Gallery",
      "bar-media-news": "News & Insights",
      "bar-media-press": "Press Bulletins",
      "bar-media-catalogue": "Catalogue",
      "bar-media-facility": "Facility",
      "bar-references-title": "References",
      "bar-contact-title": "Contact",

      "home-section-two-more-detail": "More Details",
      "home-section-two-markets-description":
        "BMA Technology has various experience since 2013. Check the markets, find your best-fit solution.",
      "home-section-two-references-description":
        "Discover our projects,learn the scope for our highlighted projects.",
      "home-section-two-systems-description":
        "BMA Technology is a high end technology company providing Electrical System Solutions for marine industry.",

      "home-section-four-description":
        "BMA Technology is a high end technology company providing Electrical System Solutions for marine industry.",

      "facility-title": "Facility",
      "facility-meta-description": "Facility",
      "facility-meta-keys":
        "Facility, Facility Page, Facility Page Description",

      "homepage-references-seeall": "See All",
      "homepage-references-description":
        "Check the highlighted projects, find their current destinations. For more information, visit our references.",

      "orange-area-description":
        "BMA Technology focuses green energy and sustainability for better future and we are involving successful projects all around the world.",

      "references-all-text": "All References",
      "references-description":
        "Check the highlighted projects, find their current destinations. For more information, visit our references.",

      "downloads-title": "Downloads",
      "downloads-description": "Downloads Description",
      "downloads-meta-keys":
        " Downloads, Downloads Page, Downloads Page Description",
      "contact-coming-soon": "Coming Soon",
      "contact-form-name": "Name",
      "contact-form-mail": "Email",
      "contact-form-phone": "Phone",
      "contact-form-message": "Message",
      "contact-form-adress": "Address",
      "contact-form-description":
        "For business inquiries, you can send us email to bd@bma.com.tr",
      "contact-form-submit": "Submit",

      "orange-1": "Delivered Projects",
      "orange-2": "Signed Projects",
      "orange-3": "Shipyards",
      "orange-4": "kWh/Reduced Carbon Emissions",

      "footer-follow-us": "Follow Us",
      "footer-disclaimer": "Disclaimer",
      "footer-privacy-policy": "Privacy Policy",
      "footer-personal-data": "Personal Data",
      "footer-cookies": "Cookies Policy",

      "references-back": "Back",
      "references-yard": "Yard",
      "references-type": "Type",
      "references-scope": "Scope",
      "references-viewpdf": "View PDF",

      "footer-copyright": "Copyright © 2023. All Rights Reserved",

      "chatbot-1": "Welcome to BMA Technology.",
      "chatbot-2": "Please contact us for further assistance.",
      "chatbot-3": "Contact",
    },
  },
  tr: {
    translation: {
      "bma-title": "BMA Teknoloji",

      "slider-1-description": "Hibrit Purse Seiner / Trawler",
      "slider-2-description":
        "Dünyanın İlk LNG ile Çalışan Batarya Destekli Canlı Balık Taşıma Gemisi",
      "slider-3-description": "Dünyanın İlk Elektrikli Römorkörü",
      "slider-4-description": "Ana Panolar, Sürücü Panoları ve Daha Fazlası",
      "home-page": "Anasayfa",
      "finishedreferences-page": "Tamamlanan Projeler", // Anasayfa > Referanslar
      "news-page": "Haberler", // Anasayfa > Haberler
      "finishedreferences-title": "Tamamlanan Projeler",
      "finishedreferences-description": "Tamamlanan projelerimizi keşfet",
      "finishedreferences-meta-keys":
        "Referanslar, Referans, Referans Listesi, Referans Listesi Sayfası, Referans Listesi Sayfası Açıklaması",
      "signedreferences-page": "İmzalanmış Projeler", // Anasayfa > Referanslar
      "signedreferences-title": "İmzalanan Projeler",
      "signedreferences-description": "İmzalanan Projeler açıklaması",
      "signedreferences-meta-keys":
        "Referanslar, Referans, Referans Listesi, Referans Listesi Sayfası, Referans Listesi Sayfası Açıklaması",
      "news-title": "Haberler",
      "news-all-text": "Tüm Haberler",
      "news-description": "Bizden son gelişmeler",
      "news-meta-keys":
        "Haberler, Haber Listesi, Haber Listesi Sayfası, Haber Listesi Sayfası Açıklaması",
      "press-bulletins-title": "Basın Bültenleri",
      "press-bulletins-description": "Son Basın Bültenleri",
      "press-bulletins-meta-keys":
        "Haberler, Haber Listesi, Haber Listesi Sayfası, Haber Listesi Sayfası Açıklaması",
      "press-bulletins-page": "Basın Bültenleri",
      "aboutus-title": "Hakkımızda",
      "contact-netherland": "Hollanda",
      "contact-page": "İletişim",
      "contact-title": "BMA Teknoloji A.Ş.",
      "contact-description": "Daha fazla bilgi için lütfen bize ulaşın.",
      "contact-us": "İletişim",
      "contact-meta-keys":
        "İletişim, İletişim Sayfası, İletişim Sayfası Açıklaması",
      "contact-istanbul-address":
        "Gebze Organize Sanayi Bölgesi 1000.Cad. No:1035 Çayırova - Kocaeli - Türkiye",
      "contact-antalya-adress":
        "Liman Mahallesi, 23. Sokak Ahmet Bileydi Apartmanı No:11 D:9 Konyalatı/Antalya",
      "contact-netherland-adress":
        "YBQ-Engineering B.V. Calfven 148, 4641RG Ossendrecht Hollanda",
      "contact-istanbul-phone": "+90 (262) 677 06 50",
      "contact-antalya-phone": "+90 (242) 259 52 32",
      "contact-netherland-phone": "+31 6 13 99 86 98",
      "contact-istanbul-mail": "info@bma.com.tr",
      "contact-antalya-mail": "info@bma.com.tr",
      "contact-netherland-mail": "b.blom@ybq-engineering.com",
      "contact-email": "info@bma.com.tr",
      "contact-us-head": "Bize Ulaşın",
      "contact-follow-us": "Bizi Takip Edin",
      "gallery-title": "Ürün Galerisi",
      "gallery-description": "Galeri açıklaması",
      "gallery-meta-keys":
        "Galeri, Galeri Listesi, Galeri Listesi Sayfası, Galeri Listesi Sayfası Açıklaması",
      "gallery-page": "Ürün Galeri",
      "catalogue-title": "Katalog",
      "catalogue-description": "Katalog açıklaması",
      "catalogue-meta-keys":
        "Katalog, Katalog Listesi, Katalog Listesi Sayfası, Katalog Listesi Sayfası Açıklaması",
      "catalogue-page": "Katalog",
      "catalogue-redirect-text": "Kataloga Git",
      "facility-page": "Tesis",
      "bar-about-title": "Hakkımızda",
      "bar-about-aboutus": "BMA Teknoloji Hakkında",
      "bar-about-rd": "Ar&Ge",
      "bar-about-downloads": "Dökümanlar",
      "bar-about-career": "Kariyer",
      "bar-about-values": "Değerler",
      "bar-about-policy": "Politika",
      "bar-about-sustainability": "Sürdürülebilirlik",

      "bar-markets-title": "Pazarlar",
      "bar-markets-commercial": "Ticari Gemiler",
      "bar-markets-fishing": "Balıkçılık ve Su Ürünleri Gemileri",
      "bar-markets-workboats": "Römorkör ve Yardımcı Gemiler",
      "bar-markets-ferries": "Feribotlar",
      "bar-markets-yatch": "Yatlar",
      "bar-markets-offshore": "Açık Deniz İkmal Gemileri",
      "bar-markets-defense": "Savunma",

      "bar-systems-title": "Sistemler",
      "bar-systems-switchboards": "Ana Panolar",
      "bar-systems-electricaldrives": "Sürücü Panoları",
      "bar-systems-energyman": "Enerji Yönetim Sistemleri",
      "bar-systems-epro": "E-Tahrik Kontrol Sistemleri",
      "bar-systems-powerman": "Güç Yönetim Sistemleri",
      "bar-systems-integrated": "Entegre Alarm ve Kontrol Sistemleri",
      "bar-systems-other": "Diğer Ürünler",

      "bar-media-title": "Medya",
      "bar-media-news": "Haberler",
      "bar-media-gallery": "Ürün Galerisi",
      "bar-media-press": "Basın Bültenleri",
      "bar-media-catalogue": "Katalog",
      "bar-media-facility": "Tesis",
      "bar-references-title": "Referanslar",
      "bar-contact-title": "İletişim",

      "home-section-two-more-detail": "Detaylar",
      "home-section-two-markets-description":
        "BMA Teknoloji, 2013 yılından itibaren birçok projede yer almıştır. Pazarları inceleyin, size en uygun çözümü bulun.",
      "home-section-two-references-description":
        "Projelerimizi keşfedin, öne çıkan projelerimizin kapsamını inceleyebilirsiniz.",
      "home-section-two-systems-description":
        "BMA Teknoloji, denizcilik endüstrisi için elektrik sistem çözümleri sağlayan yüksek teknoloji şirketidir.",

      "home-section-four-description":
        "BMA Teknoloji, denizcilik endüstrisi için Elektrik Sistemi Çözümleri sağlayan yüksek son teknoloji şirketidir.",

      "facility-title": "Tesis",
      "facility-meta-description": "Tesis Açıklaması",
      "facility-meta-keys": "Tesis, Tesis Sayfası, Tesis Sayfası Açıklaması",

      "homepage-references-seeall": "Tüm Referanslar",
      "homepage-references-description":
        "Öne çıkan projelerimize göz atın,mevcut duraklarını bulun. Daha fazla bilgi için referanslarımızı ziyaret edebilirsiniz.",

      "orange-area-description":
        "BMA Teknoloji olarak daha iyi bir gelecek için yeşil enerjiye ve sürdürülebilirliğe odaklanıyor ve tüm dünyada başarılı projelere imza atıyoruz.",

      "references-all-text": "Tüm Referanslar",
      "references-description":
        "Öne çıkan projelerimize göz atın,mevcut duraklarını bulun. Daha fazla bilgi için referanslarımızı ziyaret edebilirsiniz.",

      "downloads-title": "Dökümanlar",
      "downloads-description": "Dökümanlar Açıklaması",
      "downloads-meta-keys":
        "Dökümanlar, Dökümanlar Sayfası, Dökümanlar Sayfası Açıklaması",
      "contact-coming-soon": "Yakında",
      "contact-form-name": "İsim",
      "contact-form-mail": "E-posta",
      "contact-form-phone": "Telefon",
      "contact-form-message": "Mesaj",
      "contact-form-adress": "Adres",
      "contact-form-description":
        "Teklif almak için bd@bma.com.tr adresine e-posta gönderebilirsiniz.",
      "contact-form-submit": "Başvur",

      "orange-1": "Tamamlanan Projeler",
      "orange-2": "İmzalanan Projeler",
      "orange-3": "Tersaneler",
      "orange-4": "kWh/Önlenen karbon emisyonu",

      "footer-follow-us": "Takip Edin",
      "footer-disclaimer": "Sorumluluk Bildirisi",
      "footer-privacy-policy": "Gizlilik Politikası",
      "footer-personal-data": "Kişisel Veri",
      "footer-cookies": "Çerez Politikası",

      "references-back": "Geri Dön",
      "references-yard": "Saha",
      "references-type": "Tip",
      "references-scope": "Kapsam",
      "references-viewpdf": "PDF Görüntüle",

      "footer-copyright": "© 2023. Tüm Hakları Saklıdır",

      "chatbot-1": "BMA Teknoloji'ye Hoş Geldiniz.",
      "chatbot-2":
        "Size yardımcı olabilmemiz için lütfen bizimle iletişime geçin.",
      "chatbot-3": "İletişim",
    },
  },
  no: {
    translation: {
      "bma-title": "BMA Technology",

      "slider-1-description": "Hybrid Pelagisk tråler",
      "slider-2-description":
        "Verdens første LNG drevne brønnbåt med elektrisk fremdrift og batteripakke.",
      "slider-3-description": "Første helelektriske tug båt i verden.",
      "slider-4-description": "Tavler, Dlektriske Drive og Mer",
      "home-page": "Startside",
      "finishedreferences-page": "Leverte prosjekt", // Anasayfa > Referanslar
      "news-page": "Nyheter", // Anasayfa > Haberler
      "finishedreferences-title": "Leverte prosjekt",
      "finishedreferences-description": "Leverte prosjekt",
      "finishedreferences-meta-keys":
        "prosjekt, prosjekt, Leverte prosjekt liste, Leverte prosjekt liste side, Leverte prosjekt liste side beskrivelse",
      "signedreferences-page": "Signerte prosjekt", // Anasayfa > Referanslar
      "signedreferences-title": "Signerte prosjekt",
      "signedreferences-description": "Signerte prosjekt",
      "signedreferences-meta-keys":
        "Signerte prosjekt, Signerte prosjekt, Signerte prosjekt liste, Signerte prosjekt liste side, Signerte prosjekt liste side beskrivelse",
      "news-title": "Nyheter",
      "news-all-text": "Alle Nyheter",
      "news-description": "Siste nyheter fra oss",
      "news-meta-keys":
        "Nyhet, Nyhetsliste, Nyhetsliste side, Nyhetsliste side beskrivelse",
      "press-bulletins-title": "Nyhetsbulletin",
      "press-bulletins-description": "Nyhetsbulletin beskrivelse",
      "press-bulletins-meta-keys":
        "Nyhetsbulletin, Nyhetsbulletin liste, Nyhetsbulletin liste side, Nyhetsbulletin liste side beskrivelse",
      "press-bulletins-page": "Nyhetsbulletin",
      "aboutus-title": "Om oss",
      "contact-netherland": "Nederland",
      "contact-page": "Kontakt",
      "contact-title": "BMA Teknologi",
      "contact-description": "Vennligst kontakt oss for videre support.",
      "contact-us": "Kontakt oss",
      "contact-meta-keys":
        "Kontakt, Kontakt liste, Kontakt liste side, Kontakt liste side beskrivelse",
      "contact-istanbul-address":
        "Gebze Organize Sanayi Bölgesi 1000.Cad. No:1035 Çayırova - Kocaeli - Türkiye",
      "contact-antalya-adress":
        "Liman Mahallesi, 23. Sokak Ahmet Bileydi Apartmanı No:11 D:9 Konyalatı/Antalya",
      "contact-netherland-adress":
        "YBQ-Engineering B.V. Calfven 148, 4641RG Ossendrecht Netherlands",
      "contact-istanbul-phone": "+90 (262) 677 06 50",
      "contact-antalya-phone": "+90 (242) 259 52 32",
      "contact-netherland-phone": "+31 6 13 99 86 98",
      "contact-istanbul-mail": "info@bma.com.tr",
      "contact-antalya-mail": "info@bma.com.tr",
      "contact-netherland-mail": "b.blom@ybq-engineering.com",
      "contact-email": "info@bma.com.tr",
      "contact-us-head": "Kontakt oss",
      "contact-follow-us": "Følg oss",
      "gallery-title": "Produktarkiv",
      "gallery-description": "Produktarkiv beskrivelse",
      "gallery-meta-keys":
        "Produktarkiv, Produktarkiv liste, Produktarkiv liste side, Produktarkiv liste side beskrivelse",
      "gallery-page": "Produktarkiv",
      "catalogue-title": "Katalog",
      "catalogue-description": "Katalog beskrivelse",
      "catalogue-meta-keys":
        "Katalog, Katalog liste, Katalog liste side, Katalog liste side beskrivelse",
      "catalogue-page": "Katalog",
      "catalogue-redirect-text": "Gå til katalogen",
      "facility-page": "Fasitiliteter",
      "bar-about-title": "Om oss",
      "bar-about-aboutus": "BMA Technology oss",
      "bar-about-rd": "FoU",
      "bar-about-downloads": "Nedlastinger",
      "bar-about-career": "Karriere",
      "bar-about-values": "Verdier",
      "bar-about-policy": "Prinsipper",
      "bar-about-sustainability": "Bærekraft",

      "bar-markets-title": "Markeder",
      "bar-markets-commercial": "Kommersielle fartøyer",
      "bar-markets-fishing": "Fiske og Havbruksbåter",
      "bar-markets-workboats": "Arbeidsbåter",
      "bar-markets-ferries": "Ferger og passasjerbåter",
      "bar-markets-yatch": "Yacther",
      "bar-markets-offshore": "Offshore fartøyer",
      "bar-markets-defense": "Forsvarsbåter",

      "bar-systems-title": "Systemer",
      "bar-systems-switchboards": "Tavler",
      "bar-systems-electricaldrives": "Drive system",
      "bar-systems-energyman": "Energistyring system",
      "bar-systems-epro": "Elektrisk fremdrift kontroll system",
      "bar-systems-powerman": "Strømstyring system",
      "bar-systems-integrated": "Alarm og kontroll system",
      "bar-systems-other": "Andre produkter",

      "bar-media-title": "Media",
      "bar-media-news": "Nyheter",
      "bar-media-gallery": "Produktarkiv",
      "bar-media-press": "Nyhetsbulletin",
      "bar-media-catalogue": "Katalog",
      "bar-media-facility": "Fasitiliteter",
      "bar-references-title": "Referanser",
      "bar-contact-title": "Kontakt",

      "home-section-two-more-detail": "Les mer",
      "home-section-two-markets-description":
        "BMA Technology er et etablert internasjonalt teknologiselskap, og har siden 2013 en rekke varierte referanseprosjekter og prosjekter i ulike markeder over hele verden.",
      "home-section-two-references-description":
        "Utforsk våre prosjekt og lær mer om våre utvalgte prosjekt.",
      "home-section-two-systems-description":
        "BMA Technology er et moderne teknologiselskap som tilbyr systemløsninger for elektro i marin industri.",

      "home-section-four-description":
        "BMA Technology er en leverandør som leverer løsninger for elektriske systemer for maritim industri, og har fokus på høy standard.",

      "facility-title": "Fasitiliteter",
      "facility-meta-description": "Fasitiliteter",
      "facility-meta-keys":
        "Fasitiliteter, Fasitiliteter liste, Fasitiliteter liste side, Fasitiliteter liste side beskrivelse",

      "homepage-references-seeall": "Alle Referanser",
      "homepage-references-description":
        'Sjekk de uthevede prosjektene, finn deres nåværende posisjoner. For mer informasjon, besøk "våre referanser".',

      "orange-area-description":
        "BMA Technology fokuserer på grønn energi og bærekraft for bedre fremtid, og vi er involverte i flere suksessrike prosjekter verden over.",

      "references-all-text": "Alle Referanser",
      "references-description":
        'Sjekk de uthevede prosjektene, finn deres nåværende posisjoner. For mer informasjon, besøk "våre referanser".',

      "downloads-title": "Nedlastinger",
      "downloads-description": "Nedlastinger beskrivelse",
      "downloads-meta-keys":
        "Nederlastinger, Nedlastinger liste, Nedlastinger liste side, Nedlastinger liste side beskrivelse",
      "contact-coming-soon": "Kommer snart",
      "contact-form-name": "Navn",
      "contact-form-mail": "Epost",
      "contact-form-phone": "Telefon",
      "contact-form-message": "Melding",
      "contact-form-adress": "Addresse",
      "contact-form-description":
        "For forespørsler, skriv epost til bd@bma.com.tr",
      "contact-form-submit": "Sende",

      "orange-1": "Leverte prosjekt",
      "orange-2": "Signerte prosjekt",
      "orange-3": "Verft",
      "orange-4": "Redustert forbruk og utslipp",

      "footer-follow-us": "Følg oss",
      "footer-disclaimer": "Ansvar",
      "footer-privacy-policy": "Personvernerklæring",
      "footer-personal-data": "Personlig data",
      "footer-cookies": "Informasjonskapsler",

      "references-back": "Tilbake",
      "references-yard": "Verft",
      "references-type": "Type ",
      "references-scope": "Omfang",
      "references-viewpdf": "Se PDF",

      "footer-copyright": "Copyright © 2023. Med enerett",

      "chatbot-1": "Velkommen til BMA Technology.",
      "chatbot-2": "Vennligst kontakt oss for videre support..",
      "chatbot-3": "Kontakt",
    },
  },
};

let lang = localStorage.getItem("lang") || "en";

i18n.use(initReactI18next).init({
  resources,
  lng: lang,
  fallbackLng: lang,
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});
