import React, { useEffect, useState } from "react";


import AOS from "aos";
import "aos/dist/aos.css";

import { useTranslation } from "react-i18next";

import SEO from "../../../components/SEO";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Link } from "react-router-dom";

import axios from "../../../api/axios";
const url = '/get/page_content/index.php'
const News = () => {
  const [value, setValue] = useState(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    axios.post(url, JSON.stringify({ name: "policy" })).then((res) => {
      if (res.data.data.length > 0) {
        if (i18n.language === "en") {
          setValue({
            name: res.data.data[0].name,
            title: res.data.data[0].entitle,
            desc: res.data.data[0].encontent,
            metadesc: res.data.data[0].endescription,
            metakeys: res.data.data[0].enkeys,
            last: res.data.data[0].last,
          });
        } else if (i18n.language === 'tr') {
          setValue({
            name: res.data.data[0].name,
            title: res.data.data[0].trtitle,
            desc: res.data.data[0].trcontent,
            metadesc: res.data.data[0].trdescription,
            metakeys: res.data.data[0].trkeys,
            last: res.data.data[0].last,
          });
        } else {
          setValue({
            name: res.data.data[0].name,
            title: res.data.data[0].notitle,
            desc: res.data.data[0].nocontent,
            metadesc: res.data.data[0].nodescription,
            metakeys: res.data.data[0].nokeys,
            last: res.data.data[0].last,
          });
        }
      }
    });
  }, []);

  return (
    <div className="md:px-16 p-3 flex flex-col gap-16">
      <div className="w-full border-[1px] relative">
        {value !== null && (
          <>
            <SEO
              title={value.title}
              description={value.metadesc}
              keys={value.metakeys}
            />
            
            <div className="flex justify-between">
              <div className="flex gap-2 text-[#A3A3A3] md:p-6 p-2 md:text-base text-xs">
                <Link to="/">{t("home-page")}</Link>

                <p>{`>`}</p>
                <p className="text-black">{value.title}</p>
              </div>
              <div className="flex justify-center items-center md:p-6 p-2 md:text-lg text-sm">
              </div>
            </div>
            <div className="md:p-24 p-6 flex md:flex-row flex-col-reverse md:gap-24 gap-4 border-t-[1px] text-lg">
              <div className="md:w-1/2 w-full flex flex-col gap-4">
                <ReactQuill
                  value={value.desc}
                  readOnly={true}
                  theme={"bubble"}
                />
              </div>
              <div className="md:w-1/2 w-full">
                <img
                  src={
                    process.env.REACT_APP_URL +
                    "/uploads/pages/" +
                    value.name +
                    "." +
                    value.last
                  }
                  alt="news"
                  className="object-contain"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default News;
