import { Autoplay } from "swiper";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

import SwiperButtonNext from "../../../components/SwiperButtonNext";
import SwiperButtonPrev from "../../../components/SwiperButtonPrev";

import SliderOneImg from "../../../assets/Guest/Home/SectionOne/Hardaous.jpg";
import SliderTwoImg from "../../../assets/Guest/Home/SectionOne/Bjorg.jpg";
import SliderThreeImg from "../../../assets/Guest/Home/SectionOne/Zeetug.png";
import SliderFourImg from "../../../assets/Guest/Home/SectionOne/Leila.jpg";

import { useTranslation } from "react-i18next";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";

import "./SectionOne.css";

const Mainswiper = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="sectionone">
      <Swiper
        loop={true}
        modules={[Autoplay]}
        allowTouchMove={false}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        direction={"vertical"}
        spaceBetween={50}
        slidesPerView={1}
      >
        <SwiperSlide>
          {({ isPrev, isActive }) => (
            <div
              className={`w-full h-full flex md:flex-row flex-col justify-between md:px-24 px-1 transition-all ${
                isActive ? "opacity-1" : "opacity-0"
              }`}
            >
              <div className="flex flex-col justify-center gap-6 h-2/5 w-full md:w-2/5 md:h-full">
                <div
                  className={`${
                    isActive
                      ? "opacity-1 translate-y-0"
                      : "opacity-0 translate-y-24"
                  } transition-all  md:text-5xl text-2xl text-[#808184]`}
                >
                  <p className="font-bold text-[#00833e]">
                    {i18n.language === "en" && "READY"}
                    {i18n.language === "tr" && "BAŞLAMAYA"}
                    {i18n.language === "no" && "Klar"}
                  </p>{" "}
                  {i18n.language === "en" && "TO GET STARTED?"}
                  {i18n.language === "tr" && "HAZIR MISIN?"}
                  {i18n.language === "no" && "for å starte?"}
                </div>
                <div
                  className={`md:text-xl text-base text-[#A3A3A3] transition-all delay-300 duration-500 ${
                    isActive
                      ? "opacity-1 translate-y-0"
                      : "opacity-0 translate-y-24"
                  }`}
                >
                  {t("slider-1-description")}
                </div>
                <div
                  className={`${
                    isActive
                      ? "opacity-1 translate-y-0"
                      : "opacity-0 translate-y-24"
                  } duration-500 transition-all delay-700`}
                >
                  <Link
                    className={`md:px-8 md:py-4 px-4 hover:bg-[#158B01] hover:text-white transition-all  tracking-wide border-[#158B01] border-[1px] text-[#158B01] text-xl `}
                    to="/finishedreferences"
                  >
                    {i18n.language === "en" && "More Details"}
                    {i18n.language === "tr" && "Daha Fazla Bilgi"}
                    {i18n.language === "no" && "Flere detailjer"}
                  </Link>
                </div>
              </div>
              <div className="relative h-3/5 w-full md:w-3/5 md:h-full">
                <div className="w-full h-full overflow-hidden">
                  <img
                    src={SliderOneImg}
                    alt="SliderOneImg"
                    className={`w-full h-full object-cover transition-all  duration-[5000ms] ${
                      isActive ? "scale-125" : "scale-100"
                    }`}
                  />
                </div>
                <div className="absolute top-6 left-6 text-[#00833e] flex gap-2 text-3xl">
                  <SwiperButtonPrev
                    className={
                      "p-1.5 bg-white flex justify-center items-center border-[#00833e]"
                    }
                  />
                  <SwiperButtonNext
                    className={
                      "p-1.5 bg-white flex justify-center items-center border-[#00833e]"
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isPrev, isActive }) => (
            <div
              className={`w-full h-full flex md:flex-row flex-col justify-between md:px-24 px-1 transition-all ${
                isActive ? "opacity-1" : "opacity-0"
              }`}
            >
              <div className="flex flex-col justify-center gap-6 h-2/5 w-full md:w-2/5 md:h-full">
                <div
                  className={`${
                    isActive
                      ? "opacity-1 translate-y-0"
                      : "opacity-0 translate-y-24"
                  } transition-all  md:text-5xl text-2xl text-[#808184]`}
                >
                  <p className="font-bold text-[#00833e]">
                    {i18n.language === "en" && "READY"}
                    {i18n.language === "tr" && "BAŞLAMAYA"}
                    {i18n.language === "no" && "Klar"}
                  </p>{" "}
                  {i18n.language === "en" && "TO GET STARTED?"}
                  {i18n.language === "tr" && "HAZIR MISIN?"}
                  {i18n.language === "no" && "for å starte?"}
                </div>
                <div
                  className={`md:text-xl text-base text-[#A3A3A3] transition-all delay-300 duration-500 ${
                    isActive
                      ? "opacity-1 translate-y-0"
                      : "opacity-0 translate-y-24"
                  }`}
                >
                  {t("slider-2-description")}
                </div>
                <div
                  className={`${
                    isActive
                      ? "opacity-1 translate-y-0"
                      : "opacity-0 translate-y-24"
                  } duration-500 transition-all delay-700`}
                >
                  <Link
                    className={`md:px-8 md:py-4 px-4 hover:bg-[#158B01] hover:text-white transition-all  tracking-wide border-[#158B01] border-[1px] text-[#158B01] text-xl `}
                    to="/finishedreferences"
                  >
                    {i18n.language === "en" && "More Details"}
                    {i18n.language === "tr" && "Daha Fazla Bilgi"}
                    {i18n.language === "no" && "Flere detailjer"}
                  </Link>
                </div>
              </div>
              <div className="relative h-3/5 w-full md:w-3/5 md:h-full">
                <div className="w-full h-full overflow-hidden">
                  <img
                    src={SliderTwoImg}
                    alt="SliderOneImg"
                    className={`w-full h-full object-cover transition-all  duration-[5000ms] ${
                      isActive ? "scale-125" : "scale-100"
                    }`}
                  />
                </div>
                <div className="absolute top-6 left-6 text-[#00833e] flex gap-2 text-3xl">
                  <SwiperButtonPrev
                    className={
                      "p-1.5 bg-white flex justify-center items-center border-[#00833e]"
                    }
                  />
                  <SwiperButtonNext
                    className={
                      "p-1.5 bg-white flex justify-center items-center border-[#00833e]"
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isPrev, isActive }) => (
            <div
              className={`w-full h-full flex md:flex-row flex-col justify-between md:px-24 px-1 transition-all ${
                isActive ? "opacity-1" : "opacity-0"
              }`}
            >
              <div className="flex flex-col justify-center gap-6 h-2/5 w-full md:w-2/5 md:h-full">
                <div
                  className={`${
                    isActive
                      ? "opacity-1 translate-y-0"
                      : "opacity-0 translate-y-24"
                  } transition-all  md:text-5xl text-2xl text-[#808184]`}
                >
                  <p className="font-bold text-[#00833e]">
                    {i18n.language === "en" && "READY"}
                    {i18n.language === "tr" && "BAŞLAMAYA"}
                    {i18n.language === "no" && "Klar"}
                  </p>{" "}
                  {i18n.language === "en" && "TO GET STARTED?"}
                  {i18n.language === "tr" && "HAZIR MISIN?"}
                  {i18n.language === "no" && "for å starte?"}
                </div>
                <div
                  className={`md:text-xl text-base text-[#A3A3A3] transition-all delay-300 duration-500 ${
                    isActive
                      ? "opacity-1 translate-y-0"
                      : "opacity-0 translate-y-24"
                  }`}
                >
                  {t("slider-3-description")}
                </div>
                <div
                  className={`${
                    isActive
                      ? "opacity-1 translate-y-0"
                      : "opacity-0 translate-y-24"
                  } duration-500 transition-all delay-700`}
                >
                  <Link
                    className={`md:px-8 md:py-4 px-4 hover:bg-[#158B01] hover:text-white transition-all  tracking-wide border-[#158B01] border-[1px] text-[#158B01] text-xl `}
                    to="/finishedreferences"
                  >
                    {i18n.language === "en" && "More Details"}
                    {i18n.language === "tr" && "Daha Fazla Bilgi"}
                    {i18n.language === "no" && "Flere detailjer"}
                  </Link>
                </div>
              </div>
              <div className="relative h-3/5 w-full md:w-3/5 md:h-full">
                <div className="w-full h-full overflow-hidden">
                  <img
                    src={SliderThreeImg}
                    alt="SliderOneImg"
                    className={`w-full h-full object-cover transition-all  duration-[5000ms] ${
                      isActive ? "scale-125" : "scale-100"
                    }`}
                  />
                </div>
                <div className="absolute top-6 left-6 text-[#00833e] flex gap-2 text-3xl">
                  <SwiperButtonPrev
                    className={
                      "p-1.5 bg-white flex justify-center items-center border-[#00833e]"
                    }
                  />
                  <SwiperButtonNext
                    className={
                      "p-1.5 bg-white flex justify-center items-center border-[#00833e]"
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isPrev, isActive }) => (
            <div
              className={`w-full h-full flex md:flex-row flex-col justify-between md:px-24 px-1 transition-all ${
                isActive ? "opacity-1" : "opacity-0"
              }`}
            >
              <div className="flex flex-col justify-center gap-6 h-2/5 w-full md:w-2/5 md:h-full">
                <div
                  className={`${
                    isActive
                      ? "opacity-1 translate-y-0"
                      : "opacity-0 translate-y-24"
                  } transition-all  md:text-5xl text-2xl text-[#808184]`}
                >
                  <p className="font-bold text-[#00833e]">
                    {i18n.language === "en" && "READY"}
                    {i18n.language === "tr" && "BAŞLAMAYA"}
                    {i18n.language === "no" && "Klar"}
                  </p>{" "}
                  {i18n.language === "en" && "TO GET STARTED?"}
                  {i18n.language === "tr" && "HAZIR MISIN?"}
                  {i18n.language === "no" && "for å starte?"}
                </div>
                <div
                  className={`md:text-xl text-base text-[#A3A3A3] transition-all delay-300 duration-500 ${
                    isActive
                      ? "opacity-1 translate-y-0"
                      : "opacity-0 translate-y-24"
                  }`}
                >
                  {t("slider-4-description")}
                </div>
                <div
                  className={`${
                    isActive
                      ? "opacity-1 translate-y-0"
                      : "opacity-0 translate-y-24"
                  } duration-500 transition-all delay-700`}
                >
                  <Link
                    className={`md:px-8 md:py-4 px-4 hover:bg-[#158B01] hover:text-white transition-all  tracking-wide border-[#158B01] border-[1px] text-[#158B01] text-xl `}
                    to="/switchboards"
                  >
                    {i18n.language === "en" && "More Details"}
                    {i18n.language === "tr" && "Daha Fazla Bilgi"}
                    {i18n.language === "no" && "Flere detailjer"}
                  </Link>
                </div>
              </div>
              <div className="relative h-3/5 w-full md:w-3/5 md:h-full">
                <div className="w-full h-full overflow-hidden">
                  <img
                    src={SliderFourImg}
                    alt="SliderOneImg"
                    className={`w-full h-full object-cover transition-all  duration-[5000ms] ${
                      isActive ? "scale-125" : "scale-100"
                    }`}
                  />
                </div>
                <div className="absolute top-6 left-6 text-[#00833e] flex gap-2 text-3xl">
                  <SwiperButtonPrev
                    className={
                      "p-1.5 bg-white flex justify-center items-center border-[#00833e]"
                    }
                  />
                  <SwiperButtonNext
                    className={
                      "p-1.5 bg-white flex justify-center items-center border-[#00833e]"
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Mainswiper;
