import React, { useState, useEffect } from "react";
// https://services.marinetraffic.com/api/exportvessel/f4db4ae8bfc571ab143f3629d869a966f38c60e4
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import Filter from "./filter";

import AOS from "aos";
import "aos/dist/aos.css";

import { useTranslation } from "react-i18next";

import SEO from "../../../components/SEO";

import axios from "../../../api/axios";

const GET_URL_REFERENCE = "/get/finished-reference/index.php";
const GET_URL_REFERENCES = "/get/finished-references/index.php";

const linked = (text, lang) => {
  let ab = text.toLowerCase();
  if (lang === "en") {
    ab = text.toLocaleLowerCase("en-US");
  }
  if (lang === "tr") {
    ab = text.toLocaleLowerCase("tr-TR");
  }
  if (
    ab.includes("switchboards") ||
    ab.includes("ana panolar") ||
    ab.includes("tavler")
  )
    return "/switchboards";
  if (
    ab.includes("electrical drives") ||
    ab.includes("sürücü panoları") ||
    ab.includes("drive system")
  )
    return "/electricaldrives";
  if (
    ab.includes("propulsion") ||
    ab.includes("e-tahrik") ||
    ab.includes("elektrisk fremdrift kontroll system")
  )
    return "/epro";
  if (
    ab.includes("energy management") ||
    ab.includes("enerji yönetim") ||
    ab.includes("energistyring system")
  )
    return "/EMS";
  if (
    ab.includes("power management") ||
    ab.includes("güç yönetim") ||
    ab.includes("strømstyring system")
  )
    return "/PMS";
  if (
    ab.includes("integrated alarm") ||
    ab.includes("entegre alarm") ||
    ab.includes("kontrollsystemer")
  )
    return "/IACS";
  if (
    ab.includes("other products") ||
    ab.includes("diğer ürünler") ||
    ab.includes("andre produkter")
  )
    return "/other";
  return true;
};

const References = () => {
  const params = useParams();
  const { t, i18n } = useTranslation();

  const [filter, setFilter] = useState("");
  const [filtered, setFiltered] = useState(null);

  const [value, setValue] = useState(null);
  const [references, setReferences] = useState(null);
  const navigate = useNavigate();

  const uniq = params.url;

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const formData = new FormData();
    formData.append("info", uniq);
    try {
      axios.post(GET_URL_REFERENCE, formData).then((res) => {
        let newData = res.data.data[0]
        if (i18n.language === "en") {
          newData.scope = res.data.data[0].scope;
        } else if (i18n.language === "tr") {
          newData.scope = res.data.data[0].trscope;
        } else {
          newData.scope = res.data.data[0].noscope;
        }
        setValue(newData);
      });
    } catch (error) {
      console.log(error);
    }
  }, [i18n.language, uniq]);

  useEffect(() => {
    try {
      axios.get(GET_URL_REFERENCES).then((response) => {
        setReferences(response.data.data);
        setFiltered(response.data.data);
        filterMethod(response.data.data);

      });
    } catch (error) {
      console.log(error);
    } 
  }, []);

  const filterFunc = (e) => {
    const text = e;
    console.log(text);
    if (
      text.toLocaleLowerCase() === "all" ||
      text.toLocaleLowerCase() === "tümü" ||
      text.toLocaleLowerCase() === "alle"
    ) {
      setFiltered(references);
    } else {
      const arr = [];
      references.map((item) => {
        let types;
        if (i18n.language === "en") {
          types = item.type.split(",");
        } else if (i18n.language === "tr") {
          types = item.trtype.split(",");
        } else {
          types = item.notype.split(",");
        }
        types.map((type) => {
          if (type.trim() === text) {
            arr.push(item);
          }
        });
      });
      setFiltered(arr);
    }
  };
  const filterMethod = () => {
    if (i18n.language === "en") {
      setFilter([
        "Commercial Vessels",
        "Fishing and Aquaculture Vessels",
        "Workboats",
        "Ferries",
        "Yachts",
        "Offshore Supply Vessels",
        "Defense",
      ]);
    } else if (i18n.language === "tr") {
      setFilter([
        "Ticari Gemiler",
        "Balıkçılık ve Su Ürünleri Gemileri",
        "Römorkör ve Yardımcı Gemiler",
        "Feribotlar",
        "Yatlar",
        "Açık Deniz İkmal Gemileri",
        "Savunma",
      ]);
    } else {
      setFilter([
        "Kommersielle fartøyer",
        "Fiske og brønnbåter",
        "Arbeidsbåter",
        "Ferger og passasjerbåter",
        "Yacther",
        "Offshore fartøyer",
        "Forsvarsbåter",
      ]);
    }
  };

  return (
    <div className="md:px-24 p-3 flex flex-col gap-16">
      {value !== null && (
        <div className="w-full md:h-[90vh] flex md:flex-row flex-col-reverse">
          {i18n.language === "en" && (
            <SEO
              title={t("finishedreferences-title")}
              description={value.endescription}
              keys={value.enkeys}
              conancial={`/signedreferences/${uniq}`}
            />
          )}
          {i18n.language === "tr" && (
            <SEO
              title={t("finishedreferences-title")}
              description={value.trdescription}
              keys={value.trkeys}
              conancial={`/signedreferences/${uniq}`}
            />
          )}
          {i18n.language === "no" && (
            <SEO
              title={t("finishedreferences-title")}
              description={value.nodescription}
              keys={value.nokeys}
              conancial={`/signedreferences/${uniq}`}
            />
          )}
          <div className="w-full md:w-2/5 h-1/2 md:h-full flex flex-col md:gap-8 gap-1 ">
            <button
              onClick={() => navigate(-1)}
              className="flex gap-4 text-lg items-center mt-12 group"
            >
              <IoIosArrowBack className="md:text-5xl text-3xl border-[1px] border-black cursor-pointer group-hover:bg-black group-hover:text-white transition-all" />
              <p className="text-[#A3A3A3] md:text-base text-sm">
                {t("references-back")}
              </p>
            </button>
            <div className="flex flex-col md:gap-8 gap-1 mt-6">
              <p className="text-[#00833e] md:text-4xl text-2xl font-bold">
                {value.head}
              </p>
              <div className="text-[#A3A3A3] md:text-lg text-base flex flex-col md:gap-6 gap-1">
                <div>
                  <span className="font-bold">{t("references-type")}: </span>
                  {i18n.language === "en" && value.type}
                  {i18n.language === "tr" && value.trtype}
                  {i18n.language === "no" && value.notype}
                </div>
                <div>
                  <span className="font-bold">{t("references-yard")}: </span>
                  {i18n.language === "en" && value.yard}
                  {i18n.language === "tr" && value.tryard}
                  {i18n.language === "no" && value.noyard}
                </div>
                <div>
                  <span className="font-bold">{t("references-scope")}: </span>
                  {value.scope.split(",").map((item, index) => (
                    <Link
                      to={linked(item.trim(), i18n.language)}
                      className={`${
                        linked(item.trim()) === true
                          ? "pointer-events-none"
                          : "underline"
                      }`}
                      key={index}
                    >
                      {item.trim("")}
                      {value.scope.split(",").length - 1 === index ? "" : ", "}
                    </Link>
                  ))}
                </div>
              </div>{" "}
              <div>
                <div className="mt-2">
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={
                      process.env.REACT_APP_URL +
                      "uploads/finishedreferences/" +
                      value.unique +
                      ".pdf"
                    }
                    className="md:px-12 md:py-4 md:text-base text-sm px-4 py-2 hover:bg-black hover:text-white border-[1px] border-black transition-all"
                  >
                    {t("references-viewpdf")}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-3/5 h-1/2 md:h-full">
            <img
              src={
                process.env.REACT_APP_URL +
                "/uploads/finishedreferences/" +
                value.unique +
                "." +
                value.last
              }
              alt="reference"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      )}
      {value == null && (
        <div className="w-full flex flex-col gap-16 font-bold text-5xl ">
          <p>404</p>
        </div>
      )}
      <div className="w-full border-[1px] relative md:mt-32 lg:mt-0 mt-10">
        <div className="absolute md:-top-32 -top-16 left-24">
          <div className="md:h-32 h-16 w-[1px] bg-[#D8D8D8]"></div>
          <div className="absolute  -top-5 -left-5 w-10 h-10 rounded-[100%] border-[1px] border-[#D8D8D8] animate-ping"></div>
          <div className="absolute  -top-5 -left-5 w-10 h-10 rounded-[100%] border-[1px] border-[#D8D8D8]">
            <div className="absolute top-[52%] left-[51%] -translate-x-1/2 -translate-y-1/2 w-1 h-1 bg-[#D8D8D8] rounded-[100%]"></div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex gap-2 text-[#A3A3A3] md:p-6 p-2 md:text-base text-xs flex-wrap">
            <Link to="/">{t("home-page")}</Link>
            <p>{`>`}</p>
            <Link to="/finishedreferences">{t("finishedreferences-page")}</Link>
            <p>{`>`}</p>
            <p className="text-black">{value !== null && value.head}</p>
          </div>
          <div className="flex justify-center items-center md:p-6 p-2 md:text-lg text-xs">
            {filter.length > 0 && (
              <Filter filter={filterFunc} filterText={filter} />
            )}
            <p></p>
          </div>
        </div>
        <div className="md:p-24 p-6 flex flex-wrap border-t-[1px] text-lg gap-12 justify-center">
          {references !== null &&
            references.length > 0 &&
            filtered.map((item, index) => (
              <Link
                data-aos="flip-left"
                to={"/finishedreferences/" + item.customername}
                key={index}
                className="group lg:w-[28%] md:w-[40%] w-[85%] aspect-[3/4] flex flex-col justify-between  transition-all cursor-pointer hover:shadow-lg"
              >
                <div className="h-[66%] w-full overflow-hidden">
                  <img
                    src={
                      process.env.REACT_APP_URL +
                      "/uploads/finishedreferences/" +
                      item.unique +
                      "." +
                      item.last
                    }
                    alt="ref"
                    className="w-full h-full object-cover group-hover:scale-110 scale-100 transition-all"
                  />
                </div>
                <div className="flex flex-col p-2">
                  <p className="text-black lg:text-3xl md:text-xl">
                    {item.head}
                  </p>
                  <Link
                    to="/systemsmain"
                    className="text-[#A3A3A3] lg:text-lg text-base text-ellipsis line-clamp-3"
                  >
                    {i18n.language === "en" && item.scope}
                    {i18n.language === "tr" && item.trscope}
                    {i18n.language === "no" && item.noscope}
                  </Link>
                </div>
                <div className="flex justify-end text-2xl">
                  <IoIosArrowForward />
                </div>
              </Link>
            ))}
        </div>
        <div className="md:p-24 p-6 flex justify-center items-center">
          <div>
            <Link
              to="/finishedreferences"
              className="text-center px-12 py-3 border-black border-[1px] hover:bg-black hover:text-white transition-all"
            >
              {t("homepage-references-seeall")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default References;
