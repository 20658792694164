import { React } from "react";
import { useSwiper } from "swiper/react";

import {IoIosArrowForward} from 'react-icons/io'

export default function SlideNextButton({ className }) {
  const swiper = useSwiper();

  return (
    <button className={className} onClick={() => swiper.slideNext()}>
        <IoIosArrowForward />
    </button>
  );
}
