import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Toast from "react-hot-toast";
import { useAuth } from "../../../../context/AuthContext";

import axios from "../../../../api/axios";

const GET_URL = "/auth/contact-messages/get/index.php";
const DELETE_URL = "/auth/contact-messages/delete/index.php";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Name", width: 130 },
  { field: "email", headerName: "Email", width: 170 },
  { field: "phone", headerName: "Phone", width: 150 },
  { field: "message", headerName: "Message", width: 500 },
];

export default function DataTable() {
  const [selected, setSelected] = useState([]);
  const [formData, setFormData] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      try {
        axios.get(GET_URL, {
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }).then((res) => {
          if (res.data.status === "success") {
            setFormData(res.data.data);
          } else {
            Toast.error(res.data.message);
          }
        }
        );
      } catch (error) {
        console.log(error);
      }
    }
  }, [user]);

  const deleteSelected = () => {
    if (selected.length === 0) {
      Toast.error("No selected row");
      return;
    }

    const formData = new FormData();
    formData.append("action", "delete_contact_messages");
    formData.append("ids", selected.join(','));
    const loadid = Toast.loading("Siliniyor...");

    try {
      axios.post(DELETE_URL, formData, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      }).then((res) => {
        if (res.data.status === "success") {
          Toast.success(res.data.message);
        } else {
          Toast.error(res.data.message);
        }
        setTimeout(() => {
          window.location.reload(false);
        }, 700);
        Toast.dismiss(loadid);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col gap-4" style={{ height: 600, width: "100%" }}>
      {formData !== null && (
        <DataGrid
          onSelectionModelChange={(something) => {
            setSelected(something);
          }}
          rows={formData}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          getRowHeight={() => "auto"}
        />
      )}
      <div className="p-2">
        <button
          onClick={() => deleteSelected()}
          className="bg-gray-600 text-white p-4 rounded-xl"
        >
          Delete Selected Rows
        </button>
      </div>
    </div>
  );
}
