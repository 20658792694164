import React, { useRef, useCallback, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../../api/axios";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

import "./SectionFour.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import { useTranslation } from "react-i18next";

const GET_URL = '/get/page-image-last/index.php'

const SectionFour = () => {
  const { t, i18n } = useTranslation();

  const sliderRef = useRef(null);

  const [aboutus, setAboutus] = useState(null);
  const [rd, setRd] = useState(null);
  const [career, setCareer] = useState(null);
  const [values, setValues] = useState(null);
  const [policy, setPolicy] = useState(null);
  const [sust, setSust] = useState(null);

  const [foranim, setForanim] = useState(false);
  const [reverseanim, setReverseanim] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setForanim(false);
    }, 700);
    return () => {
      clearTimeout(timeout);
    };
  }, [foranim]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setReverseanim(false);
    }, 700);
    return () => {
      clearTimeout(timeout);
    };
  }, [reverseanim]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("name", "aboutus");
    axios.post(GET_URL, formData).then((res) => {
      setAboutus(res.data.data);
    });
    const formData2 = new FormData();
    formData2.append("name", "rd");
    axios.post(GET_URL, formData2).then((res) => {
      setRd(res.data.data);
    });
    const formData3 = new FormData();
    formData3.append("name", "career");
    axios.post(GET_URL, formData3).then((res) => {
      setCareer(res.data.data);
    });
    const formData4 = new FormData();
    formData4.append("name", "values");
    axios.post(GET_URL, formData4).then((res) => {
      setValues(res.data.data);
    });

    const formData5 = new FormData();
    formData5.append("name", "policy");
    axios.post(GET_URL, formData5).then((res) => {
      setPolicy(res.data.data);
    });
    const formData6 = new FormData();
    formData6.append("name", "sustainability");
    axios.post(GET_URL, formData6).then((res) => {
      setSust(res.data.data);
    });
  }, []);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className="md:p-16 p-3 md:min-h-screen ">
      <div
        style={{
          background:
            "linear-gradient(90deg, rgba(0,42,66,1) 0%, rgba(0,79,124,1) 100%)",
        }}
        className="md:h-[calc(100vh-8rem)] h-[calc(100vh-1.5rem)] flex flex-col justify-between gap-2"
      >
        <div className="md:mt-12 md:ml-12 p-2 text-2xl md:text-2xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-bold flex md:gap-12 gap-4 flex-wrap ">
          <div className="flex gap-2 flex-wrap ">
            {i18n.language === "en" ? (
              <>
                <span
                  className={`text-white  opacity-60 ${
                    foranim && "animation-opacity-60"
                  } ${reverseanim && "animation-reverse-opacity-60"}`}
                >
                  {t("bar-about-title").toLocaleUpperCase()}
                </span>
                <span
                  className={`text-stroke text-transparent opacity-40 md:block hidden ${
                    foranim && "animation-opacity-40"
                  } ${reverseanim && "animation-reverse-opacity-40"}`}
                >
                  {t("bar-about-title").toLocaleUpperCase()}
                </span>
                <span className={`text-stroke text-transparent opacity-30 md:block hidden ${
                    foranim && "animation-opacity-30"
                  } ${reverseanim && "animation-reverse-opacity-30"}`}>
                  {t("bar-about-title").toLocaleUpperCase()}
                </span>
                <span className={`text-stroke text-transparent opacity-20 md:block hidden ${
                    foranim && "animation-opacity-20"
                  } ${reverseanim && "animation-reverse-opacity-20"}`}>
                  {t("bar-about-title").toLocaleUpperCase()}
                </span>
              </>
            ) : (
              <>
                <span
                  className={`text-white opacity-60 ${
                    foranim && "animation-opacity-60"
                  } ${reverseanim && "animation-reverse-opacity-60"}`}
                >
                  {t("bar-about-title").toLocaleUpperCase()}
                </span>
                {/* opacity-60 */}
                <span
                  className={`text-stroke text-transparent opacity-40 md:block hidden ${
                    foranim && "animation-opacity-40"
                  } ${reverseanim && "animation-reverse-opacity-40"}`}
                >
                  {t("bar-about-title").toLocaleUpperCase()}
                </span>
                {/* opacity-40 */}
                <span
                  className={`text-stroke text-transparent opacity-20 md:block hidden ${
                    foranim && "animation-opacity-20"
                  } ${reverseanim && "animation-reverse-opacity-20"}`}
                >
                  {t("bar-about-title").toLocaleUpperCase()}
                </span>
              </>
            )}
          </div>
          <div className="text-[#fff] flex gap-2">
            <button className="border-[1px] " onClick={handlePrev}>
              <IoIosArrowBack />
            </button>
            <button className="border-[1px] " onClick={handleNext}>
              <IoIosArrowForward />
            </button>
          </div>
        </div>
        <div className="w-full h-full  flex md:flex-row flex-col-reverse justify-end md:justify-between items-center p-4 overflow-hidden">
          <div className="relative md:left-14 left-0 md:w-2/5 w-full text-white gap-2 md:gap-4 flex flex-col md:justify-center justify-end h-1/3 md:h-full">
            <p className="md:text-5xl text-2xl">{t("bma-title")}</p>
            <p>{t("home-section-four-description")}</p>
            <div className="mt-4">
              <Link
                to="/about-us"
                className="md:px-12 md:py-4 px-4 py-2 border-[1px] border-white  text-sm hover:bg-white hover:text-black transition-all"
              >
                {i18n.language === "en" && "Read More"}
                {i18n.language === "tr" && "Daha Fazla Oku"}
                {i18n.language === "no" && "Les mer"}
              </Link>
            </div>
          </div>
          <div className="md:w-4/5 text-white sectionfour w-full relative md:left-28 left-0  h-1/2 md:h-full flex justify-center items-center">
            <Swiper
              ref={sliderRef}
              spaceBetween={10}
              loop={true}
              onSlideChange={(swiper) => {
                if (swiper.activeIndex > swiper.previousIndex || swiper.activeIndex === 0) {
                  setForanim(true);
                } else  {
                  setReverseanim(true);
                }
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                480: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 2,
                },
                1280: {
                  slidesPerView: 3,
                },
              }}
            >
              <SwiperSlide>
                {({ isActive }) => {
                  return (
                    <div
                      className={`w-full relative transition-all duration-500 ${
                        isActive ? "h-full bg-white" : "h-2/3 bg-green-600"
                      }`}
                    >
                      <Link
                        to="/about-us"
                        className="absolute top-0 left-0 w-full h-full"
                      >
                        <div
                          style={{
                            backgroundImage: `url(${
                              process.env.REACT_APP_URL +
                              "/uploads/pages/aboutus." +
                              aboutus
                            })`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                          alt="img"
                          className={`w-full h-full object-cover ${
                            isActive ? "" : "blending-mode"
                          }`}
                        />
                      </Link>
                      <p
                        className={`absolute bottom-2 left-2 text-3xl md:text-5xl ${
                          isActive ? "hidden" : "block"
                        }`}
                      >
                        {t("bar-about-aboutus")}
                      </p>
                    </div>
                  );
                }}
              </SwiperSlide>
              <SwiperSlide>
                {({ isActive }) => {
                  return (
                    <div
                      className={`w-full relative transition-all duration-500 ${
                        isActive ? "h-full bg-white" : "h-2/3 bg-green-600"
                      }`}
                    >
                      <Link
                        to="/r-d"
                        className="absolute top-0 left-0 w-full h-full"
                      >
                        <div
                          style={{
                            backgroundImage: `url(${
                              process.env.REACT_APP_URL +
                              "/uploads/pages/rd." +
                              rd
                            })`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                          alt="img"
                          className={`w-full h-full object-cover ${
                            isActive ? "" : "blending-mode"
                          }`}
                        />
                      </Link>
                      <p
                        className={`absolute bottom-2 left-2 text-3xl md:text-5xl ${
                          isActive ? "hidden" : "block"
                        }`}
                      >
                        {t("bar-about-rd")}
                      </p>
                    </div>
                  );
                }}
              </SwiperSlide>
              <SwiperSlide>
                {({ isActive }) => {
                  return (
                    <div
                      className={`w-full relative transition-all duration-500 ${
                        isActive ? "h-full bg-white" : "h-2/3 bg-green-600"
                      }`}
                    >
                      <Link
                        to="/career"
                        className="absolute top-0 left-0 w-full h-full"
                      >
                        <div
                          style={{
                            backgroundImage: `url(${
                              process.env.REACT_APP_URL +
                              "/uploads/pages/career." +
                              career
                            })`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                          alt="img"
                          className={`w-full h-full object-cover ${
                            isActive ? "" : "blending-mode"
                          }`}
                        />
                      </Link>
                      <p
                        className={`absolute bottom-2 left-2 text-3xl md:text-5xl ${
                          isActive ? "hidden" : "block"
                        }`}
                      >
                        {t("bar-about-career")}
                      </p>
                    </div>
                  );
                }}
              </SwiperSlide>
              <SwiperSlide>
                {({ isActive }) => {
                  return (
                    <div
                      className={`w-full relative transition-all duration-500 ${
                        isActive ? "h-full bg-white" : "h-2/3 bg-green-600"
                      }`}
                    >
                      <Link
                        to="/values"
                        className="absolute top-0 left-0 w-full h-full"
                      >
                        <div
                          style={{
                            backgroundImage: `url(${
                              process.env.REACT_APP_URL +
                              "/uploads/pages/values." +
                              values
                            })`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                          alt="img"
                          className={`w-full h-full object-cover ${
                            isActive ? "" : "blending-mode"
                          }`}
                        />
                      </Link>
                      <p
                        className={`absolute bottom-2 left-2 text-3xl md:text-5xl ${
                          isActive ? "hidden" : "block"
                        }`}
                      >
                        {t("bar-about-values")}
                      </p>
                    </div>
                  );
                }}
              </SwiperSlide>
              <SwiperSlide>
                {({ isActive }) => {
                  return (
                    <div
                      className={`w-full relative transition-all duration-500 ${
                        isActive ? "h-full bg-white" : "h-2/3 bg-green-600"
                      }`}
                    >
                      <Link
                        to="/policy"
                        className="absolute top-0 left-0 w-full h-full"
                      >
                        <div
                          style={{
                            backgroundImage: `url(${
                              process.env.REACT_APP_URL +
                              "/uploads/pages/policy." +
                              policy
                            })`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                          alt="img"
                          className={`w-full h-full object-cover ${
                            isActive ? "" : "blending-mode"
                          }`}
                        />
                      </Link>
                      <p
                        className={`absolute bottom-2 left-2 text-3xl md:text-5xl ${
                          isActive ? "hidden" : "block"
                        }`}
                      >
                        {t("bar-about-policy")}
                      </p>
                    </div>
                  );
                }}
              </SwiperSlide>
              <SwiperSlide>
                {({ isActive }) => {
                  return (
                    <div
                      className={`w-full relative transition-all duration-500 ${
                        isActive ? "h-full bg-white" : "h-2/3 bg-green-600"
                      }`}
                    >
                      <Link
                        to="/sustainability"
                        className="absolute top-0 left-0 w-full h-full"
                      >
                        <div
                          style={{
                            backgroundImage: `url(${
                              process.env.REACT_APP_URL +
                              "/uploads/pages/sustainability." +
                              sust
                            })`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                          alt="img"
                          className={`w-full h-full object-cover ${
                            isActive ? "" : "blending-mode"
                          }`}
                        />
                      </Link>
                      <p
                        className={`absolute bottom-2 left-2 text-3xl md:text-5xl ${
                          isActive ? "hidden" : "block"
                        }`}
                      >
                        {t("bar-about-sustainability")}
                      </p>
                    </div>
                  );
                }}
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFour;
