import React from "react";

import PageEdit from "../../partials/dashboard/PageEdit";
import PageImageEdit from "../../partials/dashboard/PageImageEdit";

const DashboardMain = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Cards */}
      <div className="grid grid-cols-12 gap-6">
        <PageEdit title="Career En" edit="en" page="career"/>
        <PageEdit title="Career Tr" edit="tr" page="career"/>
        <PageEdit title="Career No" edit="no" page="career"/>
        <PageImageEdit  page="career"/>
      </div>
    </div>
  );
};

export default DashboardMain;
