import React from "react";

import PageEdit from "../../partials/dashboard/PageEdit";
import PageImageEdit from "../../partials/dashboard/PageImageEdit";

const DashboardMain = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Cards */}
      <div className="grid grid-cols-12 gap-6">
        <PageEdit title="Fishing and Aquaculture Vessels En" edit="en" page="fishing"/>
        <PageEdit title="Fishing and Aquaculture Vessels Tr" edit="tr" page="fishing"/>
        <PageEdit title="Fishing and Aquaculture Vessels No" edit="no" page="fishing"/>
        <PageImageEdit page="fishing"/>

      </div>
    </div>
  );
};

export default DashboardMain;
