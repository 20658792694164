import React, { useRef } from "react";
import Toast from "react-hot-toast";

import axios from "../../../../api/axios";
import { useAuth } from "../../../../context/AuthContext";

const POST_URL = "/auth/page_content/image/index.php";

function DashboardCard13(props) {
  const imageRef = useRef(null);
  const { user } = useAuth();

  const submitFunc = async () => {
    if (imageRef.current.files[0] === undefined) {
      Toast.error("Lütfen dosyaları ekleyin");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", props.page);
      formData.append("image", imageRef.current.files[0]);
      const response = await axios.post(POST_URL, formData, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`
        }
      });
      console.log(response);
      if (response.data.status === "success") {
        Toast.success(response.data.message);
      } else {
        Toast.error(response.data.message);
      }
      setTimeout(() => {
        // window.location.reload(false);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
      <div className="p-3">
        <div>
          <header className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm font-semibold p-2">
            Change Image
          </header>
          <ul className="my-1 flex flex-col gap-2">
            <input type="file" ref={imageRef} />
            <button
              onClick={submitFunc}
              className="p-2 text-sm bg-[#00833e] text-white mt-1"
            >
              Submit
            </button>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DashboardCard13;
