import {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";

import axios from "../api/axios";

const REFRESH_URL = "/auth/refresh/index.php";
const Context = createContext();
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const [ticking, setTicking] = useState(null);

  const toggleRefresh = useCallback(
    (status) => {
      if (status) {
        let i = setInterval(async () => {
          let userVal = JSON.parse(localStorage.getItem("user"));
          if (userVal) {
            try {
              const response = await axios.post(
                REFRESH_URL,
                JSON.stringify({
                  refreshToken: userVal?.refreshToken,
                  accessToken: userVal?.accessToken,
                }),
                {}
              );

              if (response?.data?.status === "success") {
                setUser({
                  ...userVal,
                  accessToken: response?.data?.access_token,
                  refreshToken: response?.data?.refresh_token,
                  expiresAt: Date.now() + 1000 * 60 * 60 * 24 * 7, // 7 days
                });

                localStorage.setItem(
                  "user",
                  JSON.stringify({
                    ...userVal,
                    accessToken: response?.data?.access_token,
                    refreshToken: response?.data?.refresh_token,
                    expiresAt: Date.now() + 1000 * 60 * 60 * 24 * 7, // 7 days
                  })
                );
              }
            } catch (err) {
              console.log(err);
            }
          }
        }, [10000]);

        setTicking(i);
      } else {
        clearInterval(ticking);
        setTicking(null);
      }
    },
    [ticking]
  );

  const login = (user) => {
    setUser(user);

    localStorage.setItem(
      "user",
      JSON.stringify({
        ...user,
        expiresAt: Date.now() + 1000 * 60 * 60 * 24 * 15, // 15 days
      })
    );
  };

  const logout = () => {
    setUser(null);
    setTicking(null);
    toggleRefresh(false);

    localStorage.removeItem("user");
  };

  useEffect(() => {
    const userFromLocal = localStorage.getItem("user");

    if (userFromLocal) {
      const expires = JSON.parse(userFromLocal)?.expiresAt;
      if (expires && expires < Date.now()) {
        logout();
      } else {
        setUser(JSON.parse(userFromLocal));
      }
    }
  }, []);

  return (
    <Context.Provider value={{ user, login, logout, ticking, toggleRefresh }}>
      {children}
    </Context.Provider>
  );
};
export const useAuth = () => useContext(Context);
