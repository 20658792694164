import { React } from 'react';
import { useSwiper } from 'swiper/react';

import {IoIosArrowBack} from 'react-icons/io'

export default function SlideNextButton({className}) {
  const swiper = useSwiper();

  return (
    <button className={className} onClick={() => swiper.slidePrev()}>
        <IoIosArrowBack />
    </button>
  );
}