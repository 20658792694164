import React from "react";

import PageEdit from "../../partials/dashboard/PageEdit";
import PageImageEdit from "../../partials/dashboard/PageImageEdit";

const DashboardMain = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Cards */}
      <div className="grid grid-cols-12 gap-6">
        <PageEdit title="About En" edit="en" page="aboutus"/>
        <PageEdit title="About Tr" edit="tr" page="aboutus"/>
        <PageEdit title="About No" edit="no" page="aboutus"/>
        <PageImageEdit page="aboutus"/>
      </div>
    </div>
  );
};

export default DashboardMain;
