import React from "react";

import Logo from "../../assets/bmawhite.png";
import Bg from "../../assets/map.png";

import { Link } from "react-router-dom";

import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t,i18n } = useTranslation();

  return (
    <div className="w-full flex flex-col text-white">
      <div className="h-3 w-full bg-[#84E074]"></div>
      <div className="md:px-24 md:py-16 px-12 py-8 relative flex flex-col gap-4 bg-[#158B01] ">
        <div className="absolute top-0 left-0 w-full h-full">
          <img src={Bg} alt="bg" className="w-full h-full object-cover" />
        </div>
        <div>
          <img src={Logo} alt="logo" className="h-8 object-contain" />
        </div>
        <div className="flex flex-wrap gap-4 z-10">
          <div className="flex flex-col ">
            <p className="font-bold text-lg">{t("bar-about-title")}</p>
            <Link to="/about-us">{t("bar-about-aboutus")}</Link>
            <Link to="/r-d">{t("bar-about-rd")}</Link>
            <Link to="/downloads">{t("bar-about-downloads")}</Link>
            <Link to="/career">{t("bar-about-career")}</Link>
            <Link to="/values">{t("bar-about-values")}</Link>
            <Link to="/policy">{t("bar-about-policy")}</Link>
            <Link to="/sustainability">{t("bar-about-sustainability")}</Link>
          </div>
          <div className="flex flex-col ">
            <p className="font-bold text-lg">{t("bar-markets-title")}</p>
            <Link to="/commercial">{t("bar-markets-commercial")}</Link>
            <Link to="/fishing"> {t("bar-markets-fishing")} </Link>
            <Link to="/workboats"> {t("bar-markets-workboats")} </Link>
            <Link to="/ferries"> {t("bar-markets-ferries")} </Link>
            <Link to="/yachts"> {t("bar-markets-yatch")} </Link>
            <Link to="/offshore"> {t("bar-markets-offshore")} </Link>
            <Link to="/defense"> {t("bar-markets-defense")} </Link>
          </div>
          <div className="flex flex-col ">
            <p className="font-bold text-lg">{t("bar-systems-title")}</p>
            <Link to="/switchboards">{t("bar-systems-switchboards")}</Link>
            <Link to="/electricaldrives">{t("bar-systems-electricaldrives")}</Link>
            <Link to="/epro">{t("bar-systems-epro")}</Link>
            <Link to="/EMS">{t("bar-systems-energyman")}</Link>
            <Link to="/PMS">{t("bar-systems-powerman")}</Link>
            <Link to="/IACS">{t("bar-systems-integrated")}</Link>
            <Link to="/other">{t("bar-systems-other")}</Link>
          </div>
          <div className="flex flex-col ">
            <p className="font-bold text-lg">{t("bar-media-title")}</p>
            <Link to="/news">{t("bar-media-news")}</Link>
            <Link to="/gallery">{t("bar-media-gallery")}</Link>
            <Link to="/press-bulletins">{t("bar-media-press")}</Link>
            <Link to="/catalogue">{t("bar-media-catalogue")}</Link>
            <Link to="/facility">{t("bar-media-facility")}</Link>
          </div>
          <div className="flex flex-col ">
            <p className="font-bold text-lg">{t("bar-references-title")}</p>
            <Link to="/finishedreferences">{t("finishedreferences-page")}</Link>
            <Link to="/signedreferences">{t("signedreferences-page")}</Link>
          </div>
          <div className="flex flex-col ">
            <p className="font-bold text-lg">{t("bar-contact-title")}</p>
            <p>{t("contact-form-adress")}: {t('contact-istanbul-address')}</p>
            <p>{t("contact-form-phone")}: {t('contact-istanbul-phone')}</p>
          </div>
        </div>
      </div>
      <div className="py-2 md:px-24 px-12 flex md:flex-row flex-col md:justify-between md:gap-0 gap-4 text-[#158B01] font-bold bg-[#84E074]">
        <div className="flex md:gap-4 gap-1 items-center">
          <p>{t("footer-follow-us")}</p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/bmatechnologygosb/"
          >
            <FaFacebookF />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://tr.linkedin.com/company/bma-technology"
          >
            <FaLinkedinIn />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/bma__technology/"
          >
            <FaInstagram />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/@bma_technology"
          >
            <FaYoutube />
          </a>
        </div>
        <div className="flex gap-2 flex-wrap">
          <Link
            target={"_blank"}
            rel="noreferrer"
            to={process.env.REACT_APP_URL + "/uploads/disclaimer"+`${i18n.language === 'tr' ? "-tr.pdf" : ".pdf"}`}
          >
            {t("footer-disclaimer")}
          </Link>
          <p>|</p>
          <Link
            target={"_blank"}
            rel="noreferrer"
            to={"/uploads/privacy-policy"+`${i18n.language === 'tr' ? "-tr.pdf" : ".pdf"}`}
          >
            {t("footer-privacy-policy")}
          </Link>
          <p>|</p>
          <Link
            target={"_blank"}
            rel="noreferrer"
            to={
              
              "/uploads/policy-on-the-protection-and-processing-of-personal-data"+`${i18n.language === 'tr' ? "-tr.pdf" : ".pdf"}`
            }
          >
            {t("footer-personal-data")}
          </Link>
          <p>|</p>
          <Link
            target={"_blank"}
            rel="noreferrer"
            to={ "/uploads/downloads/66aa35254046b"+`${i18n.language === 'tr' ? "-tr.pdf" : i18n.language === 'en' ? "-en.pdf" : "-no.pdf"}`}
          >
            {t("footer-cookies")}
          </Link>
        </div>
      </div>
      <div className="md:px-24 px-12 py-2 text-[#BCBCBC]">
        {t("footer-copyright")}
      </div>
    </div>
  );
};

export default Footer;
