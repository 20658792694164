import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

import { useTranslation } from "react-i18next";

import SEO from "../../../components/SEO";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import axios from "../../../api/axios";
const GET_URL = "/get/new/index.php";
const News = () => {
  const [value, setValue] = useState(null);
  const [textFirst, setTextFirst] = useState(null);
  const params = useParams();
  const uniq = params.url;

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const formData = new FormData();
    formData.append("info", uniq);
    try {
      axios
        .post(GET_URL, formData)
        .then((response) => {
          if (response.data.status) {
            let data = response.data.data;
            if (data.length > 0) {
              if (i18n.language === "en") {
                setValue({
                  unique: data[0].unique,
                  title: data[0].enhead,
                  shortdesc: data[0].enshortdesc,
                  desc: data[0].endesc,
                  metadesc: data[0].metaendesc,
                  metakeys: data[0].metaenkeys,
                  mlast: data[0].mlast,
                  last: data[0].last,
                });
                const arr = data[0].enhead.split(" ");
                let textarr = [[arr[0]], []];
                for (let i = 1; i < arr.length; i++) {
                  textarr[1].push(arr[i]);
                }
                setTextFirst(textarr);
              } else if (i18n.language === 'tr') {
                setValue({
                  unique: data[0].unique,
                  title: data[0].trhead,
                  shortdesc: data[0].trshortdesc,
                  desc: data[0].trdesc,
                  metadesc: data[0].metatrdesc,
                  metakeys: data[0].metatrkeys,
                  mlast: data[0].mlast,
                  last: data[0].last,
                });
                const arr = data[0].trhead.split(" ");
                let textarr = [[arr[0]], []];
                for (let i = 1; i < arr.length; i++) {
                  textarr[1].push(arr[i]);
                }
                setTextFirst(textarr);
              } else {
                setValue({
                  unique: data[0].unique,
                  title: data[0].nohead,
                  shortdesc: data[0].noshortdesc,
                  desc: data[0].nodesc,
                  metadesc: data[0].metanodesc,
                  metakeys: data[0].metanokeys,
                  mlast: data[0].mlast,
                  last: data[0].last,
                });
                const arr = data[0].nohead.split(" ");
                let textarr = [[arr[0]], []];
                for (let i = 1; i < arr.length; i++) {
                  textarr[1].push(arr[i]);
                }
                setTextFirst(textarr);
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, [i18n.language, uniq]);

  return (
    <div className="md:px-16 p-3 flex flex-col gap-16">
      {value !== null && (
        <div className="w-full h-[90vh] flex md:flex-row flex-col-reverse">
          <SEO
            title={value.title}
            description={value.metadesc}
            keys={value.metakeys}
            conancial={`/news/${uniq}`}
          />
          <div className="w-full md:w-2/5 h-1/2 md:h-full flex flex-col justify-center gap-8 md:px-24">
            <p className="text-[#778798] md:text-4xl text-2xl">
              <span className="text-[#00822e] font-bold">
                {textFirst[0][0]}
              </span>{" "}
              {textFirst[1].join(" ")}
            </p>
            <p className="text-[#A3A3A3] text-lg">{value.shortdesc}</p>
          </div>
          <div className="w-full md:w-3/5 h-1/2 md:h-full">
            <img
              src={
                process.env.REACT_APP_URL +
                "/uploads/news/" +
                value.unique +
                "." +
                value.mlast
              }
              alt="news"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      )}
      <div className="w-full border-[1px] relative">
        {value !== null && (
          <>
            <div className="absolute md:-top-32 -top-16 left-24">
              <div className="md:h-32 h-16 w-[1px] bg-[#D8D8D8]"></div>
              <div className="absolute  -top-5 -left-5 w-10 h-10 rounded-[100%] border-[1px] border-[#D8D8D8] animate-ping"></div>
              <div className="absolute  -top-5 -left-5 w-10 h-10 rounded-[100%] border-[1px] border-[#D8D8D8]">
                <div className="absolute top-[52%] left-[51%] -translate-x-1/2 -translate-y-1/2 w-1 h-1 bg-[#D8D8D8] rounded-[100%]"></div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex gap-2 text-[#A3A3A3] md:p-6 p-2 md:text-base text-xs">
                <Link to="/">{t("home-page")}</Link>
                <p>{`>`}</p>
                <Link to="/news">{t("news-page")}</Link>
                <p>{`>`}</p>
                <p className="text-black">{value.title}</p>
              </div>
              <div className="flex justify-center items-center md:p-6 p-2 md:text-lg text-sm">
                {value.date}
              </div>
            </div>
            <div className="md:p-24 p-6 flex md:flex-row flex-col-reverse md:gap-24 gap-4 border-t-[1px] text-lg">
              <div className="md:w-1/2 w-full flex flex-col gap-4">
                <ReactQuill
                  value={value.desc}
                  readOnly={true}
                  theme={"bubble"}
                />
              </div>
              <div className="md:w-1/2 w-full">
                <img
                  src={
                    process.env.REACT_APP_URL +
                    "/uploads/news/" +
                    value.unique +
                    "." +
                    value.last
                  }
                  alt="news"
                  className="object-contain"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default News;
