import { useNavigate } from 'react-router-dom'

export default function Page404() {
  const navigate = useNavigate()

  return (
    <div className="w-screen h-screen flex-col gap-4 flex items-center justify-center">
      <h1 className="text-2xl ">SAYFA BULUNAMADI</h1>
      <button
        onClick={() => navigate(-1)}
        className="bg-slate-200 px-8 py-2 text-black rounded hover:bg-slate-300"
      >
        GERİYE DÖN
      </button>
    </div>
  )
}
