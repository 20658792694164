import React from "react";

const Kvkk = () => {
  return (
    <div className="md:p-24 p-1">
      <div className="flex flex-col gap-2 border-[1px] md:p-16 p-2">
        <p>İletişim Formu A&ccedil;ık Rıza Metni</p>
        <p>
          <br />
        </p>
        <p>
          Kişisel verilerin korunması mevzuatı uyarınca veri sorumlusu BMA
          Teknoloji Anonim Şirketi (VKN: 1780741614) (&ldquo;BMA&rdquo; veya
          &ldquo;Şirket&rdquo;) &nbsp;olarak 6698 Sayılı Kişisel Verilerin
          Korunması Kanunu (&ldquo;KVKK&rdquo;)&rsquo;nin 5/2 kapsamında
          a&ccedil;ık rıza aranmaksızın kişisel verilerin işlenmesi
          m&uuml;mk&uuml;n olan haller kapsamında iletişim formunda
          belirttiğiniz kişisel verileriniz işlenmektedir. Ayrıca,
          KVKK&rsquo;nin 8/2 ve 9/2 h&uuml;k&uuml;mleri kapsamında a&ccedil;ık
          rıza aranmaksızın kişisel verilerin aktarılması m&uuml;mk&uuml;n olan
          haller haricinde, yurt i&ccedil;i ve yurt dışına aktarılmasına
          y&ouml;nelik aşağıdaki hususlarda a&ccedil;ık rıza vermeniz durumunda,
          aşağıda belirtilen hususlarla sınırlı olmak &uuml;zere kişisel
          verileriniz aktarılacaktır. Ayrıca Avrupa Birliği&rsquo;ndeki
          ger&ccedil;ek kişilerin kişisel verilerine ilişkin olarak Genel Veri
          Koruma T&uuml;z&uuml;ğ&uuml; (GVKT)&rsquo;ye uygun olarak aşağıda
          belirtilen ama&ccedil;larla işlenecek ve aktarılacaktır.
        </p>
        <p>
          <br />
        </p>
        <p>İşlenen Kişisel Veriler ve İşlenme Amacı&nbsp;</p>
        <p>
          <br />
        </p>
        <p>
          İletişim formunu doldurmanız halinde, isim, e-posta adresiniz, telefon
          numaranız, mesajınızda kişisel veri paylaşmanız halinde, paylaştığınız
          kişisel veriniz işlenecektir. Mesajınızda paylaştığınız kişisel
          verileriniz, isim, e-posta, telefon numarası, mesajlarınız
          doğrultusunda size tekrar ulaşılabilme ve talebinize uygun işlem
          yapılması, iş faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi, iletişim
          faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi, talep ve şikayetlerin
          takibi ama&ccedil;larıyla işlenmektedir. Mesajınızda &ouml;zel
          nitelikli kişisel verilerinizi paylaşmamanızı rica ederiz.
          &nbsp;Kişisel verilerinizi, yukarıda a&ccedil;ıklananlar dışında
          profilinizi etkileyen veya profilinizi oluşturan herhangi bir otomatik
          karar alma i&ccedil;in kullanılmamaktadır.
        </p>
        <p>
          <br />
        </p>
        <p>Kişisel Verilerin Aktarılması&nbsp;</p>
        <p>
          <br />
        </p>
        <p>
          Kişisel verileriniz KVKK kapsamında a&ccedil;ık rızanız olması ve/veya
          GVKT kapsamında rızanız olması durumunda aşağıdaki alıcılara
          aktarılacaktır:
        </p>
        <p>
          <br />
        </p>
        <p>
          E-posta, bulut bilişim ve hosting (barındırma) hizmetlerinin
          kullanılması amacıyla sınırlı olmak &uuml;zere, bilişim teknolojileri
          hizmeti alınan firmaların yurt i&ccedil;i ve yurt dışındaki sunucuları
          ile veri merkezlerine aktarılacaktır.&nbsp;
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>G&uuml;venlik</p>
        <p>
          <br />
        </p>
        <p>
          Kişisel verilerinizin g&uuml;venliğinin sağlanması i&ccedil;in
          g&uuml;venlik tedbirleri alınmaktadır. Kişisel verilerinizin
          kaybolması, yetkisiz kişilerce erişilmesi veya ifa edilmesi gibi
          durumların &ouml;nlenmesi amacıyla yayınladığımız politikalar
          &ccedil;er&ccedil;evesinde gerekli g&uuml;venlik tedbirleri şirket
          tarafından uygulanmaktadır.
        </p>
        <p>
          <br />
        </p>
        <p>Rızayı Geri &Ccedil;ekme Hakkı&nbsp;</p>
        <p>
          <br />
        </p>
        <p>
          Kişisel verilerinizin işlenmesi ve aktarılmasına rıza verdiyseniz
          onayınızı tamamen veya kısmen geri &ccedil;ekme hakkınız
          bulunmaktadır. &nbsp;Rızanızı geri &ccedil;ektiğimize ilişkin bildirim
          aldığımızda, işleme i&ccedil;in başka bir yasal dayanak olmadığı
          s&uuml;rece bilgilerinizi artık rızanızın olduğu ama&ccedil;lar
          i&ccedil;in işlenmeyecektir.
        </p>
        <p>
          <br />
        </p>
        <p>
          Yukarıdakileri kabul ediyorsanız kutuyu işaretleyerek onay
          verebilirsiniz.
        </p>
        <p>&nbsp;</p>
        <p>
          <br />
        </p>
        <p>&nbsp; &nbsp;&nbsp;</p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
      </div>
    </div>
  );
};

export default Kvkk;
