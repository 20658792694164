import React, { useRef } from "react";
import Toast from "react-hot-toast";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

import axios from "../../api/axios";

import Logo from "../../assets/bmawhite.png";
const LOGIN_URL = '/auth/login/index.php';

export default function Admin() {
  const navigate = useNavigate();
  const mailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const username = mailRef.current.value;
    const password = passwordRef.current.value;

    if (username.length === 0 || password.length === 0) {
      Toast.error("Lütfen boşlukları doldurunuz.");
      return;
    }

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ username, password }),
        {}
      );
      console.log(response)
      if (response?.data?.status === "success") {
        login({
          email: username,
          accessToken: response?.data?.access_token,
          refreshToken: response?.data?.refresh_token,
        });
        const loading = Toast.loading("Yönlendiriliyorsunuz...");
        setTimeout(() => {
          navigate("/cyadmin/dashboard");
          Toast.dismiss(loading);
        }, 2000);
      } else {  
        Toast.error(response?.data?.message);
      }
    } catch (err) {
      console.log(err)
      Toast.error(err?.response?.data?.message);
    }
  };

  return (
    <div className="flex items-center justify-center w-full h-screen bg-slate-100">
      <form
        onSubmit={handleSubmit}
        className="flex justify-center flex-col items-center gap-8 p-8 shadow-xl  bg-[#158A01] rounded-xl"
      >
        <img src={Logo} alt="logo" className="w-[13rem] object-contain" />
        <input
          ref={mailRef}
          className="p-4 bg-slate-100 rounded outline-none"
          type="text"
          placeholder="Kullanıcı Adı"
        />
        <input
          ref={passwordRef}
          className="p-4 bg-slate-100 rounded outline-none"
          type="password"
          placeholder="Şifre"
        />
        <button type="submit" className="px-4 py-2 bg-slate-100 w-full rounded">
          Giriş Yap
        </button>
      </form>
    </div>
  );
}
