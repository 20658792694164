import React, { useEffect, useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

import { useTranslation } from "react-i18next";

import SEO from "../../../components/SEO";
import { Link } from "react-router-dom";
import axios from "../../../api/axios";
const url = '/get/download/index.php'
const News = () => {
  const [images, setImages] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    axios.get(url).then((res) => {
      console.log(res)
      if (res.data.length > 0) {
        const arr = [];
        let data = res.data
        data.map((item) => {
          arr.push({
            original:
              process.env.REACT_APP_URL +
              "uploads/downloads/" +
              item.unique +
              "." +
              item.last,
            pdf:
              process.env.REACT_APP_URL +
              "uploads/downloads/" +
              item.unique + `${(i18n.language === 'en' && '-en') || (i18n.language === 'tr' && '-tr') || (i18n.language === 'no' && '-no')}` + '.pdf',
            head: `${(i18n.language === 'en' && item.head) || (i18n.language === 'tr' && item.trhead) || (i18n.language === 'no' && item.nohead)}`,
          });
        });
        setImages(arr);
      }
    });
  }, []);

  return (
    <>
      <div className="md:px-24 p-3 flex flex-col gap-16">
        <SEO
          title={t("downloads-title")}
          description={t("downloads-description")}
          keys={t("downloads-meta-keys")}
        />
        <div className="w-full border-[1px] relative mt-12">
          <div className="flex justify-between border-b-[1px]">
            <div className="flex gap-2 text-[#A3A3A3] md:p-6 p-2 md:text-base text-xs">
              <Link to="/">{t("home-page")}</Link>
              <p>{`>`}</p>
              <p className="text-black">{t("downloads-title")}</p>
            </div>
            <div className="flex justify-center items-center md:p-6 p-2 gap-2 md:text-lg text-sm"></div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-3 md:p-8 p-1 auto-cols-max">
            {images !== null &&
              images.length > 0 &&
              images.map((item, index) => (
                <div
                  key={index}
                  className="cursor-pointer group flex flex-col w-full  hover:shadow-lg hover:-translate-y-2 transition-all p-8"
                >
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={item.pdf}
                    className="w-full md:h-[24rem] overflow-hidden"
                  >
                    <img
                      src={item.original}
                      alt="img"
                      className="w-full h-full object-cover group-hover:scale-110 transition-all duration-500"
                    />
                  </a>
                  <div className="w-full ">
                    <p className="text-[#A3A3A3] md:text-lg text-base">
                      {item.head}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default News;
