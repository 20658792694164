import React from "react";
import Mainswiper from "./SectionOne";
import SectionFour from "./SectionFour";
import SectionFive from "./SectionFive";
import SectionThree from "./SectionThree";
import SectionTwo from "./SectionTwo";
import SEO from "../../../components/SEO";

const Main = () => {
  return (
    <>
      <SEO
        title="BMA Technology"
        description="BMA Technology, elektrik, otomasyon ve hibrid güç üretimi ve tahrik sistemleri için anahtar teslimi çözümler sunan bir deniz elektrik sistemi entegratörüdür."
        keywords="bma"
        canonical={`/`}
      />

      <Mainswiper />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
    </>
  );
};

export default Main;
