import React, { useEffect, useState, useRef } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Toast from "react-hot-toast";
import { useAuth } from "../../../../context/AuthContext";

import axios from "../../../../api/axios";

const GET_URL = "/get/partners/index.php";
const ADD_URL = "/auth/partners/add/index.php";
const DELETE_URL = "/auth/partners/delete/index.php";

const DashboardMain = () => {
  const imageRef = useRef(null);
  const { user } = useAuth();
  const [value, setValue] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (imageRef.current.files[0] === undefined) {
      Toast.error("Lütfen dosyaları ekleyin");
      return;
    }
    const formData = new FormData();
    formData.append("image", imageRef.current.files[0]);
    try {
      axios
        .post(ADD_URL, formData, {
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            Toast.success(res.data.message);
          } else {
            Toast.error(res.data.message);
          }
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (id) => {
    const formData = new FormData();
    formData.append("action", "delete_partner");
    formData.append("id", id);
    const loadid = Toast.loading("Siliniyor...");
    try {
      axios.post(DELETE_URL, formData, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      }).then((res) => {
        if (res.data.status === "success") {
          Toast.success(res.data.message);
        } else {
          Toast.error(res.data.message);
        }
        Toast.dismiss(loadid);
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      axios
        .get(GET_URL)
        .then((res) => {
          setValue(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Cards */}
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-full bg-white shadow-lg rounded-sm border border-slate-200">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Create New One</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                <div className="flex gap-2 items-center">
                  <p>Image</p>
                  <input ref={imageRef} type="file" name="image" />
                </div>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Submit
                </Button>
              </form>
            </AccordionDetails>
          </Accordion>
        </div>
        {value !== null &&
          value.map((item) => (
            <div
              key={item.unique}
              className="col-span-full md:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200"
            >
              <div className="p-4 flex flex-col gap-2">
                <img
                  src={
                    process.env.REACT_APP_URL +
                    "/uploads/partners/" +
                    item.unique +
                    "." +
                    item.last
                  }
                  alt="admin"
                />
                <Button
                  color="error"
                  variant="contained"
                  fullWidth
                  type="button"
                  onClick={() => handleDelete(item.id)}
                >
                  Delete
                </Button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DashboardMain;
