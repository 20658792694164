import React, { useEffect, useState } from "react";

import Main from "../../../assets/Guest/References/main.png";

import { IoIosArrowForward } from "react-icons/io";

import AOS from "aos";
import "aos/dist/aos.css";

import { useTranslation } from "react-i18next";

import SEO from "../../../components/SEO";
import { Link } from "react-router-dom";

import axios from "../../../api/axios";
const url = '/get/news/index.php'

const News = () => {
  const [news, setNews] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    try {
      axios.get(url).then((res) => {
        if (res.data.data.length > 0) {
          setNews((res.data.data).reverse());
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <div className="md:px-24 p-3 flex flex-col gap-16">
        <SEO
          title={t("news-title")}
          description={t("news-description")}
          keys={t("news-meta-keys")}
          conanical={'/news'}
        />
        <div className="w-full md:h-[90vh] flex md:flex-row flex-col-reverse">
          <div className="w-full md:w-2/5 h-1/2 md:h-full flex flex-col justify-center gap-8 ">
            <p className="text-[#00833e] md:text-4xl text-2xl font-bold">
              {t("news-title")}
            </p>
            <p className="text-[#A3A3A3] text-lg">{t("news-description")}</p>
          </div>
          <div className="w-full md:w-3/5 h-1/2 md:h-full">
            <img src={Main} alt="main" className="w-full h-full object-cover" />
          </div>
        </div>
        <div className="w-full border-[1px] relative mt-12">
          <div className="absolute md:-top-32 -top-16 left-24">
            <div className="md:h-32 h-16 w-[1px] bg-[#D8D8D8]"></div>
            <div className="absolute  -top-5 -left-5 w-10 h-10 rounded-[100%] border-[1px] border-[#D8D8D8] animate-ping"></div>
            <div className="absolute  -top-5 -left-5 w-10 h-10 rounded-[100%] border-[1px] border-[#D8D8D8]">
              <div className="absolute top-[52%] left-[51%] -translate-x-1/2 -translate-y-1/2 w-1 h-1 bg-[#D8D8D8] rounded-[100%]"></div>
            </div>
          </div>
          <div className="flex justify-between border-b-[1px]">
            <div className="flex gap-2 text-[#A3A3A3] md:p-6 p-2 md:text-base text-xs">
              <Link to="/">{t("home-page")}</Link>
              <p>{`>`}</p>
              <Link to="/news" className="text-black">
                {t("news-page")}
              </Link>
            </div>
            <div className="flex justify-center items-center md:p-6 p-2 gap-2 md:text-lg text-sm"></div>
          </div>
          <div className="grid auto-rows-min grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-3 md:p-8 p-1;">
            {news &&
              news.length > 0 &&
              news.map((item, index) => (
                <a
                  key={index}
                  href={"/news/" + item.enhead}
                  className="group flex flex-col w-full  hover:shadow-lg hover:-translate-y-2 transition-all p-8"
                >
                  <div className="w-full h-1/2 overflow-hidden">
                    <img
                      src={
                        process.env.REACT_APP_URL +
                        "uploads/news/" +
                        item.unique +
                        "." +
                        item.last
                      }
                      alt="img"
                      className="w-full h-full object-cover group-hover:scale-110 transition-all duration-500"
                    />
                  </div>
                  <div className="h-1/2 flex flex-col md:gap-3 gap-1  p-1">
                    <p className="md:text-xl text-lg">
                      {i18n.language === "en" && item.enhead}
                      {i18n.language === "tr" && item.trhead}
                      {i18n.language === "no" && item.nohead}
                    </p>
                    <p className="md:text-base text-sm text-[#A3A3A3]">
                      {i18n.language === "en" && item.enshortdesc}
                      {i18n.language === "tr" && item.trshortdesc}
                      {i18n.language === "no" && item.noshortdesc}
                    </p>
                    <div className="flex justify-end mt-auto">
                      <IoIosArrowForward />
                    </div>
                  </div>
                </a>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default News;
