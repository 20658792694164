import React, { useState, useRef, useEffect } from "react";
import Transition from "../utils/Transition";

import { MdPlayArrow } from "react-icons/md";

import { useTranslation } from "react-i18next";

function Notifications(props) {
  const { i18n } = useTranslation();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="relative inline-flex ml-3">
      <button
        ref={trigger}
        className={` flex items-center justify-center  transition duration-150 rounded px-2 ${
          dropdownOpen && "bg-slate-200"
        }`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <div className="text-[#158A01] flex items-center">
          <p>
            {i18n.language === "en" && "Filter"}
            {i18n.language === "tr" && "Filtrele"}
            {i18n.language === "no" && "Filter"}
          </p>
          <div
            className={`transition-all duration-500 ${
              dropdownOpen ? "rotate-[270deg]" : "rotate-90"
            }`}
          >
            <MdPlayArrow />
          </div>
        </div>
      </button>

      <Transition
        className=" z-10 absolute top-full right-0 -mr-22 sm:mr-0 min-w-[14rem] bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="text-xs font-semibold text-slate-400 uppercase pt-1.5 pb-2 px-4">
            {i18n.language === "en" && "Filter"}
            {i18n.language === "tr" && "Filtrele"}
            {i18n.language === "no" && "Filter"}
          </div>
          <ul>
            <li>
              <button
                className="flex items-center justify-between w-full px-4 py-2 text-sm leading-5 text-left text-slate-700 hover:bg-slate-100 hover:text-slate-900 focus:outline-none focus:bg-slate-100 focus:text-slate-900"
                onClick={() => {
                  if (i18n.language === "en") {
                    props.filter("all");
                  } else if (i18n.language === "tr") {
                    props.filter("tümü");
                  } else {
                    props.filter("alle");
                  }
                }}
              >
                {i18n.language === "en" && <span>All</span>}
                {i18n.language === "tr" && <span>Tümü</span>}
                {i18n.language === "no" && <span>Alle</span>}
              </button>
            </li>
            {props.filterText.map((item, index) => (
              <li key={index}>
                <button
                  className="flex items-center justify-between w-full px-4 py-2 text-sm leading-5 text-left text-slate-700 hover:bg-slate-100 hover:text-slate-900 focus:outline-none focus:bg-slate-100 focus:text-slate-900"
                  onClick={() => props.filter(item)}
                >
                  <span>{item}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </Transition>
    </div>
  );
}

export default Notifications;
