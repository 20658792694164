import React, { useState } from "react";

import SecMain from "../../../assets/Guest/Home/sectionthree/sectionthreemain.png";
import Logo from "../../../assets/Guest/Home/sectionthree/logo.png";
import first from "../../../assets/Guest/Home/sectionthree/first.png";
import second from "../../../assets/Guest/Home/sectionthree/second.png";
import third from "../../../assets/Guest/Home/sectionthree/three.png";
import four from "../../../assets/Guest/Home/Bar/BMA-icons-8.png";
import CountUp from "react-countup";

import ScrollTrigger from "react-scroll-trigger";

import { useTranslation } from "react-i18next";

const SectionThree = () => {
  const { t } = useTranslation();

  const [counter1, setCounter1] = useState(false);

  return (
    <div className="md:p-16 p-3 md:h-screen relative flex justify-center items-center ">
      <div className="absolute top-0 left-0 w-full h-full  z-10">
        <img
          src={SecMain}
          alt="bmatech"
          className="z-10 object-cover w-full h-full"
        />
      </div>
      <div className="z-20 flex md:flex-row flex-col md:justify-between md:items-center md:gap-0 gap-24 md:p-32 p-8">
        <img
          src={Logo}
          alt="bmatech"
          className="object-contain z-20 lg:h-32 md:h-24 h-24"
        />
        <div className="flex flex-col gap-8 md:w-1/2 w-full">
          <div className="w-full flex text-white md:gap-4 gap-2 lg:gap-6 justify-center">
            <div className="flex flex-col gap-4">
              <div className="lg:h-20 md:h-12 h-8 aspect-square">
                <img
                  src={first}
                  alt="bmatech"
                  className="object-contain w-full h-full"
                />
              </div>
              <div className="flex flex-col items-center justify-center">
                <p className="font-bold text-lg md:text-2xl lg:text-4xl">
                  <ScrollTrigger
                    onEnter={() => setCounter1(true)}
                    onExit={() => setCounter1(false)}
                  >
                    {counter1 && <CountUp start={0} duration={2} end={300} />}
                  </ScrollTrigger>
                </p>
                <div>
                  <p className="lg:text-base md:text-sm text-xs text-center">
                  {t('orange-1')}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="h-full w-[2px] bg-white rotate-[6deg]"></div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="lg:h-20 md:h-12 h-8 aspect-square">
                <img
                  src={second}
                  alt="bmatech"
                  className="object-contain w-full h-full"
                />
              </div>
              <div className="flex flex-col items-center">
                <p className="font-bold text-lg md:text-2xl lg:text-4xl">
                  <ScrollTrigger
                    onEnter={() => setCounter1(true)}
                    onExit={() => setCounter1(false)}
                  >
                    {counter1 && <CountUp start={0} duration={2} end={370} />}
                  </ScrollTrigger>
                </p>
                <div>
                  <p className="lg:text-base md:text-sm text-center text-xs">
                  {t('orange-2')}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="h-full w-[2px] bg-white rotate-[6deg]"></div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="lg:h-20 md:h-12 h-8 aspect-square">
                <img
                  src={third}
                  alt="bmatech"
                  className="object-contain w-full h-full"
                />
              </div>
              <div className="flex flex-col items-center">
                <p className="font-bold text-lg md:text-2xl lg:text-4xl">
                  <ScrollTrigger
                    onEnter={() => setCounter1(true)}
                    onExit={() => setCounter1(false)}
                  >
                    {counter1 && <CountUp start={0} duration={2} end={40} />}
                  </ScrollTrigger>
                </p>
                <div>
                  <p className="lg:text-base md:text-sm text-center text-xs">
                  {t('orange-3')}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="h-full w-[2px] bg-white rotate-[6deg]"></div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="lg:h-20 md:h-12 h-8 aspect-square">
                <img
                  src={four}
                  alt="bmatech"
                  className="object-contain w-full h-full"
                />
              </div>
              <div className="flex flex-col items-center">
                <p className="font-bold text-base md:text-xl lg:text-3xl">
                  <ScrollTrigger
                    onEnter={() => setCounter1(true)}
                    onExit={() => setCounter1(false)}
                  >
                    {counter1 && <CountUp start={0} duration={2} end={13899} />}
                  </ScrollTrigger>
                </p>
                <div>
                  <p className="lg:text-base md:text-sm text-center text-xs">
                    {t('orange-4')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className="text-white md:text-base text-xs text-center">
            {t("orange-area-description")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
