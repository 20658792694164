import React, { useEffect, useState } from "react";

import Main from "../../../assets/Guest/References/main.png";

import { IoIosArrowForward } from "react-icons/io";
import Filter from "./filter";

import AOS from "aos";
import "aos/dist/aos.css";

import { useTranslation } from "react-i18next";

import SEO from "../../../components/SEO";

import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Link } from "react-router-dom";
import axios from "../../../api/axios";
const GET_URL = "/get/signed-references/index.php";
const texts = (text) => {
  const ab = text.toLocaleLowerCase();
  if (ab.includes("commercial")) return "Ticari Gemiler";
  if (ab.includes("fishing")) return "Balıkçılık ve Su Ürünleri Gemileri";
  if (ab.includes("workboats")) return "Römorkör ve Yardımcı Gemiler";
  if (ab.includes("ferries")) return "Feribotlar";
  if (ab.includes("yatch")) return "Yatlar";
  if (ab.includes("offshore")) return "Açık Deniz İkmal Gemileri";
  if (ab.includes("defense")) return "Savunma";
  return text;
};

const References = () => {
  const [animationParent] = useAutoAnimate();

  const [filter, setFilter] = useState("");
  const [references, setReferences] = useState(null);
  const [filtered, setFiltered] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    try {
      axios.get(GET_URL).then((response) => {
        let data = response.data.data;
        setReferences(data);
        setFiltered(data);
        filterMethod(data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const filterFunc = (e) => {
    const text = e;
    console.log(text);
    if (
      text.toLocaleLowerCase() === "all" ||
      text.toLocaleLowerCase() === "tümü" ||
      text.toLocaleLowerCase() === "alle"
    ) {
      setFiltered(references);
    } else {
      const arr = [];
      references.map((item) => {
        let types;
        if (i18n.language === "en") {
          types = item.type.split(",");
        } else if (i18n.language === "tr") {
          types = item.trtype.split(",");
        } else {
          types = item.notype.split(",");
        }
        types.map((type) => {
          if (type.trim() === text) {
            arr.push(item);
          }
        });
      });
      setFiltered(arr);
    }
  };

  const filterMethod = () => {
    if (i18n.language === "en") {
      setFilter([
        "Commercial Vessels",
        "Fishing and Aquaculture Vessels",
        "Workboats",
        "Ferries",
        "Yachts",
        "Offshore Supply Vessels",
        "Defense",
      ]);
    } else if (i18n.language === "tr") {
      setFilter([
        "Ticari Gemiler",
        "Balıkçılık ve Su Ürünleri Gemileri",
        "Römorkör ve Yardımcı Gemiler",
        "Feribotlar",
        "Yatlar",
        "Açık Deniz İkmal Gemileri",
        "Savunma",
      ]);
    } else {
      setFilter([
        "Kommersielle fartøyer",
        "Fiske og brønnbåter",
        "Arbeidsbåter",
        "Ferger og passasjerbåter",
        "Yacther",
        "Offshore fartøyer",
        "Forsvarsbåter",
      ]);
    }
  };

  return (
    <>
      <div className="md:px-24 p-3 flex flex-col gap-16">
        <SEO
          title={t("signedreferences-title")}
          description={t("signedreference-description")}
          keys={t("signedreference-meta-keys")}
          conancial={'/signedreferences'}
        />
        <div className="w-full md:h-[90vh] flex md:flex-row flex-col-reverse">
          <div className="w-full md:w-2/5 h-1/2 md:h-full flex flex-col justify-center gap-8 ">
            <p className="text-[#00833e] md:text-4xl text-2xl font-bold">
              {t("signedreferences-title")}
            </p>
            <p className="text-[#A3A3A3] text-lg">
              {t("signedreferences-description")}
            </p>
          </div>
          <div className="w-full md:w-3/5 h-1/2 md:h-full">
            <img src={Main} alt="main" className="w-full h-full object-cover" />
          </div>
        </div>
        <div className="w-full border-[1px] relative mt-12">
          <div className="absolute md:-top-32 -top-16 left-24">
            <div className="md:h-32 h-16 w-[1px] bg-[#D8D8D8]"></div>
            <div className="absolute  -top-5 -left-5 w-10 h-10 rounded-[100%] border-[1px] border-[#D8D8D8] animate-ping"></div>
            <div className="absolute  -top-5 -left-5 w-10 h-10 rounded-[100%] border-[1px] border-[#D8D8D8]">
              <div className="absolute top-[52%] left-[51%] -translate-x-1/2 -translate-y-1/2 w-1 h-1 bg-[#D8D8D8] rounded-[100%]"></div>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex gap-2 text-[#A3A3A3] md:p-6 p-2 md:text-base text-xs">
              <Link to="/">{t("home-page")}</Link>
              <p>{`>`}</p>
              <p className="text-black">{t("signedreferences-page")}</p>
            </div>
            <div className="flex justify-center items-center md:p-6 p-2 gap-2 md:text-lg text-sm">
              {filter.length > 0 && (
                <Filter filter={filterFunc} filterText={filter} />
              )}
            </div>
          </div>
          <div
            ref={animationParent}
            className="md:p-24 p-6 flex flex-wrap border-t-[1px] text-lg gap-12 justify-center"
          >
            {references !== null &&
              references.length > 0 &&
              filtered.map((item, index) => (
                <a
                  data-aos="flip-left"
                  href={"/signedreferences/" + item.customername}
                  key={index}
                  className="group lg:w-[28%] md:w-[40%] w-[85%] aspect-[3/4] flex flex-col justify-between  transition-all cursor-pointer hover:shadow-lg"
                >
                  <div className="h-[66%] w-full overflow-hidden">
                    <img
                      src={
                        process.env.REACT_APP_URL +
                        "/uploads/signedreferences/" +
                        item.unique +
                        "." +
                        item.last
                      }
                      alt="ref"
                      className="w-full h-full object-cover group-hover:scale-110 scale-100 transition-all"
                    />
                  </div>
                  <div className="flex flex-col p-2">
                    <p className="text-black lg:text-3xl md:text-xl">
                      {item.head}
                    </p>
                    <p className="text-[#A3A3A3] lg:text-lg text-base text-ellipsis line-clamp-3">
                      {i18n.language === "en" && item.scope}
                      {i18n.language === "tr" && item.trscope}
                      {i18n.language === "no" && item.noscope}
                    </p>
                  </div>
                  <div className="flex justify-end text-2xl">
                    <IoIosArrowForward />
                  </div>
                </a>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default References;
