import React, { useEffect, useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

import { useTranslation } from "react-i18next";

import SEO from "../../../components/SEO";
import ImageModal from "./ImageModal";
import { Link } from "react-router-dom";
import axios from "../../../api/axios";
const GET_URL = "/get/facility/index.php";
const News = () => {

  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [imageId, setImageId] = useState(null);

  const [images, setImages] = useState(null);
  const { t ,i18n} = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    try {
      axios.get(GET_URL).then((res) => {
        if (res.data.status) {
          let data = res.data.data;
          if (data.length > 0) {
            const arr = [];
            data.map((item) => {
              arr.push({
                original:
                  process.env.REACT_APP_URL +
                  "uploads/facility/" +
                  item.unique +
                  "-low." +
                  item.last,
                thumbnail:
                  process.env.REACT_APP_URL +
                  "uploads/facility/" +
                  item.unique +
                  "." +
                  item.last2,
                  head: `${(i18n.language === 'en' && item.head) || (i18n.language === 'tr' && item.trhead)|| (i18n.language === 'no' && item.nohead)}`,
              });
            });
            setImages(arr);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <div className="md:px-24 p-3 flex flex-col gap-16">
        <SEO
          title={t("facility-title")}
          description={t("facility-meta-description")}
          keys={t("facility-meta-keys")}
          conanical={'/facility'}
        />
        <div className="w-full border-[1px] relative mt-12">
          <div className="flex justify-between border-b-[1px]">
            <div className="flex gap-2 text-[#A3A3A3] md:p-6 p-2 md:text-base text-xs">
              <Link to="/">{t("home-page")}</Link>
              <p>{`>`}</p>
              <Link to="/news" className="text-black">
                {t("facility-page")}
              </Link>
            </div>
            <div className="flex justify-center items-center md:p-6 p-2 gap-2 md:text-lg text-sm">
            </div>
          </div>{" "}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-3 md:p-8 p-1">
            {images !== null &&
              images.length > 0 &&
              images.map((item, index) => (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setImageId(item.original);
                    setSearchModalOpen(true);
                  }}
                  key={index}
                  className="cursor-pointer group flex flex-col w-full h-full  hover:shadow-lg hover:-translate-y-2 transition-all p-8"
                >
                  <div className="w-full h-[90%] overflow-hidden">
                    <img
                      src={item.thumbnail}
                      alt="img"
                      className="w-full h-full object-cover group-hover:scale-110 transition-all duration-500"
                    />
                  </div>
                  <div className="w-full h-[10%]">
                    <p className="text-[#A3A3A3] text-lg">{item.head}</p>
                  </div>
                </div>
              ))}
          </div>
          <ImageModal
            id="image-modal"
            searchId="search"
            imageId={imageId}
            modalOpen={searchModalOpen}
            setModalOpen={setSearchModalOpen}
          />
        </div>
      </div>
    </>
  );
};

export default News;
